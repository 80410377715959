import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import { Hero, Grid, Column, Spacer, Media } from 'components/ui';
import breakpoint from 'config/responsive';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import { ValuationInPage } from 'components/blocks';
import bp from 'config/responsive';

const MobilePropertyBox = styled.div`
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${sc.screens.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;

  .headerDescription {
    display: inline-block;
  }

  height: 60vh;
  padding-top: 70px;


  ${breakpoint.large} {
    opacity: 1;
  }
  ${bp.computer} {
    height: inherit;
  }
  .grid {
    ${bp.computer} {
      opacity: 0;
    }
  }
`;

const Headline = styled.div`
 margin-bottom: 15px;

${bp.computer} {
  margin-bottom: 15px;
}

${bp.large} {
  margin-bottom: 42px;
}
`;

const BackgroundSection = ({ className, fluid, ...props }) => (
  <BackgroundImage className={className} fluid={fluid} {...props} />
);

const StyledBackgroundSection =  styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;

`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${sc.colors.main_rgb}, 0.90),
      rgba(${sc.colors.muted_rgb}, 0.80)
    );
    top: 0;
    left: 0%;
  }
`;

const TextContainer = styled.div`
  width: 90%;

  ${bp.computer} {
    width: 50%;
    max-width: 620px;
  }
`;

const PropertySearchContainer = styled.div`

  overflow-y: auto;
  flex: 0 0 380px;
  margin: 0 0 0 auto;

  ${bp.large} {
    flex: 0 0 520px;
  }
`;

export class LandlordsHero extends PureComponent {
  static propTypes = {
    title: PT.string,
    text: PT.string,
    img: PT.string,
  }

  renderComponent = (data) => {
    const { title, text, img } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <React.Fragment>
          <Background>
            <StyledBackgroundSection
              src={img}
            />
          </Background>
          <Content>
            <Grid>
              <Spacer />
              <Column verticalAlign center width={10}>
                <TextContainer>
                  <Headline>
                    <Script alpha color="white" align="left" className="title">
                      {title}
                    </Script>
                  </Headline>
                  <Sans alpha color="white" className="headerDescription">
                    {text}
                  </Sans>
                </TextContainer>
                <PropertySearchContainer className="propertySearchBox">
                   <ValuationInPage valuationType="rental-price" />
                </PropertySearchContainer>
              </Column>
              <Spacer />
            </Grid>
          </Content>
        </React.Fragment>
      )}
      renderIfFalse={() => (
        <React.Fragment>
          <Background>
            <StyledBackgroundSection
              src={img}
            />
          </Background>
          <Content>
            <Grid>
              <Column verticalAlign center width={12}>
                <TextContainer>
                  <Headline>
                    <Script alpha color="white" align="left" className="title">
                      {title}
                    </Script>
                  </Headline>
                  <Sans alpha color="white" className="headerDescription">
                    {text}
                  </Sans>
                </TextContainer>

              </Column>
            </Grid>
          </Content>
          <MobilePropertyBox className="mobilePropertyBox">
            <PropertySearchContainer className="propertySearchBox">
               <ValuationInPage valuationType="rental-price" />
            </PropertySearchContainer>
          </MobilePropertyBox>
        </React.Fragment>
      )} />
    );
  }

  render() {
    return (
      <Hero text="scroll">
        <StaticQuery
          query={graphql`
            query {
              cover: file(relativePath: { eq: "landlords.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={this.renderComponent}
        />
      </Hero>
    );
  }
}

export default LandlordsHero;
