import styled, { css } from 'styled-components';
import { Input as SemanticInput } from 'semantic-ui-react';
import sc from 'config/theme';
import bp from 'config/responsive';
import { fonts } from 'Typography';

const background = '#fafafa';

const defaultInputStyle = css`
  border: 1px solid rgba(151, 151, 151, 0.22);
  border-radius: ${sc.sizes.borderRadius}px;
  background: ${background};
  padding: 0px 20px;
  font-size: 16px;
  font-weight: 400;
  font-family: ${fonts.sans};
  color: ${sc.colors.main};
  transition: 0.25s ease;
  outline: none;
  -webkit-appearance: none;

  &:active,
  &:focus {
    border-color: ${sc.colors.muted};
    box-shadow: 0 0 4px rgba(${sc.colors.yellow_rgb}, 0.5);
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(${sc.colors.muted_rgb}, 0.52);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(${sc.colors.muted_rgb}, 0.52);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(${sc.colors.muted_rgb}, 0.52);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: rgba(${sc.colors.muted_rgb}, 0.52);
  }
`;

export const InputGroup = styled.div`
  display: flex;
  width: calc(100% + ${sc.sizes.margin * 2}px);
  margin-left: -${sc.sizes.margin}px;
  margin-right: -${sc.sizes.margin}px;
  margin-bottom: 6px;

  > * {
    flex: 1 1 0;
    min-width: 0 !important;
    margin-left: ${sc.sizes.margin}px !important;
    margin-right: ${sc.sizes.margin}px !important;
    margin-bottom: ${sc.sizes.margin}px !important;
  }
  /* ${bp.large} {
    ${({ padded }) =>
    padded &&
    css`
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 30px;
        > * {
          margin-left: 20px !important;
          margin-right: 20px !important;
        }
      `}
  } */
`;

export const Textarea = styled.textarea`
  ${defaultInputStyle};
  padding: 12px 20px;
  min-height: 210px;
`;

export const Input = styled(SemanticInput)`
  &&&& {
    height: ${sc.sizes.height}px;

    input {
      ${defaultInputStyle};
    }

    ${({ fluid }) =>
    fluid &&
    css`
        flex-grow: 1;
      `}
    ${bp.mobile} {
      height: ${sc.sizesMobile.height}px;
      font-size: 16px;
    }
  }
`;
