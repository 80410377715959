import { TweenLite, TimelineLite, Back, TimelineMax/*, Elastic, Power4, Power0, Linear*/ } from 'gsap';
//This i need for a plane animation: MorphSVGPlugin

import $ from 'jquery';

// Placeholder for production builds only
let SplitText = function() {};

// Load SplitText from gatsby-browser.js (referenced on window)
if (typeof window !== 'undefined') {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    SplitText = window.SplitText;
  }
  
}

export default function buyPageMotion() {
  // Condition to not perform ANY animations when building for production (just when opened in browser)
  if (typeof document !== 'undefined') {
    setTimeout(() => {

      let windowWidth = $(window).width();

      if(windowWidth < 992) {
        var buyAnimations = new TimelineLite(),
        mySplitText = new SplitText(".title", { type: "words,chars" }),
        chars = mySplitText.chars;

        TweenLite.set(".title", { perspective: 400 });

        buyAnimations
        .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
        .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")

      } else {
        var buyAnimations = new TimelineLite(),
        mySplitText = new SplitText(".title", { type: "words,chars" }),
        chars = mySplitText.chars;

        TweenLite.set(".title", { perspective: 400 });

        buyAnimations
        .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
        .set('.grid', {opacity: 1})
        .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
        .from('.headerDescription', 0.8, {opacity: 0, y: 10, ease: Back.easeOut }, "-=0")
        .from('.propertySearchBox', 0.8, {opacity: 0, scale: 0.95, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.4")
        .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")

      }

      



    }, 0);
  }
}


//Scroll Animations
export function functionSinglePropertyAdvice() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var singlePropertyAdviceAnimations = new TimelineLite({ delay: 1 }),
    mySplitText = new SplitText(".singlePropertyAdviceTitle", { type: "words,chars" }),
    chars = mySplitText.chars;

    TweenLite.set(".singlePropertyAdviceTitle", { perspective: 400 });

    singlePropertyAdviceAnimations
      .set('.singlePropertyAdvice', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .staggerFrom(".singlePropertyAdvice button, .singlePropertyAdvice .text, .singlePropertyAdvice a", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")

  }
}


export function functionSlider() {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {

    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.buySlider', {opacity: 1})
      .staggerFrom(".buySliderInner", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }
}


export function tripleImageSectionScroll() {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.tripleImageSection, .singleImageSection', {opacity: 1})
      .staggerFrom(".tripleImageSectionInner, .singleImageSectionInner", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}



export function buyLandingInfoScroll() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.buyLandingInfo, .aboutDescriptionText, .sellLandingInfo', {opacity: 1})
      .staggerFrom(".buyLandingInfo .grid > *, .sellLandingInfo .grid > *, .aboutDescriptionText", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
      .from(".pulseImageContainerFirstInner", 0.8, {scale: 0}, "-=0.6")
      .from(".pulseImageContainerSecondInner", 0.8, {scale: 0}, "-=0.6")
      .from(".pulseImageContainerInnerImg", 0.8, {scale: 0}, "-=0.6")
  }
  



}






