import React from 'react';
import styled from 'styled-components';
import { Sans } from 'Typography';

import bulletPoint from 'images/bullet-point.svg';

export const List = styled.ul`
  list-style-image: url(${bulletPoint});
  padding-left: 9px;
`;

const StyledItem = styled(Sans)`
  padding-left: 6px;
`;

const Item = ({ children, ...otherProps }) => (
  <StyledItem delta color="gray" lineHeight="2.3" as="li" {...otherProps}>
    {children}
  </StyledItem>
);

List.Item = Item;

export default List;
