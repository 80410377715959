import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import Img from 'gatsby-image';
import {
  Grid,
  Spacer,
  Column,
  Media,
} from 'components/ui';
import sc from 'config/theme';
import bp from 'config/responsive';


import { ViewportEnterAnimation } from 'components/ui';
import {  buyLandingInfoScroll } from 'motion/buy.js';


const PulsingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  ${bp.computer} {
    margin-top: 60px;
  }


  .pulseImageContainerFirstInner {
    position: absolute;
    border-radius: 100%;
    width: 80vw;
    height: 80vw;
    z-index: 0;
    border: 1px solid #e8e8e8;
    pointer-events: none;


    ${bp.computer} {
      width: 470px;
      height: 470px;
    }
    ${bp.large} {
      width: 670px;
      height: 670px;
    }
  }

  .pulseImageContainerSecondInner {
    position: absolute;
    border-radius: 100%;
    width: 70vw;
    height: 70vw;
    z-index:0;
    background: rgba(${sc.colors.gray_rgb}, 0.1);
    pointer-events: none;

    ${bp.computer} {
      width: 400px;
      height: 400px;
    }

    ${bp.large} {
      width: 600px;
      height: 600px;
    }
  }
`;

const Pulse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw !important;
  height: 80vw !important;
  padding: 50px;
  background: black;
  border-radius: 50%;
  background: transparent;

  ${bp.tablet} {
    width: 70vw !important;
    height: 70vw !important;
  }
  ${bp.computer} {
    width: 400px!important;
    height: 400px!important;
  }
  ${bp.large} {
    width: 600px!important;
    height: 600px!important;
  }
`;

const PulseImg = styled(Img)`
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

const ImageColumn = styled(Column)`
  ${({ imageOnRight }) => imageOnRight && css`
    order: 1;
  `}
`;

export class PulseImage extends PureComponent {
  static propTypes = {
    imageOnRight: PT.bool,
    fixedImg: PT.any.isRequired,
  }

  renderPulsingImage = () => {
    const { fixedImg, alt } = this.props;

    return (
      <Column width={4}>
        <PulsingContainer className="pulseImageContainer">
          <Pulse className="pulseImageContainerInner">
            <div className="pulseImageContainerFirstInner"></div>
            <div className="pulseImageContainerSecondInner"></div>
            <PulseImg fixed={fixedImg} className="pulseImageContainerInnerImg" alt={alt}/>
          </Pulse>
        </PulsingContainer>
      </Column>
    );
  }

  render() {
    const { imageOnRight, fixedImg, children } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
          <ViewportEnterAnimation offset={100} animation={buyLandingInfoScroll}>  
            <Grid>
              <Spacer />
              <ImageColumn width={4} imageOnRight={imageOnRight} >
                {fixedImg && this.renderPulsingImage()}
              </ImageColumn>
              {!imageOnRight && <Spacer />}
              <Column width={5}>
                {children}
              </Column>
              <Spacer />
            </Grid>
          </ViewportEnterAnimation>
        )}
        renderIfFalse={() => (
          <ViewportEnterAnimation offset={100} animation={buyLandingInfoScroll}>  
            <Grid>
              <ImageColumn width={12} imageOnRight={imageOnRight} >
                {fixedImg && this.renderPulsingImage()}
              </ImageColumn>
              {!imageOnRight && <Spacer />}
              <Column width={12}>
                {children}
              </Column>
            </Grid>
          </ViewportEnterAnimation>
        )} />
    );
  }
}

export default PulseImage;