import hex2rgb from 'utils/hex2rgb';

const gray = '#979797';
const white = '#FFFFFF';
const whiteDim = '#F5F5F5';
const main = '#0B1D3F';
const muted = '#163163';
const yellow = '#D9A547';
const red =  '#A70000';

export default {
  colors: {
    white,
    white_rgb: hex2rgb(white).join(','),
    whiteDim,
    whiteDim_rgb: hex2rgb(whiteDim).join(','),
    gray,
    gray_rgb: hex2rgb(gray).join(','),
    main,
    main_rgb: hex2rgb(main).join(','),
    muted,
    muted_rgb: hex2rgb(muted).join(','),
    yellow,
    yellow_rgb: hex2rgb(yellow).join(','),
    red
  },
  sizes: {
    margin: 8,
    borderRadius: 5,
    height: 56,
  },
  sizesMobile: {
    height: 42,
  },
  screens: {
    maxWidth: 1900,
  },
};
