import styled, { css } from 'styled-components';
import React, { PureComponent } from 'react';
import { Radio, Form  } from 'semantic-ui-react';
import { fonts } from 'Typography';
import sc from 'config/theme';
import PT from 'prop-types';

const background = '#fafafa';

const RadioGrouprWrapper = styled.div`

  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 72px;
  justify-content: center;
  align-items: center;
  padding: 19px 12px 12px;
  cursor: pointer;
  margin: 0 5px;

  input {
    display: none;
  }

`;
export class RadioGroup extends PureComponent {
  static propTypes = {
    name: PT.string,
    value0: PT.string,
    value1: PT.string,
    label0: PT.string,
    label1: PT.string,
    checked0: PT.bool,
    checked1: PT.bool,
    onChange: PT.func,
  }
  state = {}
  handleChange = (e, { value }) => this.setState({ value })

  render() {
     const {
      name,
      value0,
      value1,
      label0,
      label1,
      checked0,
      checked1,
      onChange,
      ...props
    } = this.props;
    return (
      <RadioGrouprWrapper >
        <Form.Field>
          <Radio
            label={label0}
            name={name}
            value={value0}
            checked={this.state.value === value0}
            onChange={this.handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label={label1}
            name={name}
            value={value1}
            checked={this.state.value === value1}
            onChange={this.handleChange}
          />
        </Form.Field>
      </RadioGrouprWrapper>
    )
  }
}

