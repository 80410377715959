import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import { Hero, Grid, Column, Spacer, AnimatedGrid, Media } from 'components/ui';
import breakpoint from 'config/responsive';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import bp from 'config/responsive';

const MobilePropertyBox = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 30px;
  margin-top: -100px;

  .parallax-image-1 {
    transform: translateY(50px) !important;

    ${bp.tablet}{
      transform: translateY(-50px) !important;
    }
    ${bp.computer}{
      transform: translateY(50px) !important;
    }
  }
  .parallax-image-2 {
    transform: translateY(70px) !important;

    ${bp.tablet}{
      transform: translateY(-70px) !important;
    }
    ${bp.computer}{
      transform: translateY(70px) !important;
    }
  }

  ${bp.tablet} {
    padding: 0 50px;
  }
`;

const Content = styled.div`
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${sc.screens.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
  opacity: 1;

  height: 64vh;
  padding-top: 70px;
  min-height: 375px;


  ${breakpoint.large} {
    opacity: 1;
  }
  ${bp.computer} {
    height: inherit;
  }
  ${bp.mobileSm} {
    height: 70vh;
  }

  .grid {
    ${bp.computer}{
     opacity: 0;
    }
  }

`;

const TextContainer = styled.div`
  width: 90%;

  ${bp.computer} {
    width: 50%;
    max-width: 620px;
  }
`;

const Headline = styled.div`
  margin-bottom: 15px;

  ${bp.computer} {
    margin-bottom: 15px;
  }

  ${bp.large} {
    margin-bottom: 42px;
  }
`;



const StyledBackgroundSection = styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;

`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${sc.colors.main_rgb}, 0.93),
      rgba(${sc.colors.muted_rgb}, 0.87)
    );
    top: 0;
    left: 0%;
  }
`;

const StyledShot = styled.div`
  width: 100%;
  padding-top: 60%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  background-image: url(${({ src }) => src}) ;
  z-index: 2;
  position:relative;
  overflow:hidden;
`;

const ShotContainer = styled.div`
  position: relative;
`;

const StyledAnimatedGrid = styled.div`
  position: absolute !important;
  right: -10%;
  bottom: -22%;
  z-index: 0;
  width: 150px;
  height: 150px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${bp.computer} {
    width: inherit;
    height: inherit;
  }
`;

export class AboutHero extends PureComponent {
  render() {
    const {
      content: { hero },
    } = this.props;

    return (

      <Hero  text="scroll" className="whiteBg">

            <Media
            breakpoint={bp.computer}
            renderIfTrue={() => (

              <React.Fragment>
                <Background>
                  <StyledBackgroundSection
                    src={hero.img}
                  />
                </Background>
                <Content>
                  <Grid>
                    <Spacer />
                    <Column column center width={4}>
                      <Headline>
                        <Script alpha color="white" align="left" className="title">
                          {hero.title}
                        </Script>
                      </Headline>
                      <Sans alpha color="white" className="headerDescription">
                        {hero.subtitle}
                      </Sans>
                    </Column>
                    <Spacer />
                    <Column column center width={5} className="rightImage">
                      <ShotContainer>
                        <StyledShot className="parallax-image-1" src={hero.shot} />
                        <StyledAnimatedGrid  className="parallax-image-2" >
                          <AnimatedGrid />
                        </StyledAnimatedGrid>
                      </ShotContainer>
                    </Column>
                    <Spacer />
                  </Grid>
                </Content>
              </React.Fragment>
            )}
            renderIfFalse={() => (
              <React.Fragment>
                <Background>
                  <StyledBackgroundSection
                    src={hero.img}
                  />
                </Background>
                <Content>
                  <Grid>
                    <Column verticalAlign column center width={12}>
                      <TextContainer>
                        <Headline>
                          <Script alpha color="white" align="left" className="title">
                            {hero.title}
                          </Script>
                        </Headline>
                        <Sans alpha color="white" className="headerDescription">
                          {hero.subtitle}
                        </Sans>
                      </TextContainer>
                    </Column>

                  </Grid>
                </Content>
                <MobilePropertyBox className="mobilePropertyBox">
                  <ShotContainer>
                    <StyledShot className="parallax-image-1" src={hero.shot} />
                    <StyledAnimatedGrid  className="parallax-image-2" >
                      <AnimatedGrid />
                    </StyledAnimatedGrid>
                  </ShotContainer>
                </MobilePropertyBox>
              </React.Fragment>
            )} />
      </Hero>
    );
  }
}

export default AboutHero;
