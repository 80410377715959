import React, { Component } from 'react'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'

export const HeaderTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  color: green;
  margin-bottom: 1em;
`

export class Header extends Component {
  render() {
    return (
      <div>
        <HeaderTitle><Icon name="chess king" /> Semantic UI React's icon :)</HeaderTitle>
      </div>
    );
  }
}

export default Header;
