import React, { PureComponent } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

export class ViewportEnterAnimation extends PureComponent {
  state = {
    isVisible: false,
  }

  handleChange = (isVisible) => {
    const { animation } = this.props;

    if (isVisible && !this.state.isVisible) {
      this.setState({ isVisible })
      animation && animation();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <VisibilitySensor delayedCall partialVisibility onChange={this.handleChange}>
        {children}
      </VisibilitySensor>
    );
  }
}