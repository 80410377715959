import React from 'react';
import styled from 'styled-components';
import sc from 'config/theme';

import iconHome from 'images/icon-home.svg';

const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 115px;
  height: 115px;
  margin: -53px auto -62px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
  border: 6px solid ${sc.colors.white};
  background-color: ${sc.colors.main};
  position: relative;
  z-index: 1;
`;

export const NewHomesHomeBadge = () => (
  <BadgeContainer>
    <img src={iconHome} alt="" />
  </BadgeContainer>
);