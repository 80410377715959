import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Grid,
  Spacer,
  Column,
  InnerContainer,
  PulsingButton,
  Media,
} from 'components/ui';
import { Serif } from 'Typography';
import PlaneMap from './PlaneMap';
import bp from 'config/responsive';

const Container = styled.div`
  position: relative;
  margin: 60px 0 50px;


  ${bp.computer} {
    margin: 100px 0 150px;
  }
  ${bp.smallNotebook} {
    margin: 200px 0 250px;
  }
  ${bp.large} {
    margin: 300px 0 250px;
  }

  & {
    .homeOfficeText {
      padding-left: 25px !important;
      padding-right: 25px !important;

      ${bp.computer} {
        opacity: 0;
        transform: translateY(30px);
        padding-left: 0!important;
        padding-right: 95px !important;
      }
    }
  }
`;

const StyledInnerContainer = styled(InnerContainer)``;

const StyledGrid = styled(Grid)`
  height: 100%;
`;

const PlaneMapContainer = styled.div`
  position: relative;
  right: 0;
  top: 0;
  width: 100%;
  transform: translateY(0%);
  margin-top: 40px;

  ${bp.computer} {
    position: absolute;
    right: 0;
    top: 50%;
    width: 50%;
    transform: translateY(-50%);
  }

`;
export class HomeOffices extends PureComponent {
  handleButtonClick = () => {
    console.log('clickedthe button')
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }
  
  render() {
    const { content } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <StyledInnerContainer>
            <StyledGrid>
              <Spacer />
              <Column width={5} center column  className="homeOfficeText">
                <h2>
                  <Serif alpha color="main">
                    {content.description}
                  </Serif>
                </h2>
                <PulsingButton onClick={this.handleButtonClick} >{content.callToAction}</PulsingButton>
              </Column>
            </StyledGrid>
            <PlaneMapContainer>
              <PlaneMap />
            </PlaneMapContainer>
          </StyledInnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container>
          <StyledInnerContainer>
            <StyledGrid>
              <Column width={12} center column  className="homeOfficeText">
                <h2>
                  <Serif alpha color="main">
                    {content.description}
                  </Serif>
                </h2>
                <PulsingButton onClick={this.handleButtonClick} >{content.callToAction}</PulsingButton>

                <PlaneMapContainer>
                  <PlaneMap />
                </PlaneMapContainer>
              </Column>
            </StyledGrid>
            
          </StyledInnerContainer>
        </Container>  
      )} />
    );
  }
}

export default HomeOffices;
