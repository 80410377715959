import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Grid, Column, InnerContainer, Media } from 'components/ui';
import { SimilarPropertiesSlider } from 'components/blocks';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import request from 'utils/request';
import bp from 'config/responsive';


const Container = styled.div`
  padding: 40px 10px 150px;
  background: ${sc.colors.white};

  .buySlider {
    ${bp.computer} {
      opacity: 0;
    }
  }

  ${bp.computer} {
    padding: 104px 60px 142px;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 50px;

  .subtitle {
    margin-top: 10px;
  }
`;

export class BuyPropertySlider extends PureComponent {
  static propTypes = {
    title: PT.string.isRequired,
    description: PT.string.isRequired,
  };

  state = {
    data: [],
    loading: false,
    error: false,
  }

  getData = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('homeLatestProperties?propertyType=new');
      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };

  async componentDidMount() {
    this.getData();
  }

  render() {
    const { content } = this.props;
    const { loading, data } = this.state;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
          <Container>
            <InnerContainer>
              <Grid className="buySlider">
                <Column width={12} className="buySliderInner">
                  <TitleWrapper>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                    <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                  </TitleWrapper>
                  {!loading && <SimilarPropertiesSlider data={data} />}
                </Column>
              </Grid>
              
            </InnerContainer>
          </Container>
        )}
        renderIfFalse={() => (
          <Container>
            <InnerContainer>
              <Grid className="buySlider">
                <Column width={12} className="buySliderInner">
                  <TitleWrapper>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                    <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                  </TitleWrapper>
                  
                </Column>
              </Grid>
              <Grid className="buySlider">
                <Column width={1}></Column>
                <Column width={10}>
                  {!loading && <SimilarPropertiesSlider data={data} />}
                </Column> 
                <Column width={1}></Column>
              </Grid>
              

            </InnerContainer>
          </Container>
        )} />
    );
  }
}

export default BuyPropertySlider;
