import React from 'react';

export const form = {
  zip: '',
  first_name: '',
  last_name: '',
  phone: '',
  mobile: '',
  address: '',
  city: '',
  gdpr: false,
  propertyType: null,
  bedrooms: null,
  instantValuationType: 'sale-price',
  timeChoice: "morning",
  dateChoice: new Date(),
};

export const activeStep = 1;

export const InstantValuationContext = React.createContext({
  form,
  activeStep,
});