import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: calc(100% / ${({ width }) => width});
  height: 100%;
  background: url(${({ preload, url }) => preload && url}) no-repeat center
    center;
  background-size: cover;
`;

export class PreloadSlide extends PureComponent {
  state = { preload: false };
  calculatePreload = () => {
    const { index, current } = this.props;
    return (
      index === current
    );
  };
  componentDidUpdate(prevProps) {
    if (!this.state.preload) {
      if (prevProps.current !== this.props.current) {
        this.setState({
          preload: this.calculatePreload(),
        });
      }
    }
  }
  componentDidMount() {
    this.setState({
      preload: this.calculatePreload(),
    });
  }
  render() {
    const { width, url } = this.props;
    const { preload } = this.state;
    return <Container url={url} width={width} preload={preload} />;
  }
}

export default PreloadSlide;
