import { PureComponent } from 'react';
import PT from 'prop-types';

export class Media extends PureComponent {
  state = {
    visibility: false,
  };

  static propTypes = {
    onChange: PT.func,
    renderIfTrue: PT.func,
    renderIfFalse: PT.func,
  };

  static defaultProps = {
    onChange: () => null,
    renderIfTrue: () => null,
    renderIfFalse: () => null,
  };

  calculateMedia = () => {
    const { onChange } = this.props;
    const visibility = window.matchMedia(`(
      ${this.props.breakpoint.match(/\((.*)\)/)[1]}
    )`).matches;

    if (visibility !== this.state.visibility) {
      onChange && onChange(visibility);
    }

    this.setState({ visibility });
  };

  componentDidMount() {
    this.calculateMedia();
    window.addEventListener('resize', this.calculateMedia);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateMedia);
  }

  render() {
    const { visibility } = this.state;
    const { renderIfTrue, renderIfFalse } = this.props;

    return visibility ? renderIfTrue() : renderIfFalse();
  }
}

export default Media;
