import React, { PureComponent } from 'react';
import Slick from 'react-slick';
import PT from 'prop-types';
import styled from 'styled-components';
import { SliderArrow } from 'components/ui';
import bp from 'config/responsive';

const StyledSlick = styled(Slick)`
  width: 100%;
  position: relative;
  height: 55vh;
  min-height: 350px;

  ${bp.tablet} {
    min-height: 85vh;
  }

  ${bp.smallNotebook} {
    height: 100vh;
    min-height: 700px;
  }
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > * {
    height: 100%;
  }

  .slick-slide,
  .slick-slide > * {
    outline: none;
  }

  .slick-prev,
  .slick-next {
    left: 55px;
    bottom: 26px;
    top: unset;

    ${bp.computer} {
      left: 75px;
      bottom: 36px;
    }
    
  }
  .slick-next {
    left: 111px;

    ${bp.computer} {
      left: 161px;
    }
  }


`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  outline: none;
`;

const CoverImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-repeat:no-repeat;
  background-size: ${({ cover }) => cover};
  background-position: center;
  background-image: url(${({ source }) => source});
  background-color: white;
  
   ${bp.mobile}{
   
    background-size: ${({ mobile }) => mobile};
   
 
   }
`;

export class SinglePropertyHeaderSlider extends PureComponent {
  static propTypes = {
    photoList: PT.arrayOf(PT.string),
  };

  sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
  };

  render() {
    const { photoList } = this.props;
    const { cover } = this.props;
    var {mobile} = this.props; 
    var className = "";
    if (cover == "cover"){
      className='togglegallery';
    }


    return (
      <StyledSlick {...this.sliderSettings}  className={className}>
        {photoList.map(photoUrl => (
          <Slide key={photoUrl}>
            <CoverImage source={photoUrl} cover={cover} mobile={mobile} />
          </Slide>
        ))}
      </StyledSlick>
    );
  }
}

export default SinglePropertyHeaderSlider;
