import React from 'react';

export const form = {
  priceMin: 100000,
  priceMax: 1000000,

  rangeMin : 100000,
  rangeMax : 1000000,

  enquiryType: 'Buy',
  area: '',

  propertyType: null,
  bedrooms: null,
  more: '1-option',
  stepSize: 10000,

  sortBy: 'price' ,
  sortOrder: 'DESC',
  page: 1,
};

export const FormContext = React.createContext({
  form,
});
