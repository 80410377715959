import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import { Icon } from 'semantic-ui-react';
import PT from 'prop-types';
import bp from 'config/responsive';


const Container = styled.div`
  background: ${sc.colors.white};
  border-radius: 10px;
  opacity: 0;
  /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15); */
  padding: 60px 30px ;
  width: 100%;

  ${bp.computer} {
    opacity: 0.5;
  }

  transition: ${({ animationLength }) => animationLength}ms
    ${({ easing }) => easing};
    transform-origin: left center;
    transform: scale(1) translate3d(0%, 0, 0);

  ${bp.computer} {
    padding: 70px 68px 70px 105px;
    width: 800px;
    transform: scale(0.62) translate3d(-15%, 0, 0);
  }
  ${bp.large} {
    width: 1020px;
    padding: 150px 88px 115px 145px;
  }

  ${({ disabledOutside }) => disabledOutside && css``}

  ${({ active }) =>
    active &&
    css`
      transform: none;
      opacity: 1;
      ${'' /* box-shadow: 0 5px 40px 30px rgba(0, 0, 0, 0.15); */}

      ${bp.computer} {
        transform: none;
        opacity: 1;
      }

    `};
`;

const Text = styled.div`
  padding-left: 0;
  margin-bottom: 50px;
  text-align: center;

  ${bp.computer} {
    padding-left: 25px;
    text-align: left;
  }
`;

const Name = styled.div`
  padding-left: 0;
  justify-content: center;
  display: flex;

  ${bp.computer} {
    padding-left: 25px;
    justify-content: inherit;
    display: inherit;
  }
`;

const Content = styled.div`
  position: relative;

  > * {
    color: #e0e0e0;

    /* color: transparent; */
    /* text-shadow: 0 0 20px rgba(0, 0, 0, 0.4); */
    transition: ${({ animationLength }) => animationLength}ms
      ${({ easing }) => easing};
  }

  ${({ active }) =>
    active &&
    css`
      > * {
        opacity: 1;
        color: ${sc.colors.main};
        ${'' /* text-shadow: 0 0 0 rgba(0, 0, 0, 0); */}
      }
    `}
`;

const QuotesIcon = styled(Icon)`
  &&& {
    position: absolute;
    top: -10px;
    right: 75%;
    width: 64px;
    font-size: 64px;
    opacity: 0.07;
    color: #0b1d3f;
    display: none;

    ${bp.computer} {
      top: 3px;
      right: 100%;
      display: block;
    }
  }
`;

export class RecommendationSlide extends PureComponent {
  static propTypes = {
    disabledOutside: PT.bool,
    active: PT.bool,
    text: PT.string,
    name: PT.string,
    oldIndex: PT.number,
    currentIndex: PT.number,
    index: PT.number,
    easing: PT.string,
    animationLength: PT.number,
  };

  render() {
    const {
      animationLength,
      easing,
      active,
      disabledOutside,
      text,
      name,
    } = this.props;

    return (
      <Container
        easing={easing}
        animationLength={animationLength}
        active={active}
        disabledOutside={disabledOutside}
      >
        <Content
          easing={easing}
          animationLength={animationLength}
          active={active}
        >
          <QuotesIcon name="quote left" />
          <Text>
            <Sans gamma weight="medium" lineHeight="1.75">
              {text}
            </Sans>
          </Text>
          <Name>
            <Script beta>{name}</Script>
          </Name>
        </Content>
      </Container>
    );
  }
}

export default RecommendationSlide;
