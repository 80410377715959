import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Grid, Column, InnerContainer, Media } from 'components/ui';
import { SimilarPropertiesSlider } from 'components/blocks';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import bp from 'config/responsive';
import request from 'utils/request';



const Container = styled.div`

  background: ${sc.colors.white};
  padding: 50px 10px 150px;
  .propeprtySlider {
    ${bp.computer} {
      opacity: 0;
    }
  }

  ${bp.computer} {
    padding: 104px 60px 142px;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 50px;

  .subtitle {
    margin-top: 10px;
  }
`;

export class SinglePropertySimilarSlider extends PureComponent {
  static propTypes = {
    data: PT.arrayOf(PT.object).isRequired,
    title: PT.string.isRequired,
    description: PT.string.isRequired,
  };

  state = {
    data: [],
    loading: true,
    error: false,
  };

  getData = async (filter) => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('homeLatestProperties'+filter);
      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };
  async componentDidMount() {
    let query = '?propertyType=new'
    this.getData(query);
  }


  render() {
    const { data } = this.state;
    const { content } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
          <Container>
            <InnerContainer>
              <Grid className="propeprtySlider">
                <Column width={12} className="propeprtySliderInner">
                  <TitleWrapper>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                    <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                  </TitleWrapper>
                  <SimilarPropertiesSlider data={data} />
                </Column>
              </Grid>
              
            </InnerContainer>
          </Container>
        )}
        renderIfFalse={() => (
          <Container>
            <InnerContainer>
              <Grid className="propeprtySlider">
                <Column width={12} className="propeprtySliderInner">
                  <TitleWrapper>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                    <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                  </TitleWrapper>
                  
                </Column>
              </Grid>
              <Grid>
                <Column width={1}></Column>
                <Column width={10}>
                  <SimilarPropertiesSlider data={data} />
                </Column>
                <Column width={1}></Column>
              </Grid>
            </InnerContainer>
          </Container>
        )} />
    );
  }
}

export default SinglePropertySimilarSlider;

