import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slick from 'react-slick';
import {
  Grid,
  Spacer,
  Column,
  SliderArrow,
  InnerContainer,
  Media,
} from 'components/ui';
import RecommendationSlide from './RecommendationSlide';
import bp from 'config/responsive';

const easing = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)';
const animationLength = 500;

const Container = styled.div`
  opacity: 1;

  ${bp.computer} {
    opacity: 0;
    transform: translateY(30px);
  }

  .slickMobile {
    .slick-slide {
      width: 100%;
    }
    .slick-arrow {
      display: none !important;
    }
  }

  &.recommendationsSlider {
    .slick-arrow {
      display: none !important;

      ${bp.smallNotebook} {
        display: flex !important;
      }
    }
  }
`;

const StyledColumn = styled(Column)`
  &&& {
    padding-top: 0 !important;
    margin-top: 100px;

    ${bp.computer} {

    }

    ${bp.large} {
      margin-top: -60px;
    }
  }
`;

const StyledSlick = styled(Slick)`
  * {
    outline: none;
  }
  &.slider-outer {
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
      align-items: flex-start;

      ${bp.computer} {
        align-items: center;
      }
    }

    .slick-slide {
      position: relative;
      z-index: 22;
    }
    .slick-active.slick-current {
      z-index: 20;

      ~ .slick-slide {
        z-index: 12;

        ~ .slick-slide {
          z-index: 10;
        }
      }
    }
    .slick-arrow {
      left: unset;
      right: 0;
      top: unset;
      bottom: 0;
      margin-top: 0;
    }
    .slick-prev {
      right: 45px;
    }
    .slick-next {
      right: -30px;
    }
  }
`;

const data = [
  {
    text:
      'Fantastic service from Jack Wylie, Canary Wharf. Not only was Jack very accommodating when arranging viewings at very short notice, but he has since gone out of his way to provide assistance outside of typical working hours. Jack’s continuing help up to and beyond the point of agreeing a sale has been both incredibly helpful and greatly appreciated. Thoroughly recommended!',
    name: 'M. Holloway',
  },
  {
    text:
      'Dinosaurs are a diverse group of reptiles of the clade Dinosauria. They first appeared during the Triassic period, between 243 and 233.23 million years ago, although the exact origin and timing of the evolution of dinosaurs is the subject of active research.',
    name: 'J. C. Penney',
  },
  {
    text:
      'The ostriches are a family, Struthionidae, of flightless birds. The two extant species of ostrich are the common ostrich and Somali ostrich, both in the genus Struthio, which also contains several species known from Holocene fossils such as the Asian ostrich. The common ostrich is the more widespread of the two living species, and is the largest living bird species.',
    name: 'D. Attenborough',
  },
  {
    text:
      'The earliest fossils of ostrich-like birds are Paleocene taxa from Europe. Palaeotis and Remiornis from the Middle Eocene and unspecified ratite remains are known from the Eocene and Oligocene of Europe and Africa.',
    name: 'M. Zuckerberg',
  },
];

export class RecommendationsSlider extends PureComponent {
  state = {
    currentIndex: 0,
    oldIndex: data.length - 1,
  };
  outerSliderSettings = {
    className: 'slider-outer',
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    initialSlide: 0,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    cssEase: easing,
    speed: animationLength,
    autoplay: true,
    autoplaySpeed: 8000, 
    pauseOnHover: false, 

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          adaptiveHeight: true
        }
      }
    ]
  };
  afterChange = index => {
    this.setState({ currentIndex: index });
  };
  beforeChange = (oldIndex, newIndex) => {
    this.setState({ oldIndex, currentIndex: newIndex });
  };
  render() {
    const { currentIndex, oldIndex } = this.state;
    let { data } = this.props;
    if (! Array.isArray(data)){
        data = Object.keys(data).map(function(key) {
        return {text:data[key].text, name:data[key].name};
      });
    }
    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container className="recommendationsSlider">
          <InnerContainer>
            <Grid>
              <Spacer />
              <StyledColumn width={10}>
                <StyledSlick
                  {...this.outerSliderSettings}
                  afterChange={this.afterChange}
                  beforeChange={this.beforeChange}
                >
                  {data.map((el, index) => {
                    const active = index === currentIndex;

                    return (
                      <RecommendationSlide
                        key={el.text}
                        disabledOutside={index !== currentIndex + 1}
                        active={active}
                        text={el.text}
                        name={el.name}
                        oldIndex={oldIndex}
                        currentIndex={currentIndex}
                        index={index}
                        easing={easing}
                        animationLength={animationLength}
                      />
                    );
                  })}
                </StyledSlick>
              </StyledColumn>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container className="recommendationsSlider">
          <InnerContainer>
            <Grid>
              <StyledColumn width={12} className="slickMobile">
                <StyledSlick
                  {...this.outerSliderSettings}
                  afterChange={this.afterChange}
                  beforeChange={this.beforeChange}
                >
                  {data.map((el, index) => {
                    const active = index === currentIndex;

                    return (
                      <RecommendationSlide
                        key={el.text}
                        disabledOutside={index !== currentIndex + 1}
                        active={active}
                        text={el.text}
                        name={el.name}
                        oldIndex={oldIndex}
                        currentIndex={currentIndex}
                        index={index}
                        easing={easing}
                        animationLength={animationLength}
                      />
                    );
                  })}
                </StyledSlick>
              </StyledColumn>
            </Grid>
          </InnerContainer>
        </Container>
      )} />
    );
  }
}

export default RecommendationsSlider;
