import React, { PureComponent } from 'react';
import styled from 'styled-components';
import sc from 'config/theme';
import { Portal } from 'react-portal';
import { CloseButton } from 'components/ui';
import bp from 'config/responsive';

const LightboxImage  = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(${sc.colors.white_rgb}, 0.95);
  z-index: 100000;
  display: none;
  cursor: zoom-out;

  ${({ active }) => active && 'display: block;'}
`;

const LightboxContainer = styled.div`
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  padding: 15px;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${bp.computer} {
    width: calc(100% - 140px);
    height: calc(100% - 140px);
    margin: 70px;
    padding: 95px;
  }

  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
`;

export class LightboxWrapper extends PureComponent {
  state = {
    active: false,
  }

  showLightbox = () => this.setState({ active: true });

  hideLightbox = () => this.setState({ active: false });

  render() {
    const { img, children } = this.props;
    const { active } = this.state;

    return (
      <React.Fragment>
        {React.Children.map(children, child =>
          React.cloneElement(child, { onClick: this.showLightbox })
        )}
        <Portal>
          <LightboxImage active={active} onClick={this.hideLightbox}>
            <LightboxContainer>
              <CloseButtonWrapper>
                <CloseButton onClick={this.hideLightbox} />
              </CloseButtonWrapper>
              <img src={img} alt="" />
            </LightboxContainer>
          </LightboxImage>
        </Portal>
      </React.Fragment>
    );
  }
}