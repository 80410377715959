


const formatPrice = (number) => {

	if (number == 0){
		return "Price on Application";
	}
 	return Number(number).toLocaleString('en-GB', { currency: 'GBP', style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0 })
}

export default formatPrice;
