import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import sc from 'config/theme';
import { CloseButton, Media } from 'components/ui';
import bp from 'config/responsive';

const animationLength = 700;

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;

  padding: 70px 25px 0;
  background-color: ${({ dark }) => (dark ? sc.colors.main : sc.colors.white)};
  z-index: 99;
  overflow: auto;
  transition: transform ${animationLength}ms ease;
  transform: translate3d(${({ isOpen }) => (isOpen ? 0 : '100%')}, 0, 0);
  -webkit-overflow-scrolling: touch;

  ${bp.computer} {
    width: 50%;
    min-width: 375px;
    max-width: 550px;
    padding: 150px 47px 60px;
    z-index: ${({ aboveNavbar }) => (aboveNavbar ? 25 : 10)};
  }
  ${bp.large} {
    width: 30%;
  }

`;

const FullscreenOverlay = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    228deg,
    #1e3a70 0%,
    #0e234b 31%,
    #01153a 67%,
    #0b101f 100%
  );
  opacity: ${({ isOpen }) => (isOpen ? 0.93 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  z-index: ${({ aboveNavbar }) => (aboveNavbar ? 24 : 9)};
  transition: all ${animationLength / 2}ms ease-out;
`;

const ContentContainer = styled.div`
  width: 100%;
  opacity: ${({ contentVisible }) => (contentVisible ? 1 : 0)};
  transform: translate3d(
    0,
    ${({ contentVisible }) => (contentVisible ? 0 : -6)}px,
    0
  );
  transition: all ${animationLength / 2.25}ms ${animationLength * 0.85}ms
    ease-out;

    height: 100vh;

  > :last-child {
    margin-bottom: 90px;
  }
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 28px;
  right: 28px;
`;

export class SlidingContainer extends PureComponent {
  static propTypes = {
    closeButton: PT.bool,
    fullscreenOverlay: PT.bool,
    dark: PT.bool,
    isOpen: PT.bool,
    aboveNavbar: PT.bool,
  };

  render() {
    const {
      children,
      isOpen,
      fullscreenOverlay,
      closeButton,
      ...otherProps
    } = this.props;

    const closeModal = typeof window === 'undefined' ? null : window.closeModal;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (

        <React.Fragment>
          {fullscreenOverlay && (
            <FullscreenOverlay isOpen={isOpen} onClick={closeModal} aboveNavbar={otherProps.aboveNavbar} />
          )}
          <Wrapper isOpen={isOpen} {...otherProps}>
            {closeButton && (
              <CloseButtonWrapper>
                <CloseButton onClick={closeModal} />
              </CloseButtonWrapper>
            )}
            <ContentContainer contentVisible={isOpen}>
              {children}
            </ContentContainer>
          </Wrapper>
        </React.Fragment>
      )}
      renderIfFalse={() => (
        <React.Fragment>
          {fullscreenOverlay && (
            <FullscreenOverlay isOpen={isOpen} onClick={closeModal} aboveNavbar={otherProps.aboveNavbar} />
          )}
          <Wrapper isOpen={isOpen} {...otherProps}>
          <CloseButtonWrapper>
                <CloseButton onClick={closeModal} />
              </CloseButtonWrapper>
            <ContentContainer contentVisible={isOpen}>
              {children}
            </ContentContainer>
          </Wrapper>
        </React.Fragment>
      )} />
    );
  }
}

export default SlidingContainer;
