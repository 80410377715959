import React, { PureComponent } from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* opacity: 0; */

  top: 0;
  left: 0;
  transform: translatey(-100%) scale(1.2);
  transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);

  ${({ active }) =>
    active &&
    css`
      /* opacity: 0.99 !important; */
      transform: translatey(0px) scale(1);
    `}
`;

const StyledBackgroundSection = styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0 !important;
  background-image: url(${({ src }) => src}) ;

  transform: translatey(-50px) scale(1.2);
  transition: all 1.5s 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  ${({ active }) =>
    active &&
    css`
      opacity: 0.99 !important;
      transform: translatey(0px) scale(1);
    `}
`;

export class TransitionHero extends PureComponent {
  state = { 
    active: this.props.active, 
    visible: this.props.active, 
  };
  componentDidUpdate(prevProps) {
    if (prevProps.active !== this.props.active) {
      setTimeout(this.setState({ active: this.props.active }), 2000);
    }
  }

  // componentDidMount() {
  //   setTimeout(() => this.setState({ active: true }), 0);
  // }
  // componentWillMount() {
  //   setTimeout(() => this.setState({ active: false }), 0);
  // }
  render() {
    const { active, visible } = this.state;
    const { src } = this.props;
    if (!active) return null;

    return (
      <Wrapper active={active} visible={visible}>
        <StyledBackgroundSection active={active} src={src} />
      </Wrapper>
    );
  }
}

export default TransitionHero;
