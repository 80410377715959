import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import sc from 'config/theme';
import luminate from 'utils/luminate';
import bp from 'config/responsive';


const arrowHeight = 60;
const margin = 30;

const Arrow = styled.div`
  width: 45px;
  height: 45px;
  background: #fff;
  border: 1px solid rgba(${sc.colors.gray_rgb}, 0.41);
  border-radius: 50%;
  position: absolute;
  left: 0%;
  transform: translatex(-50%);
  z-index: 2;
  top: 100%;
  margin-top: 28px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  left: calc(50% - ${arrowHeight / 2 + margin / 2}px);
  transition: background-color 0.25s ease;

  ${bp.computer} {
    margin-top: 58px;
  }

  &:hover {
    background-color: ${luminate(sc.colors.white, -0.2)};
  }
  i {
    color: ${sc.colors.main} !important;
    font-size: 20px !important;
    width: unset !important;
    height: unset !important;
  }
  &::before {
    display: none;
  }
  ${({ next }) =>
    next &&
    css`
      left: calc(50% + ${arrowHeight / 2 - margin / 2 + margin}px);
    `}

  
    ${bp.computer} {
      width: ${arrowHeight}px;
      height: ${arrowHeight}px;

    }
    &.slick-next {
        i {
          transform: translateX(2px);
        }
      }
`;

export const SliderArrow = ({ next, ...rest }) => (
  <Arrow next={next} {...rest}>
    <Icon name={`caret ${next ? 'right' : 'left'}`} />
  </Arrow>
);

export default SliderArrow;
