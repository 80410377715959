import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import 'react-input-range/lib/css/index.css';
import Range from 'react-input-range';
import AutosizeInput from 'react-input-autosize';
import sc from 'config/theme';
import bp from 'config/responsive';

const height = 32;

const InputRangeWrapper = styled.div`
  flex: 1;
  margin: ${sc.sizes.margin}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${sc.colors.white};

  .input-range {
    height: 32px;
  }
  .input-range__label--value,
  .input-range__label {
    display: none;
  }

  .input-range__track {
    height: 8px;
    background: #e3e3e3;
    border-radius: 0;
  }
  .input-range__slider {
    height: ${height}px;
    width: ${height}px;
    margin-top: -21px;
    border: 2px solid ${sc.colors.yellow};
    background: ${sc.colors.main};
  }
  .input-range__slider-container {
    .input-range__slider {
      margin-left: -1px;
    }
  }
  .input-range__slider-container:nth-of-type(2) {
    .input-range__slider {
      margin-left: -31px;
    }
  }

  .input-range__slider:active {
    transform: scale(1);
  }
  .input-range__track--background {
    margin-top: unset;
    transform: translateY(-50%);
  }
  .input-range__track--active {
    background: ${sc.colors.yellow};
  }

  & input {
    appearance: none;
  }
  & input:disabled {
    opacity: 1 !important;
    -webkit-appearance: none;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  ${bp.computer} {
    ${({ padded }) =>
      padded &&
      css`
        margin-bottom: 40px;
      `}
  }
`;

const Label = styled.div`
  color: ${({ dark }) => (dark ? sc.colors.main : sc.colors.white)};
  position: relative;
  padding: ${sc.sizes.margin / 2}px 0;
  cursor: pointer;
  font-family: 'acumin-pro', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background: ${sc.colors.white};
    transition: all 0.2s ease-in-out;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;

    display: none;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      &::before {
        width: 100%;
      }
    `}
`;

const Input = styled(AutosizeInput)`
  input {
    background: transparent;
    border: none;
    color: ${({ dark }) => (dark ? sc.colors.main : sc.colors.white)};
    cursor: pointer;
    font-family: 'acumin-pro', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 14px;

    &:focus {
      outline: none;
    }
  }
`;

const stepSize = 100;

export class InputRange extends PureComponent {
  static defaultProps = {
    minValue: 200,
    maxValue: 10000,
    value: {
      min: 200,
      max: 10000,
    },
    typeT : '',
    stepSize : 100,

    handleChange: () => null,
  };

  state = {
    value: this.props.value || {
      min: this.props.minValue,
      max: this.props.maxValue,
    },
    typeT : this.props.typeT,
    minValue:  this.props.minValue,
    maxValue:  this.props.maxValue,
    inputMinFocused: false,
    inputMaxFocused: false,
    stepSize :  this.props.minValue,
    temporary: { min: 0, max: stepSize },
  };

  inputMin = React.createRef();
  inputMax = React.createRef();

  handleRangeChange = value => {

    const { minValue, maxValue } = this.props;
    if (value.max - value.min < (maxValue - minValue) / 10) return;

    // this.setState({ value });
    this.props.handleChange(value);
  };

  handleChange = ({ target }) => {
    

    const { name, value } = target;
    const nextValue = Number(value.split(',').join(''));

    if (nextValue > this.props.maxValue) return; //IF max 

    if (name === 'min' && nextValue > this.state.value.max) return;


    if (name === 'min' && nextValue === this.state.value.max) {
      return this.handleRangeChange({
        ...this.state.value,
        [name]: nextValue - this.props.stepSize,
      });
    }


    if (name === 'max' && nextValue < this.state.value.min) return;

    if (name === 'max' && nextValue === this.state.value.min) {
      return this.handleRangeChange({
        ...this.state.value,
        [name]: nextValue + this.props.stepSize,
      });
    }
    this.handleRangeChange({
      ...this.state.value,
      [name]: nextValue,
    });
  };

  handleFocus = type => {
    this.setState({ [`${type}Focused`]: true });
    this[type].current.input.focus();
  };
  handleBlur = () =>
    this.setState({ inputMinFocused: false, inputMaxFocused: false });

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.value !== this.state.value) {
    //   this.props.handleChange(this.state.value);
    // }
    // if (prevProps.value !== this.props.value) {
    //   this.setState({ value: this.props.value });
    // }
  }

  render() {
    const { inputMinFocused, inputMaxFocused } = this.state;
    const { value, dark, styledInputRange, padded } = this.props;
    
    var lettingtext;
    var extrarange = "";


    if (this.props.typeT === "Rent") {

      if (this.props.value.max == 1000){
        extrarange = "+";
      }else{
        extrarange = "";
      }



      lettingtext = 'pw';
    } else {

      if (this.props.value.max == 1000000){
        extrarange = "+";
      }else{
        extrarange = "";
      }
      lettingtext = '';
    }


    return (
      <InputRangeWrapper styledInputRange={styledInputRange}>
        <LabelWrapper padded={padded}>
          <Label
            onClick={() => this.handleFocus('inputMin')}
            isFocused={inputMinFocused}
            dark={dark}
          >
            <span>£</span>
            <Input
              dark={dark}
              name="min"
              ref={this.inputMin}
              value={value.min.toLocaleString()}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              disabled
            />
            <span>{lettingtext}</span>
            
          </Label>
          <Label
            onClick={() => this.handleFocus('inputMax')}
            isFocused={inputMaxFocused}
            dark={dark}
          >

            <span>{extrarange} £</span>
            <Input
              dark={dark}
              name="max"
              ref={this.inputMax}
              value={value.max.toLocaleString()}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              disabled
            />
            
            <span>{lettingtext}</span>
          </Label>
        </LabelWrapper>
        <Range
          step={this.props.stepSize}
          maxValue={this.props.maxValue}
          minValue={this.props.minValue}
          value={value}
          onChange={this.handleRangeChange}
        />
      </InputRangeWrapper>
    );
  }
}

export default InputRange;
