import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { Serif, Sans } from 'Typography';
import { InnerContainer, Button } from 'components/ui';
import bp from 'config/responsive';


const Container = styled.div`
  padding: 133px 0 58px;
  background-color: #335eb3;

  .sectionGetAdvice {
    ${bp.computer} {
       opacity: 0;
    }
  }
  ${bp.computer} {
    padding: 133px 0 108px;
  }
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 655px;
  width: 100%;
  text-align: center;
  padding: 0 25px;

  ${bp.computer} {
    padding: 0 ;
  }
`;

const Title = styled.div`
  margin-bottom: 41px;
`;

const Content = styled.div`
  margin-bottom: 80px;
`;

const ctaClick = () => {
  if (typeof window !== 'undefined') {
    window.openEnquiry && window.openEnquiry();
  }
}

export class NewHomesGetAdvice extends PureComponent {
  static propTypes = {
    title: PT.string,
    content: PT.string,
    buttonText: PT.string,
  }

  render() {
    const { title, content, buttonText } = this.props;

    return (
      <Container>
        <InnerContainer className="sectionGetAdvice">
          <ContentWrapper className="sectionGetAdviceInner">
            <Title>
              <Serif alpha color="white" block>
                {title}
              </Serif>
            </Title>
            <Content>
              <Sans eta color="white" block>
                {content}
              </Sans>
            </Content>
            <Button onClick={ctaClick}>{buttonText}</Button>
          </ContentWrapper>
        </InnerContainer>
      </Container>
    );
  }
}