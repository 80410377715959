import styled, { css } from 'styled-components';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import { fonts } from 'Typography';
import sc from 'config/theme';

const background = '#fafafa';

export const Checkbox = styled(SemanticCheckbox)`
  &&& {
    label,
    input:focus + label,
    input:active + label {
      color: white;
      font-family: ${fonts.sans};
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.86px;
      padding-left: ${17 + 8}px;

      ${({ error }) => error && css`
        box-shadow: 0 0 6px red;
      `}

      &::before {
        background-color: ${background} !important;
        border-color: rgba(151, 151, 151, 0.22);
      }
    }

    input:checked + label::after {
      font-size: 12px;
      margin-left: 1px;
      margin-top: 1px;
    }
    ${({ fluid }) =>
    fluid &&
    css`
        width: 100%;
      `}
    ${({ color }) =>
    color &&
    css`
        label,
        input:focus + label,
        input:active + label {
          color: ${sc.colors[color]};
        }
      `}
      ${({ verticalCenter }) =>
    verticalCenter &&
    css`
          display: flex;
          align-items: center;
        `}
  }
`;
