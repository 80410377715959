import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, navigate } from 'gatsby';

import PT from 'prop-types';
import {
  InputGroup,
  PillMenu,
  Button,
  InputRange,
  Input,
  Dropdown,
  Checkbox,
} from 'components/ui';
import sc from 'config/theme';
import { Sans } from 'Typography';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import { searchOptions } from '../../../data';

const StyledTitle = styled(Sans)`
  margin-bottom: 40px;
`;

const DropdownContainer = styled.div`
  margin-bottom: 15px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;

const PillMenuContainer = styled.div`
  margin: 42px 0 20px;
`;

const PriceRangeContainer = styled(InputGroup)`
  margin-bottom: 40px;
`;

const DropdownIndicator = (props) => {
  return (
    null
  );
};
const IndicatorSeparator = (props) => {
  return (
    null
  );
};

const filterSearch = (area) => {


 const matches = searchOptions.filter(
    i =>
    i.label.toLowerCase().startsWith(area.toLowerCase())
  );

  const  arrLength = matches.length;
  if(arrLength > 3){
      matches.splice( 0, arrLength - 3);
  }

  return matches
};


const loadOptions = (area, callback) => {
  setTimeout(() => {
    callback(filterSearch(area));
  }, 1000);
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #ddd',
    color: '#0B1D3F',
    padding: 20,
    

  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 'inherit',
    background: '#fafafa',
    borderRadius: 6,
    'padding-top': 5,
    'padding-bottom': 5,
    'padding-left': 10,
    'padding-right': 10,
    display: 'flex',
    alignItems: 'center', 
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "'acumin-pro',sans-serif",
    "border": "1px solid rgba(151,151,151,0.22)",
    height: 54, 

    ':hover': {
      "border-color": "rgba(34,36,38,.35)"
    }
    
  }),
  placeholder: () => ({
    color: 'rgba(22,49,99,0.52) !important',
    "marginLeft": "2px",
    "marginRight": "2px",
    "position": "absolute",
    "top": "50%",
    "transform": "translateY(-50%)",
    "boxSizing": "border-box"
    
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}


export class SearchModal extends PureComponent {
  static propTypes = {
    hideTabs: PT.bool,
    defaultChecked: PT.string,
  };

  state = {
    form: {

    priceMin: 100000,
    priceMax: 1000000,

    rangeMin : 100000,
    rangeMax : 1000000,

    enquiryType: 'Buy',
    area: '',
    propertyType: null,
    bedrooms: null,

    more: ['1-option', '2-option'],

    includeNewHomes: true,

    sortBy: 'price' ,
    sortOrder: 'DESC', 

    stepSize : 10000,
    page : 1,

    },

    error: false,
    inputValue : null,
    menuIsOpen : false,
  };


  static defaultProps = {
    defaultChecked: 'lettings',
  };




  closeMenu = () => {
    this.setState({ menuIsOpen: false });
   };

  openMenuIsOpen = () => {
    this.setState({ menuIsOpen: true });

  };
  handleInputChange = (newValue) => {

    if (newValue != ""){
        this.openMenuIsOpen();
    }else{
      this.closeMenu();
    }
  };
  handleInputChange1 = (newValue, { form }) => {

    if (newValue != null){
      if (newValue.hasOwnProperty('value') ){
        const inputValue = newValue.value;
        this.setState({ inputValue });

        this.closeMenu();
        this.setArea(inputValue);
        return inputValue;
      }else{
        this.closeMenu();
        this.setArea('');

        return null;
      }
    }else{
      this.closeMenu();
      this.setArea('');
      return null;
    }
  };

  createlabel = (inputValue) => {
    return "Search \"".concat(inputValue, "\"");
  }

  handlePillChange = (offerType, { form }) => {

    const name = 'enquiryType';
    const value = form[name] === offerType ? '' : offerType;
    if (offerType === "Rent" ){
      form.rangeMax = 1000;
      form.rangeMin = 200;
      form.priceMin = 200;
      form.priceMax = 1000;
      form.stepSize = 50;
    }else{
       form.rangeMin = 100000;
       form.rangeMax = 1000000;
       form.priceMin = 100000;
       form.priceMax = 1000000;
       form.stepSize = 10000;
    } 
    const event = {
      target: {
        name,
        value,
      },
    };
    this.handleChange(event);
  };

handleChange = ({ target: { name, value } }) => {
    this.setState(({ form }) => ({
    form: {
      ...form,
      [name]: value,
    },
    }));
  };


  setArea = (area) => {

    this.setState(({ form }) => ({
    form: {
      ...form,
      area: area,
    },
    }));
  }

    handleSemanticChange = (_, { name, value, checked }) =>{
    if (name == 'includeNewHomes'){
      if (checked){
        this.setState(({ form }) => ({
          form: {
            ...form,
            more: ['1-option', '2-option'],
          },
        }));

      }else{

        this.setState(({ form }) => ({
          form: {
            ...form,
            more: ['2-option'],
          },
        }));

      }
  }

    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value || checked,
      },
    }));

  };


  handleRangeChange = ({ min, max }) =>{
    this.setState(({ form }) => ({
      form: {
        ...form,
        priceMin: min,
        priceMax: max,
      },
    }));
  }

  handleSearch = () =>{

      let str = '';
    const { form } = this.state;

    Object.keys(form).forEach((key, index, arr) => {
      if (index >= arr.length - 1) {
        str += `${key}=${form[key]}`;
      } else {
        str += `${key}=${form[key]}&`;
      }
    });

    navigate(`search-results?${str}`);

  }


  render() {
    return (
      <StaticQuery
        query={graphql`
          query SearchModal {
            pages {
              propertySearchForm {
                title
                offerTypes {
                  lettings
                  sales
                }
                defaultCost {
                  min
                  max
                }
                form {
                  labels {
                    area
                    apartment
                    bedrooms
                    includeNewHomes
                    searchBtn
                  }
                  selects {
                    apartment {
                      value
                      text
                    }
                    bedrooms {
                      value
                      text
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ pages: { propertySearchForm } }) => {
          const { title, offerTypes } = propertySearchForm;
          const { labels, selects } = propertySearchForm.form;
          const {form} = this.state;

                return (
                  <div>
                    <StyledTitle
                      epsilon
                      color="main"
                      weight="medium"
                      block
                    >
                      {title}
                    </StyledTitle>
                    <PillMenuContainer>
                      <PillMenu
                        dark
                        inputName="enquiryType"
                        showCheck
                      >
                       
                        <PillMenu.Pill
                          onClick={() =>
                            this.handlePillChange(offerTypes.sales, {
                              form
                            })
                          }
                          value={offerTypes.sales}
                          checked={
                            form['enquiryType'] ===
                            offerTypes.sales
                          }
                        >
                          Buy
                        </PillMenu.Pill>


                         <PillMenu.Pill
                          onClick={() =>
                            this.handlePillChange(offerTypes.lettings, {
                              form
                            })
                          }
                          checked={
                            form['enquiryType'] ===
                            offerTypes.lettings
                          }
                        >
                          Rent
                        </PillMenu.Pill>

                      </PillMenu>
                    </PillMenuContainer>

                    <InputGroup>
                      <input 
                        type="hidden" 
                        name="sortBy" 
                        value={form.sortBy} 
                      />  
                      <input 
                        type="hidden" 
                        name="sortOrder"
                        value={form.sortOrder} 
                      />  
                    <AsyncCreatableSelect

                        components={{ DropdownIndicator, IndicatorSeparator }}
                        placeholder="Search"
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        isClearable = {true}
                        formatCreateLabel = {this.createlabel}
                        menuIsOpen={this.state.menuIsOpen}
                        styles={customStyles}
                        onChange={(value) => {
                            this.handleInputChange1( value, {form})
                          }
                        }
                        onInputChange= {this.handleInputChange}

                    />
                    </InputGroup>

                    <DropdownContainer>
                      <InputGroup>
                        <Dropdown
                          placeholder={labels.bedrooms}
                          selection
                          onChange={this.handleSemanticChange}
                          name="bedrooms"
                          value={form.bedrooms}
                          options={selects.bedrooms.map(
                            select => ({
                              ...select,
                              key: select.value,
                            })
                          )}
                        />
                        <Dropdown
                          placeholder={labels.apartment}
                          selection
                          onChange={this.handleSemanticChange}
                          name="propertyType"
                          value={form.propertyType}
                          options={selects.apartment.map(
                            select => ({
                              ...select,
                              key: select.value,
                            })
                          )}
                        />
        
                      </InputGroup>
                    </DropdownContainer>
                    <PriceRangeContainer>
                      <InputRange
                        typeT = {form.enquiryType}
                        stepSize = {form.stepSize}

                        minValue = {form.rangeMin}
                        maxValue = {form.rangeMax}
                        value={{
                          min: form.priceMin,
                          max: form.priceMax,
                        }}
                        dark
                        handleChange={this.handleRangeChange}
                      />
                    </PriceRangeContainer>
                    <CallToActionContainer>

          
                        <Checkbox
                          color="main"
                          label={labels.includeNewHomes}
                          name="includeNewHomes"
                          checked={form.includeNewHomes}
                          onChange={this.handleSemanticChange}
                          defaultChecked
                        />
                      <Button alt block onClick={this.handleSearch}>
                        Search
                      </Button>
                    </CallToActionContainer>
                  </div>

          );
        }}
      />
    );
  }
}

export default SearchModal;
