import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import { StaticQuery, graphql } from 'gatsby';
import {
  FormProgress,
  PillMenu,
  Input,
  InputGroup,
  Button,
  FormSuccess,
  Dropdown,
  Checkbox,
  DatePicker,
  TimePicker,
} from 'components/ui';
import { Sans } from 'Typography';
import sc from 'config/theme';
import axios from 'axios';
import formatPrice from 'utils/formatPrice';



import { InstantValuationContext, activeStep, form } from 'context/instantValuation';

import request from 'utils/request';
import bp from 'config/responsive';


const PaddedRow = styled.div`
  margin-bottom: 35px;
  &.nomargin {
    margin-bottom: 0;

    ${bp.computer} {
      margin-bottom: 50px;
    }
  }

  .valPrice {
    font-size: 40px;
  }
  .valBody {
    font-size: 18px;
    ${bp.computer} {
      font-size: 20px;
    }
  }
  .white-text {
    color: #fff;
  }

`;

const Centertext= styled.div`
  text-align: center;
`;

const StyledTitle = styled(Sans)`
  margin-bottom: 40px;
  color: #fff;
`;

const StyledForm = styled.form`
  background-color: ${sc.colors.main};
  padding: 40px 40px 40px;

  ${bp.computer} {
    padding: 40px 40px 20px;
  }
  
`;

const ErrorMessage = styled.div`
  color: #d80000;
  margin: 0 0 15px;
`;

const Text = styled.div`
  margin: 0 60px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  
    
  }
  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;
const buttonStyle = css`
  &&& {
    padding: 0 70px;
  }
`;

const SectionTitle = styled(Sans)`
  letter-spacing: 0.57px;
  line-height: 8.82px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 27px;
  display: block;
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 40px;
`;

const TimePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% + 10px);
  margin: 0 -5px 60px;
  align-items: stretch;
`;

const SubmitButton = styled(Button)`
  &&& {
    margin: 0 auto 100px !important;
    display: block;

    ${bp.bigNotebook} {
      margin: 0 auto !important;
    }
  }
`;

const StyledSubtitle = styled(Sans)`
  margin-bottom: 50px;
`;
const Loading = styled.div`
    position: fixed;
    background: #1f2d52;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.6;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    overflow: hidden;
`

const LoadingOverlay = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #D9A547;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    animation: pulse 1s infinite ease;


  }

  @keyframes pulse {
    0%   { opacity: 0; transform: scale(0.2) }
    50% { opacity: 1; transform: scale(1.5) }
    100% { opacity: 0; transform: scale(0.2)}
  }
`

const InstaValuationModal = () => {
  console.log('Click on BTN for Modal friend');
  //window.open('https://valuations.ernest-brooks.com/', '_blank');
  if (typeof window !== 'undefined') {
    window.openInstantValuation && window.openInstantValuation();
  }
  
}

export class ValuationInPage extends PureComponent {
  constructor(props) {
    super(props);


    this.state = {
      form,
      activeStep,
      formState: {
        loading: false,
        error: false,
        success: false,
        sent: false,
      },
      handleChange: this.handleChange,
      handleStepClick: this.handleStepClick,
      handleSemanticChange: this.handleSemanticChange,
      gdprChecked: false,
      gdprWarning: false,
      warning : "",
      valuation: 0,
      valuationpermonth : 0,
      loadingapi: false


    }

    this.handleChange = ({ target: { name, value } }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };

    this.handleNumberChange = ({ target: { name, value } }) => {
      value = value.replace(/[^0-9\.]/g, '');

      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };

    this.handleGdprChange = () => {
      this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
    }

    this.handleStepClick = (stepIndex = 1) => {
      this.setState( { warning: '' } );
      const isvalid = this.validateStep(stepIndex);

      if (!isvalid){

        this.setState( { warning: 'All fields are required.' } );

        return false;
      }


      this.setState({ activeStep: stepIndex + 1 });
      return true;
    }

    this.handleSemanticChange = (_, { name, value, checked }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value || checked,
        },
      }));
    };


  }


  hasErrorField = (field) =>{
    const form  = this.state.form;

    if (form.hasOwnProperty(field) ){
      if (form[field] == '' ){
        return true;
      }
    }else{
      return true;
    }
    return false;
  }

  validateStep = (step) =>{

    console.log('validate');
   
    var errors =  {};

    if (step === 1){

      errors['zip'] = this.hasErrorField("zip");

    }

    if (step === 2){

      errors['first_name'] = this.hasErrorField("first_name");
      errors['last_name'] = this.hasErrorField("last_name");
      errors['email'] = this.hasErrorField("email");
      errors['mobile'] = this.hasErrorField("mobile");
      errors['address'] = this.hasErrorField("address");
      errors['haddress'] = this.hasErrorField("haddress");
     

      if (!this.state.gdprChecked){
        errors['gdprfriend'] = true;
        this.setState( { gdprWarning: true } );
      }
    }



  
    const values = Object.values(errors)
    for (const value of values) {
      if (value){
        return false;
      }
    }

    return true;

  };


  handleGdprChange = () => {
    this.setState( { gdprWarning: false } );
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }

  handleDateChange = (dateChoice) => {
    this.setState({ form: { ...this.state.form, dateChoice } });
  }

  myvalForm = async (event) => {

    if (this.handleStepClick(2) ) {


      const isHouse = (this.state.form.propertyType == "Houses")? 'true' : 'false';

      console.log(this.state.form.bedrooms);


      const self = this;

      try {

        this.setState({loadingapi : true });
         let response = await axios.get('https://apidev.ernest-brooks.com/api/valpal?postcode='+this.state.form.zip+'&paon='+this.state.form.haddress+'&address='+this.state.form.address+'&isHouse='+isHouse+'&recently_sold=true&council_tax=true&stamp_duty=true&epc_check=true&wheres_what=true&apiKey=5549948284b863aa90b21b1616eef909&emailaddress='+this.state.form.email+'&name='+this.state.form.first_name+'&lastName='+this.state.form.last_name+'&phone='+this.state.form.mobile)
        .then(function (response) {
           console.log(response.data);
           self.setState({loadingapi : false });

           if (! response.data.hasOwnProperty("error") ){

                self.setState({ valuation: response.data.valuation});
                self.setState({ valuationpermonth: response.data.rentalvaluation});

           }else{

              self.setState({ valuation: 'unique'});
           }
                    
        });

        this.handleStepClick(2);

       } catch (err){
            console.error(err);
      }
    }
  }    

  submitForm = async (event) => {
    const { form } = this.state;
    event.preventDefault();



    var data = new FormData();

    for ( var key in this.state.form ) {
        if (key == 'dateChoice'){
          var date = this.state.form[key];
          var month = date.getMonth() ;
          month = parseInt(month) + 1;
          data.append(key, ( date.getDate() + '/' +month) + '/' +  date.getFullYear());
        }else{
          data.append(key, this.state.form[key]);
        }
    }

    data.append('subject', 'Instant Valuation');
    data.append('valuation', this.state.valuation);
    data.append('valuationpermonth', this.state.valuationpermonth);

      try {
        this.setState({ formState: { ...this.state.formState, loading: true } });
        await request.post('forms', data);
        this.setState({ formState: { ...this.state.formState, loading: false, success: true, sent: true } });
      } catch (err){
        console.error('Sorry, form was not sent.');
        this.setState({ formState: { ...this.state.formState, loading: false, error: true, sent: true } });
      }
   
  }


  renderStep1 = (formData) => {
    const { form } = this.state;

    return (
      <React.Fragment>
        <InputGroup>
          <Input
            fluid
            name="zip"
            value={form.zip}
            onChange={this.handleChange}
            placeholder={formData.inputs.postcode}
          />
        </InputGroup>
        <PaddedRow className="nomargin">
          <Button  alt fluid onClick={() => this.handleStepClick(1)}>
            Continue
          </Button>
        </PaddedRow>

      </React.Fragment>
    );
  }


  submitStep2 = () => {
    const { gdprChecked } = this.state;

    if (!gdprChecked) {
      this.setState({ gdprWarning: true });
      return false;
    } else {
      this.setState({ gdprWarning: false });
    }

    this.myvalForm();

  }

  renderStep25 = (formData) => {
    const { form } = this.state;
    const { valuation } = this.state;
    const { valuationpermonth } = this.state;

    let unique =  false;

    if ( valuation == "unique" ) {
      unique = true;
    }
    let rent = true;

    if ( this.props.valuationType  == "sale-price"){
      rent = false;
    }

    console.log( this.state);

    return (
      <React.Fragment>
     
      {!unique ? (
      <Centertext>
      <PaddedRow>
        <Text className="white-text">
           Your Property is worth:
        </Text>
        </PaddedRow>

        <PaddedRow>
        <StyledTitle className="valPrice" epsilon color="main" weight="medium" block>
        {rent ? (
          <React.Fragment>
          {formatPrice(valuationpermonth)} per month
          </React.Fragment>
        ) : (
          <React.Fragment>
          {formatPrice(valuation)}
          </React.Fragment>
        )}

        </StyledTitle>  
        </PaddedRow>
       
        <PaddedRow>
        {rent ? (
        <StyledSubtitle alpha color="gray" block>
          Would you like to rent the property with us?
        </StyledSubtitle>
         ) : (
         <StyledSubtitle alpha color="gray" block>
          Would you like to sell the property with us?
        </StyledSubtitle>
         )}
        </PaddedRow>

        <PaddedRow>
          <Button alt fluid onClick={() => this.handleStepClick(3)}>
            Book A consultation
          </Button>
        </PaddedRow>
        </Centertext>
        ) : (
          <Centertext>
 

            <PaddedRow>
            <StyledTitle epsilon color="main" weight="medium" block>
            Your Property is unique
            </StyledTitle>  
            </PaddedRow>
    
            <PaddedRow>
              <Button alt fluid onClick={() => this.handleStepClick(3)}>
                Book A consultation
              </Button>
            </PaddedRow>
            </Centertext>

        )}

      </React.Fragment>
    );
  }


  renderStep2 = (formData) => {
    const { form, gdprChecked, gdprWarning } = this.state;

    return (


      <div>
      {this.state.loadingapi == true &&
      <Loading className="single-loading">
        <LoadingOverlay></LoadingOverlay>
      </Loading>
      }
        <InputGroup>
          <Input
            fluid
            placeholder={formData.inputs.firstName}
            name="first_name"
            onChange={this.handleChange}
            value={form.first_name}
            required
          />
          <Input
            fluid
            name="last_name"
            placeholder={formData.inputs.lastName}
            onChange={this.handleChange}
            value={form.last_name}
            required
          />
        </InputGroup>
        <InputGroup>
          <Input fluid placeholder="Email" name="email" onChange={this.handleChange}  value={form.email} required />
        </InputGroup>
        
        <InputGroup>

          <Input
            fluid
            name="mobile"
            placeholder={formData.inputs.mobile}
            onChange={this.handleNumberChange}
            value={form.mobile}
            required
          />


        </InputGroup>
        <InputGroup>
       <Input
            fluid
            placeholder={formData.inputs.address}
            placeholder="Street name"
            onChange={this.handleChange}
            value={form.address}
            name="address"
            required
          />
         </InputGroup> 

        <InputGroup>
          <Input
            fluid
            placeholder="House Number"
            onChange={this.handleChange}
            value={form.haddress}
            name="haddress"
            required
          />

          <Input
            fluid
            name="zip"
            value={form.zip}
            onChange={this.handleChange}
            placeholder={formData.inputs.postcode}
            required
          />
        </InputGroup>
        <InputGroup>
          <Dropdown
            placeholder={formData.inputs.propertyType}
            onChange={this.handleSemanticChange}
            name="propertyType"
            selection
            value={form.propertyType}
            options={formData.dropdowns.propertyType}
          />
          <Dropdown
            placeholder={formData.inputs.bedrooms}
            onChange={this.handleSemanticChange}
            name="bedrooms"
            selection
            value={form.bedrooms}
            options={formData.dropdowns.bedrooms}
          />
        </InputGroup>
        <CallToActionContainer>
          <Checkbox
            color="white"
            name="gdpr"
            verticalCenter
            label={formData.inputs.gdpr}
            required
            checked={gdprChecked}
            onChange={this.handleGdprChange}
            error={gdprWarning}
          />
          <Button alt block onClick={this.submitStep2}>
            {formData.inputs.submit}
          </Button>
        </CallToActionContainer>
      </div>
    );
  }
  renderStep3 = (formData) => {
    const { form, formState } = this.state;

    return (
      <React.Fragment>
        <SectionTitle color="main" weight="bold">
          {formData.datepicker.title}
        </SectionTitle>
        <DatePickerWrapper>
          <DatePicker
            onChange={this.handleDateChange}
            selected={form.dateChoice}
          />
        </DatePickerWrapper>
        <TimePickerWrapper>
          <TimePicker
            name="timeChoice"
            value="morning"
            title={formData.datepicker.timesOfDay.morning.title}
            subtitle={formData.datepicker.timesOfDay.morning.subtitle}
            checked={form.timeChoice === "morning"}
            onChange={this.handleChange}
          />
          <TimePicker
            name="timeChoice"
            value="afternoon"
            title={formData.datepicker.timesOfDay.afternoon.title}
            subtitle={formData.datepicker.timesOfDay.afternoon.subtitle}
            checked={form.timeChoice === "afternoon"}
            onChange={this.handleChange}
          />
          <TimePicker
            name="timeChoice"
            value="evening"
            title={formData.datepicker.timesOfDay.evening.title}
            subtitle={formData.datepicker.timesOfDay.evening.subtitle}
            checked={form.timeChoice === "evening"}
            onChange={this.handleChange}
          />
        </TimePickerWrapper>
        <SubmitButton alt block onClick={this.submitForm} disabled={formState.loading}>
          {formData.datepicker.submit}
        </SubmitButton>
      </React.Fragment>
    );
  }

  renderForm = (data) => {
    const formData = data.pages.forms.instantValuation;

    const { title, subtitle, pills } = formData;
    const { form, activeStep, formState, warning } = this.state;
    const isPillSale = ( this.props.valuationType === pills.salePrice.value );

    const steps = formData.steps.map(step => ({ label: step }));

    return (

      <InstantValuationContext.Provider value={this.state}>
        <StyledForm onSubmit={e => e.preventDefault()}>
          <StyledTitle epsilon color="main" weight="medium" block>
            {title}
          </StyledTitle>

        
        <PaddedRow className="nomargin">


          <Button onClick={InstaValuationModal} alt fluid  buttonStyle={buttonStyle}>
          Get your valuation
          </Button>



        </PaddedRow>

        </StyledForm>
      </InstantValuationContext.Provider>
    );
  }


 render() {
    return (
      <StaticQuery
        query={graphql`
          query InstantValuationInsssPage {
            pages {
              forms {
                instantValuation {
                  title
                  subtitle
                  pills {
                    salePrice {
                      label
                      value
                    }
                    rentalPrice {
                      label
                      value
                    }
                  }
                  steps
                  footnote
                  inputs {
                    firstName
                    lastName
                    phone
                    mobile
                    address
                    city
                    postcode
                    gdpr
                    submit
                    propertyType
                    bedrooms
                  }
                  dropdowns {
                    propertyType {
                      value
                      text
                    }
                    bedrooms {
                      value
                      text
                    }
                  }
                  datepicker {
                    title
                    timesOfDay {
                      morning {
                        title
                        subtitle
                      }
                      afternoon {
                        title
                        subtitle
                      }
                      evening {
                        title
                        subtitle
                      }
                    }
                    submit
                  }
                }
              }
            }
          }
        `}
        render={this.renderForm}
      />
    )
  }
}

