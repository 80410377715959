const createQuery = (query, direction = 'min') => `@media only screen and (${direction}-width: ${query}px)`;


// https://1.semantic-ui.com/collections/grid.html#page-grid-breakpoints
const breakpoint = {
  mobileSm: createQuery(320, 'max'),
  mobile: createQuery(767.9, 'max'),
  tablet: createQuery(768),
  computer: createQuery(992),
  smallNotebook: createQuery(1100),
  bigNotebook: createQuery(1400),
  computerMax: createQuery(1600, 'max'),
  labtopMax: createQuery(1300, 'max'),
  large: createQuery(1620),
  largeNo: createQuery(1690), 
  widescreen: createQuery(1920),
}


export default breakpoint;
