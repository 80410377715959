import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import { Herohalf, Grid, Column, Spacer, Media, Button } from 'components/ui';
import { ValuationInPage } from 'components/blocks';
import breakpoint from 'config/responsive';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import bp from 'config/responsive';
import helpImg from 'images/help.png';

const MobilePropertyBox = styled.div`
  position: relative;
  z-index: 1;
`;

const Content = styled.div` 
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${sc.screens.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;

  .headerDescription {
    display: inline-block;
    max-width: 620px;
  }
  .text-center {
    text-align: center;
  }
  .no-margin {
    margin-bottom: 0;
  }

  height: 100%;
  padding-top: 70px;
  min-height: 375px;




  ${breakpoint.large} {
    opacity: 1;
    
  }
  ${bp.computer} {
 

  }

  .grid {
    ${bp.computer} {
      opacity: 0;
    }
  }

`;

const Headline = styled.div`
  display: none;
  margin-bottom: 15px;

  ${bp.computer} {
    margin-bottom: 15px;
  }

  ${bp.large} {
    margin-bottom: 42px;
  }
`;



const StyledBackgroundSection = styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;
  background-color: #fff;


`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  &::after {
    display: none;
  }

  ${bp.computer} { 
    height: 100%;
  }
`;

const TextContainer = styled.div`
  
  margin:auto;
  width: 90%;


  ${bp.computer} {
    width: 100%;

  }

  &.center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    span {
      color: #979797 !important;
    }

  }

`;

const PropertySearchContainer = styled.div`

  overflow-y: auto;
  flex: 0 0 120px;
  margin: 0 0 0 auto;

  ${bp.computer} {
    flex: 0 0 380px;
  }

  ${bp.computer} {
    flex: 0 0 520px;
  }
`;

const HeroImage = styled.div`

  width: 100%;
  background-color: transparent;
  height: 30vh;
  margin-bottom: 1.5rem;
  background-image: url(${({ src }) => src}) ;
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;

  ${bp.tablet} {
    margin-bottom: 2rem;
    background-size: auto 100%;
  }
`;



const ButtonBlue = styled(Button)`
  margin-top:20px !important;
  margin-bottom:20px !important;
  background: #335eb3 !important; 
  color: #fff !important;

  &:hover {
    color: #fff !important;
    background-color: #294b8f !important; 
  }
`;

export class HelpHero extends PureComponent {
  renderComponent = data => {
    const { content } = this.props;

    console.log(content);

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <React.Fragment>
          <Background>
            <StyledBackgroundSection />
          </Background>
          <Content>
            <Grid>
            <Spacer />
              <Column verticalAlign width={10}>
                <TextContainer className="center">
                  <Headline>
                    <Script alpha color="white" align="left" className="title">
                     1
                    </Script>
                  </Headline>
                  <HeroImage src={content.img} />

                  <br/>
                 <Sans alpha color="white" className="headerDescription text-center">
                    {content.bodyText}
                  </Sans>
                  <br/>
                  <ButtonBlue href="https://www.ernest-brooks.com/search-results/?priceMin=100000&priceMax=1000000&rangeMin=100000&rangeMax=1000000&enquiryType=Buy&area=&propertyType=null&bedrooms=null&more=1-option,2-option&includeNewHomes=true&sortBy=price&sortOrder=DESC&stepSize=10000&page=1&htb=1">View all properties</ButtonBlue>
                </TextContainer>
              <Spacer />
            </Column>


            </Grid>
          </Content>
        </React.Fragment>
      )}
      renderIfFalse={() => (
        <React.Fragment>
          <Background>
            <StyledBackgroundSection />
          </Background>
          <Content>
            <Grid>
            
              <Column verticalAlign width={12}>
                <TextContainer className="center">
                  <Headline>
                    <Script alpha color="white" align="left" className="title">
                      1
                    </Script>
                  </Headline>
                  <HeroImage src={content.img} />
                  <br/>
                  <Sans alpha color="white" className="headerDescription text-center">
                    {content.bodyText}
                  </Sans>
                  <br/>
                  <ButtonBlue href="#all-properties">View all properties</ButtonBlue>
                </TextContainer>

              </Column>
              
            </Grid>
          </Content>
        </React.Fragment>
      )} />
    );
  };

  render() {
    return (
      <Herohalf text="scroll">
        <StaticQuery
          query={graphql`
            query {
              cover: file(relativePath: { eq: "stampduty.jpg" }) {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 4160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
          render={this.renderComponent}
        />
      </Herohalf> 
    );
  }
}

export default HelpHero;
