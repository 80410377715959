import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import sc from 'config/theme';
import { Sans, weightRatio } from 'Typography';
import bp from 'config/responsive';
import { Media } from 'components/ui';
import Slick from 'react-slick';

const defaultBubble = size => css`
  width: 220px !important;
  height: 220px;
  border-radius: 50%;
  outline: none;
  display: flex !important;
  margin: 15px;
  
  ${bp.tablet} {
    width: 400px !important;
    height: 400px;
  }
  ${bp.computer} {
    width: ${size}% !important;
    height: ${size}%;
    position: absolute;
    left: ${(100 - size) / 2}%;
    top: ${(100 - size) / 2}%;
    margin: auto;

  }
`;

const defaultContentBubble = ({ dark, size }) => css`
  ${defaultBubble(size)}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.22);
  border: 12px solid ${dark ? sc.colors.white : sc.colors.yellow};
  background-color: ${dark ? sc.colors.main : sc.colors.white};
  color: ${dark ? sc.colors.white : sc.colors.main};

  p {
    font-weight: ${weightRatio.bold};
    margin: 0;
    line-height: 1.1em;
    margin-top: -0.2em;
  }
  sup {
    ${'' /* font-weight: ${weightRatio.light};
    font-size: 0.75em;
    transform: scale(0.6);
    display: inline-block;
    margin-left: -5px;

    ${bp.computer} {
      margin-left: -15px;
    } */}
  }

`;

const Wrapper = styled.div`
  max-width: 1658px;
  margin: 0 auto 0 0;
  text-align: center;

  ${bp.computer} {
    /* transform: translateX(-21%); */
    transform: scale(0.75) translateX(-21%);
    margin-top: -10%;
    margin-bottom: -10%;
  }
  ${bp.large} {
    margin-top: -10%;
    margin-bottom: -5%; 
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;

  &.bubbles {
    
  }
`;

const BubbleInBgL = styled.div`
  ${defaultBubble(100)}
  border: 1px solid #979797;
  background-color: #e5e5e5;
  opacity: 0.23;
`;

const BubbleInBgM = styled.div`
  ${defaultBubble(80)}
  border: 1px solid #dfdfdf;
  background-color: #ffffff;
`;

const BubbleInBgS = styled.div`
  ${defaultBubble(56)}
  border: 1px solid #979797;
  background-color: #e3e3e3;
  opacity: 0.23;
`;

const ContentBubbleBranches = styled.div`
  ${defaultContentBubble({ dark: true, size: 16 })}
  left: 31.78% !important;
  top: 17.6% !important;

  p {
    font-size: 40px;

    ${bp.tablet} {
      font-size: 60px;
    }
    ${bp.computer} {
      font-size: 50px;
    }
    ${bp.large} {
      font-size: 81px;
    }
  }
`;

const ContentBubbleLanguages = styled.div`
  ${defaultContentBubble({ dark: true, size: 16 })}
  left: unset;
  right: -${56 / 1}% !important;
  top: 34.62% !important;

  p {
    font-size: 40px;

    ${bp.tablet} {
      font-size: 60px;
    }
    ${bp.computer} {
      font-size: 50px;
    }
    ${bp.large} {
      font-size: 108px;
    }
  }
`;

const ContentBubbleOffices = styled.div`
  ${defaultContentBubble({ dark: true, size: 16 })}
  left: unset !important;
  right: 33.14% !important;
  top: 79.52% !important;

  p {
    font-size: 40px;

    ${bp.tablet} {
      font-size: 60px;
    }
    ${bp.computer} {
      font-size: 50px;
    }
    ${bp.large} {
      font-size: 72px;
    }
  }
`;

const CenterBubble = styled.div`
  ${defaultContentBubble({ dark: false, size: 26.11 })}
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.14);
  color: #212121 !important;
  
  p {
    font-size: 40px;

    ${bp.tablet} {
      font-size: 60px;
    }
    ${bp.computer} {
      font-size: 70px;
    }
    ${bp.large} {
      font-size: 110px;
    }
  }
`;


const DynamicNumber = styled.div`
  text-align: center;
  position: relative;

  /* sup {
    position: absolute;
    top: -5px;
    right: -10px;

    ${bp.tablet} {
      top: -10px;
      right: -20px;
    }
  } */

  ${bp.computer} {
    text-align: center;
  }
`;

export class HomePulseContainer extends PureComponent {
  sliderOptions = {
    slidesToShow: 1,
    centerMode: true,
    slidesToScroll: 1,
    className: 'bubble-slider',
     variableWidth: true,
  }

  render() {
    const { content } = this.props;
    const { branches, languages, offices, homes } = content;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Wrapper>
          <Container className="bubbles">
            <BubbleInBgL className="bubbles-in" />
            <BubbleInBgM className="bubbles-in" />
            <BubbleInBgS className="bubbles-in" />
            <ContentBubbleBranches className="bubbleBranches">
              <p>{branches.number}</p>
              <Sans delta>{branches.text}</Sans>
            </ContentBubbleBranches>
            <ContentBubbleLanguages className="bubbleLanguages">
              <p>{languages.number}</p>
              <Sans delta>{languages.text}</Sans>
            </ContentBubbleLanguages>
            <ContentBubbleOffices className="bubbleOffices">
              <p>{offices.number}</p>
              <Sans delta>{offices.text}</Sans>
            </ContentBubbleOffices>
            <CenterBubble className="centerBubble">
              <DynamicNumber className="dynamic-first">
                <p>
                  <span id="counting">1000</span>
                  <sup>+</sup>
                </p>
                <Sans gamma>{homes.text}</Sans>
              </DynamicNumber>
            </CenterBubble>
          </Container>
        </Wrapper>
      )}
      renderIfFalse={() => (
        <Slick {...this.sliderOptions}>
        <CenterBubble className="centerBubble">
            <DynamicNumber className="dynamic-first">
              <p>
                <span id="counting">1000</span>
                <sup>+</sup>
              </p>
              <Sans gamma>{homes.text}</Sans>
            </DynamicNumber>
          </CenterBubble>
          <ContentBubbleBranches className="bubbleBranches">
            <p>{branches.number}</p>
            <Sans delta>{branches.text}</Sans>
          </ContentBubbleBranches>
          <ContentBubbleOffices className="bubbleOffices">
            <p>{offices.number}</p>
            <Sans delta>{offices.text}</Sans>
          </ContentBubbleOffices>
          <ContentBubbleLanguages className="bubbleLanguages">
            <p>{languages.number}</p>
            <Sans delta>{languages.text}</Sans>
          </ContentBubbleLanguages>
          
          
          
        </Slick>
      )} />
    );
  }
}

export default HomePulseContainer;
