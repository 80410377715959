import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import sc from 'config/theme';
import bp from 'config/responsive';

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  margin-right: 5px;
  margin-left: 5px;
  background: rgba(${sc.colors.white_rgb}, 0.15);
  border-radius: 50%;
  transition: background-color ease .25s;

  &:hover {
    background: rgba(${sc.colors.white_rgb}, 0.35);
  }
  ${bp.computer} {
    margin-right: 10px;
    margin-left: 10px;
  }

  ${bp.smallNotebook} {
    margin-right: 20px;
    margin-left: 0px;
  }
`;

const StyledIcon = styled(Icon)`
  &&&& {
    color: ${sc.colors.white};
    margin: 0;
    font-size: 18px;
  }
`;

export class SocialMediaIcon extends PureComponent {
  render() {
    const { href, title, target, ...otherProps } = this.props;

    return (
      <StyledLink href={href} title={title} target="_blank">
        <StyledIcon {...otherProps} />
      </StyledLink>
    );
  }
}

export default SocialMediaIcon;
