import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Sans } from 'Typography';
import sc from 'config/theme';
import bp from 'config/responsive';
import { Media} from 'components/ui';


const CardWrapper = styled.a`
  display: flex;
  background: ${sc.colors.white};
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  overflow: hidden;
  min-height: 135px;
  margin-bottom: 25px;
  flex-wrap: wrap;

  ${bp.computer} {
    flex-wrap: inherit;
  }
`;

const CardPicture = styled.div`
  display: block;
  flex: 1;
  background: url(${({ photo }) => photo}) center no-repeat;
  background-size: cover;
  margin-right: 0;
  min-height: 220px;
  width: 100%;

  ${bp.computer} {
    flex: 0 0 183px;
    margin-right: 30px;
    min-height: inherit;
    width: inherit;
  }
`;

const CardContent = styled.div`
  flex: 1 1 50000px;
  height: auto;
  padding: 17px 25px 26px 25px;

  ${bp.computer} {
    padding: 17px 60px 26px 0;
  }


  .title {
    line-height: 1.52;
    margin-bottom: 15px;

    ${bp.computer} {

    }
  }

`;




export class EventCard extends PureComponent {
  static propTypes = {
    photoUrl: PT.string.isRequired,
    linkHref: PT.string,
    title: PT.string.isRequired,
    description: PT.string.isRequired,
  }




  render() {

   let { icon, name, rating, type, vicinity} = this.props;



    let url = '';
    let linkHref  ="";
    let linkHref2  ="";

    if (type == "school"){
      url = 'https://apidev.ernest-brooks.com/school-icon.jpg';




      linkHref = "https://www.google.com/maps/search/schools,+colleges,+universites/@"+this.props.center.lat+","+this.props.center.lng +",15z"
      linkHref2 = "https://www.google.com/maps/search/schools,+colleges,+universites/@"+this.props.center.lat+","+this.props.center.lng +",15z"



      name =  "Nearest Schools";

      vicinity = "Click here to view the schools, colleges and universities nearest to this location";



    }else{



     linkHref = 'https://www.google.com/maps/place/?q=place_id:'+this.props.place_id
      linkHref2 = 'https://www.google.com/maps/search/?api=1&query='+this.props.geometry.location.lat()+','+this.props.geometry.location.lng()

      if (this.props.hasOwnProperty("photos")){
        url = this.props.photos[0].getUrl();
      }else{
          let img = Math.floor(Math.random() * 10) + 1;
        url = 'https://apidev.ernest-brooks.com/localhotspot/'+img+'.jpg';
      }


    }


    return (


  
          <Media
            breakpoint={bp.mobile}
            renderIfTrue={() => (

       <CardWrapper as={linkHref2 ? 'a' : 'div'} href={linkHref2} target="_blank">

          <CardPicture
            photo={url}
          />
          <CardContent>
            <Sans
              zeta
              color="main"
              lineHeight="2"
              className="title"
              weight="bold"
              block
            >
              {name}
               </Sans>
             <Sans delta color="gray" lineHeight="1.68" className="text" block>
                        {vicinity}
            </Sans>

          </CardContent>
        </CardWrapper>

      )}
      renderIfFalse={() => (

        <CardWrapper as={linkHref ? 'a' : 'div'} href={linkHref} target="_blank">

          <CardPicture
            photo={url}
          />
          <CardContent>
            <Sans
              zeta
              color="main"
              lineHeight="2"
              className="title"
              weight="bold"
              block
            >
              {name}
               </Sans>
             <Sans delta color="gray" lineHeight="1.68" className="text" block>
                        {vicinity}
            </Sans>

          </CardContent>
        </CardWrapper>




      )} />




    );
  }
}

export default EventCard;
