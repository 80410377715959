import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { Sans, Script } from 'Typography';
import bp from 'config/responsive';

const CardContainer = styled.div`
  max-width: 100%;

  min-height: inherit;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: white;
  padding: 30px;


  ${bp.bigNotebook} {
    max-width: 539px;
    padding: 64px 64px 64px; 
    min-height: 319px;
  }

`;

const CardTitle = styled(Script)`
  margin-bottom: 22px;
`;

export class Card extends PureComponent {
  static propTypes = {
    title: PT.string,
    content: PT.string,
  }

  render() {
    const { title, content, ...otherProps } = this.props;

    return (
      <CardContainer {...otherProps}>
        <CardTitle delta color="main" block>{title}</CardTitle>
        <Sans beta color="gray" block dangerouslySetInnerHTML={{__html: content}}></Sans>
      </CardContainer>
    );
  }
}

export default Card;
