import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  PillMenu,
  HighlightButton,
  SlideReveal,
  Media, 
} from 'components/ui';
import { Serif, Sans, Script } from 'Typography';
import sc from 'config/theme';

import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.whiteDim};
  padding-top: 40px;
  padding-bottom: 40px;

  .aboutOptions {
    ${bp.computer} {
      opacity: 0;
    }
  }


  ${bp.computer} {
    padding-top: 107px;
    padding-bottom: 138px;
  }

  & .table{
    display: table;
    width: 100%;
    max-width: 800px;
    margin: 40px 0;

    .table-row {
      display: table-row;
      
      &:last-child {
        .table-col {
          border-bottom: 1px solid #cecece;
        }
      }

      .table-col {
        display: table-cell;
        padding: 5px 10px;
        border-left: 1px solid #cecece;
        border-top: 1px solid #cecece;
        width: 33%;

        &:last-child {
          border-right: 1px solid #cecece;
        }
      }

      &.row-header {
        font-weight: bold;
        color: #0a1e3f;
        background: #ececec;
      }
    }
  }

  & h2 {
    color: #0B1D3F;
  }
`;

const StyledScript = styled(Script)`
  display: block;
  margin-bottom: 20px;
`;

const PillContainer = styled.div`
  background: ${sc.colors.white};
  padding: 18px 22px;
  border-radius: 5px;
  position: relative;
/* 
  &::after {
    content: '';
    width: 15px;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 9;
    position: absolute;
    pointer-events: none;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,.8) 100%);
  } */

  
  ${bp.computer} {

  }
  ${bp.large} {
  }
`;

const OptionsContainer = styled.div`
  .answerText {
    padding: 0 23px 23px;  
  }

`;

const Option = styled.div`
  padding: 0 22px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  * {
    pointer-events: none;
  }


`;

const OptionBody = styled.div`
  border-bottom: 1px solid rgba(${sc.colors.gray_rgb}, 0.2);
  user-select: none;
`;

const StyledSans = styled(Sans)`
  display: block;
  padding: 20px 0 25px;
`;

const Title = styled.div`
  margin: 0 auto 20px;

  ${bp.computer} {
    margin: 0 auto 50px;
  }

`;

export class AboutOptionsWithTitle extends PureComponent {
  static propTypes = {
    name: PT.string.isRequired,
    data: PT.arrayOf.isRequired,
  }

  uniqueId = Math.random().toString(32).substr(2);

  state = {
    activeCategory: this.props.data ? this.props.data[0].title : '',
    activeQuestion: null,
  };

  handleCategoryChange = (event) => {
    this.setState({ activeCategory: event.target.value, activeQuestion: null });
  };

  handleQuestionClick = (event) => {
    const targetIndex = event.target.dataset.index;
    const currentIndex = this.state.activeQuestion;
    const newIndex = targetIndex == currentIndex ? null : targetIndex;

    this.setState({ activeQuestion: newIndex });
  }

  render() {
    const { data, name } = this.props;
    const { activeCategory, activeQuestion } = this.state;
    const activeSectionData = data && data.length ? data.filter(({ title }) => title === activeCategory)[0].questions : [];

    return (

      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <InnerContainer className="aboutOptions">
            <Grid className="aboutOptionsInner">
              <Spacer />
              <Column width={10}>
                <Title>
                  <StyledScript color="main" beta block>
                    Careers positions available
                  </StyledScript>
                </Title>
                <PillContainer>
                  <PillMenu
                    justify="space-between"
                    dark
                    inputName={name || `${this.uniqueId}-name`}
                  >
                    {data.map(({ title }) => (
                      <PillMenu.Pill
                        value={title}
                        onChange={this.handleCategoryChange}
                        checked={activeCategory === title}
                      >
                        {title}
                      </PillMenu.Pill>
                    ))}
                  </PillMenu>
                </PillContainer>
                <OptionsContainer>
                  {activeSectionData.map(({ question, answer }, index) => (
                    <OptionBody key={index}>
                      <Option data-index={index} onClick={this.handleQuestionClick}>
                        <Serif color="main" beta>
                          {question}
                        </Serif>
                        <HighlightButton dark icon={index == activeQuestion ? "minus" : "plus"} />
                      </Option>
                      <SlideReveal isOpen={index == activeQuestion}>
                        <StyledSans color="gray" alpha>
                          <div dangerouslySetInnerHTML={{__html: answer}} />
                        </StyledSans>
                      </SlideReveal>
                    </OptionBody>
                  ))}
                </OptionsContainer>
              </Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container>
          <InnerContainer className="aboutOptions">
            <Grid className="aboutOptionsInner">
              <Column width={12}>
                <Title>
                  <StyledScript color="main" beta block>
                    Careers positions available
                  </StyledScript>
                </Title>
                <PillContainer>
                  <PillMenu
                    justify="space-between"
                    dark
                    inputName={name || `${this.uniqueId}-name`}
                  >
                    {data.map(({ title }) => (
                      <PillMenu.Pill
                        value={title}
                        onChange={this.handleCategoryChange}
                        checked={activeCategory === title}
                      >
                        {title}
                      </PillMenu.Pill>
                    ))}
                  </PillMenu>
                </PillContainer>
                <OptionsContainer>
                  {activeSectionData.map(({ question, answer }, index) => (
                    <OptionBody key={index}>
                      <Option data-index={index} onClick={this.handleQuestionClick}>
                        <Serif color="main" beta>
                          {question}
                        </Serif>
                        <HighlightButton dark icon={index == activeQuestion ? "minus" : "plus"} />
                      </Option>
                      <SlideReveal isOpen={index == activeQuestion}>
                        <StyledSans color="gray" alpha>
                          <div dangerouslySetInnerHTML={{__html: answer}} />
                        </StyledSans>
                      </SlideReveal>
                    </OptionBody>
                  ))}
                </OptionsContainer>
              </Column>
            </Grid>
          </InnerContainer>
        </Container>
      )} />

      
    );
  }
}

export default AboutOptionsWithTitle;
