/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { PureComponent } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import sc from '../config/theme';
import Navbar from './Navbar';
import Footer from './Footer';
import { createGlobalStyle } from 'styled-components';
import { FormContext, form } from 'context/propertySearch';
import bp from 'config/responsive';

const Content = styled.div`
  background: ${sc.colors.main};
  overflow: hidden;
  
  ${bp.large} {
    overflow: inherit;
  }
`;

const Noscript = styled.noscript`
  background: ${sc.colors.main};
  overflow: hidden;
  
  ${bp.large} {
    overflow: inherit;
  }
`;

const Notsupported = styled.div`
  padding-top: 152px;
  color: black;
  width: 100%;
  text-align: center;
  background-color:#fff;

  h1{
     font-size: 20px;
  }

  p{
    font-size: 16px;
  }

`;


const StyledSelection = createGlobalStyle`
  ::-moz-selection {
    background: rgba(${sc.colors.yellow_rgb}, 0.5);
    color: ${sc.colors.main};
    text-shadow: none;
  }
  ::selection {
    background: rgba(${sc.colors.yellow_rgb}, 0.5);
    color: ${sc.colors.main};
    text-shadow: none;
  }
`;

const Main = styled.div``;

class Layout extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = ({ target: { name, value } }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };

    this.handleSearch = () => {

      let str = '';
      const { form } = this.state;

      Object.keys(form).forEach((key, index, arr) => {
        if (index >= arr.length - 1) {
          str += `${key}=${form[key]}`;
        } else {
          str += `${key}=${form[key]}&`;
        }
      });

      navigate(`search-results?${str}`);
    };

    this.handleSemanticChange = (_, { name, value, checked }) => {
      if (name == 'includeNewHomes'){
        if (checked){
          this.setState(({ form }) => ({
            form: {
              ...form,
              more: ['1-option'],
            },
          }));

        }else{

          this.setState(({ form }) => ({
            form: {
              ...form,
              more: [],
            },
          }));

        }
      }

      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value || checked,
        },
      }));
    };

    this.setArea= (area) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          area: area,
        },
      }));
    }

    this.toggleSort = () => {
      var neworder = (this.state.form.sortOrder === "DESC" ? "ASC" : "DESC");
      this.setState(({ form }) => ({
        form: {
          ...form,
          sortBy: 'Price',
          sortOrder: neworder,
        },
      }));
 
      let str = '';
      const { form } = this.state;
        Object.keys(form).forEach((key, index, arr) => {
          if (index >= arr.length - 1) {
            str += `${key}=${form[key]}`;
          } else {
            str += `${key}=${form[key]}&`;
          }
        });

      navigate(`search-results?${str}`);

    };

    this.handleRangeChange = ({ min, max }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          priceMin: min,
          priceMax: max,
        },
      }));
    };

    this.directStateMutate = (newForm) => {
      this.setState({
        form: {
          ...this.state.form,
          ...newForm,
        },
      });
    }

    let savedState = {};
    if (typeof window !== 'undefined') {
      window.directStateMutate = this.directStateMutate;
      savedState = window.savedState || {};
    }

    this.state = {
      form: {
        ...form,
        ...savedState,
      },
      directStateMutate: this.directStateMutate,
      handleChange: this.handleChange,
      handleSearch: this.handleSearch,
      toggleSort: this.toggleSort,
      setArea: this.setArea,
      setMore: this.setMore,

      handleSemanticChange: this.handleSemanticChange,
      handleRangeChange: this.handleRangeChange,
      browsersuported : true,
    };
  
}

  get_browser() {
    var ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
      tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
      return {name:'IE',version:(tem[1]||'')};
      }   
    if(M[1]==='Chrome'){
      tem=ua.match(/\bOPR|Edge\/(\d+)/)
      if(tem!=null)   {return {name:'Opera', version:tem[1]};}
      }   
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
  }




  componentDidMount() {

    var browser=this.get_browser()

     if (browser.name == 'ie'){
       this.setState({ browsersuported : false });

     // navigate(`browser-not-supported`);

    }else{
        const { onVisible } = this.props;
        onVisible && onVisible();

    }

  }

  renderContent = data => {
    const { children, noFooter } = this.props;
    const { browsersuported }= this.state;

    const gtg = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KDZNZVH" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

    return (
      <React.Fragment>

      {browsersuported ? (
         <React.Fragment>
      <noscript dangerouslySetInnerHTML={{__html: gtg}} /> 
      <Content>
        <StyledSelection />
        <FormContext.Provider value={this.state}>
          <Navbar siteTitle={data.site.siteMetadata.title} />
          <Main>{children}</Main>
        </FormContext.Provider>
        {noFooter ? null : <Footer />}
      </Content>
      </React.Fragment>
        ) : (

         <Content>
        <StyledSelection />
        <FormContext.Provider value={this.state}>
          <Navbar siteTitle={data.site.siteMetadata.title} />
          <Notsupported>
            <h1>Browser not supported.</h1>
            <p>We support <a href="https://www.google.com/chrome/">Chrome</a>, <a href="http://www.mozilla.org/firefox/">Firefox</a>, <a href="http://www.apple.com/safari/">Safari</a>, and <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Microsoft Edge</a>.</p>
          </Notsupported>
        </FormContext.Provider>
       
        </Content>
        

        )}

        <script type="text/javascript" src="https://api.visitor.chat/js/vc.min.js" id="vcLoaderScript"></script>

        </React.Fragment>

    );
  };

  render() {
    if (typeof window !== 'undefined') {
      window.savedState = this.state.form;
    }

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={this.renderContent}
      />
    );
  }
}

export default Layout;
