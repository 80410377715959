import React, { PureComponent } from 'react';
import PT from 'prop-types';
import Slick from 'react-slick';
import styled, { css } from 'styled-components';
import { SliderArrow } from 'components/ui';
import { SliderInnerCard } from 'components/blocks';
import bp from 'config/responsive';


const StyledSlick = styled(Slick)`
  width: calc(100% + 30px);
  margin-left: -15px;

  * {
    outline: none;
  }
  &.slider-outer {
    height: 100%;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      opacity: 0.2;
      transition: ease 0.45s;

      &.slick-active {
        opacity: 1;
      }
    }
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 5px;

  ${bp.computer} {
    padding: 0 15px;
  }
`;

const SlideInner = styled.div`
  padding-top: 70% !important;
  position: relative;
  border-radius: 11px;
  overflow: hidden;
`;

const StyledSliderArrow = styled(SliderArrow)`
  
  ${bp.computer} {
    transform: none;
    left: unset;
    top: unset;
    right: 14px;
    bottom: 100%;
    margin-top: unset;
    margin-bottom: 50px;

    ${({ next }) =>
      !next &&
      css`
        right: ${14 + 26 + 60}px;
    `}
  }

`;

export class SimilarPropertiesSlider extends PureComponent {
  static propTypes = {
    data: PT.arrayOf(PT.object).isRequired,
  }

  state = {
    currentIndex: 0,
  };

  outerSliderSettings = {
    className: 'slider-outer',
    arrows: true,
    dots: false,
    initialSlide: 0,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <StyledSliderArrow next />,
    prevArrow: <StyledSliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  afterChange = index => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { currentIndex } = this.state;
    const { data } = this.props;
    const { htb } = this.props;

    if (data.length < 2 ){
      this.outerSliderSettings.infinite = false;
    }

    return (
      <StyledSlick {...this.outerSliderSettings} afterChange={this.afterChange}>
        {data.map((item, index) => (
          <Slide key={item.src}>
            <SlideInner>
              <SliderInnerCard
                cover={item}
                active={index === currentIndex}
                index={index}
                htb = {htb}
              />
            </SlideInner>
          </Slide>
        ))}
      </StyledSlick>
    );
  }
}

export default SimilarPropertiesSlider;
