import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PT from 'prop-types';
import { InnerContainer, Grid, Spacer, Column, Button, Media } from 'components/ui';
import { Script, Serif } from 'Typography';
import sc from 'config/theme';
import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.main};
  padding: 40px 0;

  .singleImageSection {
    ${bp.computer} {
      opacity: 0;
    }
  }
  .imageContent {
    order: 2;
  }
  .textContent {
    padding-left: 25px !important;
    padding-right: 25px !important;
    order: 1;
    margin-bottom: 60px;
    
    ${bp.tablet} {
      padding-left: 25px !important;
      padding-right: 25px !important;
      margin-bottom: 100px;
    }
  }

  .parallax-image-6  {
    min-height: 395px !important;
  }

  ${bp.computer} {
    padding: 160px 0 ;
    min-height: 1120px;
    z-index: 1;
    position: relative;

    .parallax-image-4  {
      img {
        width: auto !important;
      }
    }
  }

  ${bp.computerMax} {
    .parallax-image-6 {
      width: 336px!important;
      height: 234px!important;

    }
    .parallax-image-5  {
      width: 236px !important;
      height: 296px !important;
      left: 285px;
    }
    .parallax-image-4 {
      width: 306px !important;
      height: 419px !important; 
    }
  }

  ${bp.labtopMax} {
    .parallax-image-6 {
      width: 286px!important;
      height: 184px!important;
      top: 100px;
    }
    .parallax-image-5  {
      width: 176px !important;
      height: 186px !important;
      left: 295px;
    }
    .parallax-image-4 {
      width: 276px !important;
      height: 389px !important;

      
    }
  }

  .mobileSingle {
    .parallax-image-6 {
      display: none!important;
      
      
    }
    .parallax-image-5  {

      width: 70vw !important;
      height: 60vw !important;
      left: inherit !important;
      right: -19vw !important;
      position: relative !important;
      transform: none !important;
      top: 0;
      box-shadow: none;
      
      ${bp.tablet} {
        width: 90vw !important;
        height: 40vw !important;
        left: inherit !important;
        right: -5vw !important;
        position: relative !important;
        transform: none !important;
        top: 0;
        box-shadow: none;
      }
    }
    .parallax-image-4 {
      width: 85vw !important;
      height: 90vh !important;
      left: 15px !important;
      right: 0 !important;
      position: relative !important;
      transform: none !important;
      top: 0;
      margin-top: -70px;
      box-shadow: none;
      
      ${bp.tablet} {
        width: 85vw !important;
        height: 120vh !important;
        left: 15px !important;
        right: 0 !important;
        position: relative !important;
        transform: none !important;
        top: 0 ; 
        margin-top: -100px;
        box-shadow: none;
      }
    }
  }

  .customFontSize, .customFontSize * {
    font-size: 18px;
    font-family :'acumin-pro', sans-serif;

    ${bp.computer} {
      font-size: 28px;
    }
  }

  
`;

const StyledScript = styled(Script)`
  margin-bottom: 28px;
`;

const Paragraph = styled.p`
  display: block;
  margin-bottom: 30px !important;
  line-height: inherit;

  ${bp.computer} {
    margin-bottom: 60px !important; 
  }
`;

const ImgBack = styled(Img)`
  position: absolute !important;
  z-index: 1;
  left: 152px;
  top: 0;
  border-radius: 10px;
`;

const ImgMiddle = styled(Img)`
  position: absolute !important;
  z-index: 2;
  left: 375px;
  top: 410px;
  border-radius: 10px;
  box-shadow: 10px 11px 21px rgba(0,0,0,0.265);
  background: ${sc.colors.main};

  > * {
    opacity: 0.35;
  }
`;

const ImgFront = styled(Img)`
  position: absolute !important;
  left: 30%;
    top: 30%;
  z-index: 3;
  border-radius: 10px;
  /* box-shadow: 10px 11px 21px rgba(0,0,0,0.265); */
`;




export class SingleImageSection extends PureComponent {

  handleButtonClick = () => {
    console.log('clickedthe button')
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  static propTypes = {
    title: PT.string,
    text: PT.string,
    buttonText: PT.string,
    buttonAction: PT.func,
  };

  static defaultProps = {
    title: '',
    text: '',
    buttonText: '',
  };

  renderContent = () => {
    const { content } = this.props;

    return (
      <div>
        <StyledScript beta color="yellow" block>
          {content.title}
        </StyledScript>
        <div className="customFontSize">
          <Serif beta color="white" block lineHeight="1.65">
            {content.paragraph1 && <Paragraph>{content.paragraph1}</Paragraph>}
            {content.paragraph2 && <Paragraph>{content.paragraph2}</Paragraph>}
            {content.paragraph3 && <Paragraph>{content.paragraph3}</Paragraph>}
          </Serif>
        </div>
        <Button onClick={this.handleButtonClick}>{content.callToAction}</Button>
      </div>
    );
  };

  render() {
    const { imageBack, imageMiddle, imageFront, children, imageBackalt, imageMiddlealt ,imageFrontalt } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <InnerContainer className="singleImageSection">
            <Grid  className="singleImageSectionInner">
              <Column width={5}>
                {/* <ImgBack fixed={imageBack} className="parallax-image-6" alt={imageBackalt}/>
                <ImgMiddle fixed={imageMiddle} className="parallax-image-5" alt={imageMiddlealt} /> */}
                <ImgFront fixed={imageFront} className="parallax-image-4" alt={imageFrontalt} />
              </Column>
              <Spacer />
              <Column width={5}>{children || this.renderContent()}</Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container>
          <InnerContainer className="singleImageSection">
            <Grid  className="singleImageSectionInner mobileSingle">
              <Column width={12} className="imageContent">
                {/* <ImgBack fixed={imageBack} className="parallax-image-6" />
                <ImgMiddle fixed={imageMiddle} className="parallax-image-5" /> */}
                <ImgFront fixed={imageFront} className="parallax-image-4" />
              </Column>
              <Column width={12} className="textContent">{children || this.renderContent()}</Column>
            </Grid>
          </InnerContainer>
        </Container>
      )} />
    );
  }
}
