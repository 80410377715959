import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slick from 'react-slick';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  SliderArrow,
  HighlightButton,
  Media,
} from 'components/ui';
import { TeamMember } from 'components/blocks';
import { Script, Sans, Serif } from 'Typography';
import sc from 'config/theme';
import request from 'utils/request';
import bp from 'config/responsive';


const Container = styled.div`
  background: ${sc.colors.main};
  padding-top: 40px;
  padding-bottom: 40px;

  .aboutSlider {
    ${bp.computer} {
      opacity: 0;
    }

  }

  ${bp.computer} {
    padding-top: 149px;
    padding-bottom: 202px;
  }

  .aboutSliderText {
    padding-left: 25px !important;
    padding-right: 25px !important;

    ${bp.computer} {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  .customFontSize, .customFontSize * {
    font-size: 18px;
    font-family :'acumin-pro', sans-serif;

    ${bp.computer} {
      font-size: 28px;
    }
  }
`;

const StyledSlick = styled(Slick)`
  margin-top: 103px;
  * {
    outline: none;
  }
  &.slider-outer {
    .slick-list {
      overflow: visible;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-arrow {
    top: -100px;
    margin-top: 0;
    left: unset;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    right: 90px;

    ${bp.computer} {
     right: 101px;
    }

  }
`;

const Avatar = styled.div`
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  backface-visibility: hidden;
  border-radius: 10px;
`;

const Text = styled.div`
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  background: ${sc.colors.white};
  backface-visibility: hidden;
  transform: rotateY(180deg);
  padding: 40px 33px 40px;
  border-radius: 10px;
  overflow: hidden;

  ${bp.bigNotebook} {
    padding: 45px 33px 28px;
  }

`;

const SlideInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

const Slide = styled.div`
  position: relative;
  margin: 0 15px;
  width: 315px !important;
  height: 490px;

  /* border-radius: 10px;
  overflow: hidden; */
  perspective: 1000px;
  &:hover {
    ${SlideInner} {
      transform: rotateY(180deg);
    }
  }
  ${bp.computer} {
    width: 350px !important;
    height: 490px;
  }

  ${bp.bigNotebook} {
    width: 370px !important;
    height: 530px;
  }

`;

const StyledScript = styled(Script)`
  margin-bottom: 32px;
`;

const StyledSerif = styled(Serif)`
  display: block;
  font-size: 20px;

  ${bp.computer} {
    font-size: 30px;
  }
  ${bp.large} {
    /* font-size: 40px; */
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 60px;

  ${Script}, ${Sans} {
    display: block;
  }
  ${Script} {
    margin-bottom: 13px;
  }

  & .smallerFont {
    ${bp.bigNotebook} {
      font-size: 40px !important;
    }
  }
`;

const ContactDetails = styled.div`
  margin-bottom: 23px;
  ${Sans} {
    display: block;
    &:first-of-type {
      margin-bottom: 4px;
    }
  }

  a {
    color: rgba(0,0,0,.87);
    text-decoration: none;

    &, & * {
      ${bp.bigNotebook} {
        font-size: 18px; 
      }
    }
  }
`;

const StyledHighlightButton = styled(HighlightButton)`
  &&& {
    position: absolute;
    bottom: 0;
    right: 28px;
    bottom: 28px;
  }
`;

export class AboutTeam extends PureComponent {
  state = {
    selectedMember: {},
    data: [],
  };

  sliderSettings = {
    className: 'slider-outer',
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 1,
    variableWidth: true,
    variableHeight: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',

    responsive: [
      
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]

  };

  handleClick = member => this.setState({ selectedMember: member });

  getData = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('aboutTeamMembersv2');

      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    let { selectedMember, data } = this.state;

    if (! Array.isArray(data)){
        data = Object.keys(data).map(function(key) {
        return { 
        avatar:data[key].avatar, 
        email:data[key].email, 
        linkedin:data[key].linkedin, 
        main:data[key].main, 
        name:data[key].name, 
        phone:data[key].phone, 
        position:data[key].position}; 

      });
    }
    const { content } = this.props;
    const { team } = content;
    return (

      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <TeamMember member={selectedMember} handleClick={this.handleClick} />

          <InnerContainer className="aboutSlider">
            <Grid>
              <Spacer />
              <Column width={8}>
                <StyledScript color="yellow" beta>
                  {team.title}
                </StyledScript>

                <StyledSerif color="white">
                  <div className="customFontSize">
                    {team.description}
                  </div>
                </StyledSerif>
              </Column>
              <Spacer width={3} />
            </Grid>
            <Grid>
              <Spacer />
              <Column width={10}>
                <StyledSlick {...this.sliderSettings}>
                  {data.map(el => (
                    <Slide>
                      <SlideInner>
                        <Avatar src={el.avatar} />
                        <Text>
                          <TitleContainer>
                            <Script className="smallerFont" color="main" beta>
                              {el.name}
                            </Script>
                            <Sans color="main" beta>
                              {el.position}
                            </Sans>
                          </TitleContainer>
                          <ContactDetails>
                            <Sans zeta>Phone</Sans>
                            <a href={`tel:${el.phone}`}>
                              <Sans eta weight="bold">
                                {el.phone}
                              </Sans>
                            </a>
                          </ContactDetails>
                          <ContactDetails>
                            <Sans zeta>Email</Sans>
                            <a href={`mailto:${el.email}`}>
                              <Sans eta weight="bold">
                                {el.email}
                              </Sans>
                            </a>
                          </ContactDetails>

                          <StyledHighlightButton
                            onClick={() => this.handleClick(el)}
                            dark
                            icon="plus"
                          />
                        </Text>
                      </SlideInner>
                    </Slide>
                  ))}
                </StyledSlick>
              </Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => ( 
        <Container>
          <TeamMember member={selectedMember} handleClick={this.handleClick} />

          <InnerContainer className="aboutSlider">
            <Grid>
              <Column width={12} className="aboutSliderText">
                <StyledScript color="yellow" beta>
                  {team.title}
                </StyledScript>

                <StyledSerif color="white">
                  <div className="customFontSize">
                    {team.description}
                  </div>
                </StyledSerif>
              </Column>
              <Spacer width={12} />
            </Grid>
            <Grid>
              <Column width={12}>
                <StyledSlick {...this.sliderSettings}>
                  {data.map(el => (
                    <Slide>
                      <SlideInner>
                        <Avatar src={el.avatar} />
                        <Text>
                          <TitleContainer>
                            <Script color="main" beta>
                              {el.name}
                            </Script>
                            <Sans color="main" beta>
                              {el.position}
                            </Sans>
                          </TitleContainer>
                          <ContactDetails>
                            <Sans zeta>Phone</Sans>

                            <a href={`tel:${el.phone}`}>
                            <Sans eta weight="bold">
                              {el.phone}
                            </Sans>
                            </a>
                            
                          </ContactDetails>
                          <ContactDetails>
                            <Sans zeta>Email</Sans>
                            <a href={`mailto:${el.email}`}>
                            <Sans eta weight="bold">
                              {el.email}
                            </Sans>
                           </a> 
                          </ContactDetails>

                          <StyledHighlightButton
                            onClick={() => this.handleClick(el)}
                            dark
                            icon="plus"
                          />
                        </Text>
                      </SlideInner>
                    </Slide>
                  ))}
                </StyledSlick>
              </Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )} />
    );
  }
}

export default AboutTeam;
