import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Sans } from 'Typography';
import { StaticQuery, graphql } from 'gatsby';
import request from 'utils/request';
import { InputGroup, Input, Checkbox, Button } from 'components/ui';
import formatPrice from 'utils/formatPrice';
import sc from 'config/theme';
import { fonts } from 'Typography';

const StyledTitle = styled(Sans)`
  margin-bottom: 20px;
`;

const StyledFormTitle = styled(Sans)`
  margin-bottom: 20px;
  margin-top: 50px;
`;

const StyledSubtitle = styled(Sans)`
  margin-bottom: 50px;
`;

const StyledForm = styled.form`
  margin-top: 65px;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1 0 auto;
  margin-top: 40px;
`;

const CheckboxRow = styled.div`
  margin-top: 25px;
`;

const StyledTable = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;

  thead {
    background: ${sc.colors.main};
    color: ${sc.colors.white};
    font-family: ${fonts.sans};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.86px;
    line-height: 28px;
  }

  th {
    padding: 0 14px;
    text-align: left;
  }

  tbody tr {
    background-color: #ffffff;

    td {
      border: 1px solid #d4d4d4;
      padding: 0 14px;
      opacity: 0.52;
      color: ${sc.colors.muted};
      font-family: ${fonts.sans};
      font-size: 16px;
      font-weight: 400;
      line-height: 42px;
    }

    &:nth-child(even) {
      background-color: #fafafa;
    }
  }
`;

export class StampDutyCalculator extends PureComponent {
  state = {
    propertySetPrice: '',
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
    },
    form: {
      propertyPrice: '',
      
      firstTimeBuyer: false,
      secondaryHome: false,
    },
    calculations : [

    ],
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  }

  handleCheckbox = (name) => {

    if (name == "secondaryHome"){


      if (this.state.form.firstTimeBuyer){

       this.setState({
        form: {
          ...this.state.form,
          [name]: !this.state.form[name],
          firstTimeBuyer: false,
        },
       });

      }else{

        this.setState({
          form: {
            ...this.state.form,
            [name]: !this.state.form[name],
          },
        })

      }

    }else{

      if (this.state.form.secondaryHome){

        this.setState({
        form: {
          ...this.state.form,
          [name]: !this.state.form[name],
          secondaryHome: false,
        },
       });

      }else{

        this.setState({
          form: {
            ...this.state.form,
            [name]: !this.state.form[name],
          },
        });

      }

    }

  }

  handleInputChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;

      if (! isNaN( value )){
     
        this.setState(({ form }) => ({
          form: {
            ...form,
            [name]: value,
          },
        }));

      } 

  }

  submitForm = async (event) => {
    event.preventDefault();

     var basePrice = parseFloat( this.state.form.propertyPrice );

  


   

    var firstTime =  this.state.form.firstTimeBuyer; 
    var secondHome =  this.state.form.secondaryHome; 

    var response = [];

    var perRate = 0;
      
    var due = 0;

    var total = 0;

    var nextTier =  true;


    if (firstTime && basePrice <= 500000){

      var newPrice = basePrice - 300000;

    
      perRate = 0;
      due = 0;
    
      total += due;

      due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

      response.push( { band: 'Up to £300,000', rate: perRate+'%', due: '£'+due } );


      if (newPrice > 0 && nextTier){
         perRate = 5;

        if (newPrice <= 200000 ){
          due = newPrice*(perRate/100);
        }else{
          due = 200000*(perRate/100);
        }

        total += due;

        due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

        response.push( { band: '£300,000 - £500,000', rate: perRate+'%', due: '£'+due } );
      }else{

        nextTier = false;
      }




    }else{


   

      var newPrice = basePrice - 125000;

      if (secondHome){
        perRate = 3;

        if (newPrice > 0){
           due = 125000*(perRate/100);
        }else{
           due = basePrice*(perRate/100);
        }


      }else{
        perRate = 0;
        due = 0;
      }
      
      total += due;

      due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

      response.push( { band: 'Up to £125,000', rate: perRate+'%', due: '£'+due } );


      if (newPrice > 0 && nextTier){
        if (secondHome){
          perRate = 5;
        }else{
          perRate = 2;
        }

        if (newPrice <= 125000 ){
          due = newPrice*(perRate/100);
        }else{
          due = 125000*(perRate/100);
        }

        total += due;

        due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

        response.push( { band: '£125,000 - £250,000', rate: perRate+'%', due: '£'+due } );
      }else{

        nextTier = false;
      }




      newPrice -= 125000;
     

      if (newPrice > 0 && nextTier){

        if (secondHome){
          perRate = 8;
        }else{
          perRate = 5;
        }

        if (newPrice <= 675000 ){
          due = newPrice*(perRate/100);
        }else{
          due = 675000*(perRate/100);
        }

        total += due;

        due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        
        response.push( { band: '£250,000 - £925,000', rate: perRate+'%', due: '£'+due } );

      }else{

        nextTier = false;
      }



       newPrice -= 675000;


      if (newPrice > 0 && nextTier){

        if (secondHome){
          perRate = 13;
        }else{
          perRate = 10;
        }

        if (newPrice <= 575000 ){
          due = newPrice*(perRate/100);
        }else{
          due = 575000*(perRate/100);
        }

        total += due;

        due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        
        response.push( { band: '£925,000 - £1,500,000', rate: perRate+'%', due: '£'+due } );

      }else{

        nextTier = false;

      }

      newPrice -= 575000;

      if (newPrice>0 && nextTier){
        
        if (secondHome){
          perRate = 15;
        }else{
          perRate = 12;
        }
        due = newPrice*(perRate/100);

        total += due;



        due = due.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

        response.push( { band: '£1,500,000+', rate: perRate+'%', due: '£'+due} );


      
      }


    }

    var totalPercentage = (total*100)/basePrice;

    this.setState({ propertySetPrice: total});
    total = total.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    response.push ( { band: 'Total', rate: totalPercentage.toFixed(2)+'%', due: '£'+total } );




    this.setState({ formState: {loading: false, success: true, sent: true }})
    this.setState({calculations: response});

  }


  renderTable = ({ headers, data }) => {
    return (
      <StyledTable>
        <thead>
          <tr>
            {headers.map((title) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            const { band, rate, due, tax } = row;

            return (
              <tr>
                <td>{band}</td>
                <td>{rate}</td>
                <td>{due || tax}</td>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    )
  }



  renderForm = (data, secondStep) => {
    const { inputs } = data;
    const { firstTimeBuyer, secondaryHome , propertyPrice} = this.state.form;
    const { loading } = this.state.formState;


    return (
      <StyledForm onSubmit={this.submitForm}>
        <InputGroup>
          <Input placeholder={inputs.propertyPrice} 
          name="propertyPrice"
          onChange={this.handleInputChange}
          value = {propertyPrice}
          required />
        </InputGroup>
        <CheckboxRow>
          <Checkbox
            color="main"
            name="firstTimeBuyer"
            required
            label={inputs.firstTimeBuyer}
            checked={firstTimeBuyer}
            onChange={() => this.handleCheckbox('firstTimeBuyer')}
          />
        </CheckboxRow>
        <CheckboxRow>
          <Checkbox
            color="main"
            name="secondaryHome"
            required
            label={inputs.secondaryHome}
            checked={secondaryHome}
            onChange={() => this.handleCheckbox('secondaryHome')}
          />
        </CheckboxRow>
        <FormFooter>
          <Button alt onClick={() => false} disabled={loading}>
            {secondStep ? inputs.btnRecalculate : inputs.btnCalculate}
          </Button>
        </FormFooter>
      </StyledForm>
    );
  }

  renderStep1 = (data) => {
    const { title, subtitle } = data;

    return (
      <React.Fragment>
        {/* <StyledTitle epsilon color="main" weight="medium" block>
          {title}
        </StyledTitle>
        <StyledSubtitle alpha color="gray" block>
          {subtitle}
        </StyledSubtitle> */}
        {this.renderForm(data, false)}
      </React.Fragment>
    );
  }

  renderStep2 = (data) => {
    const { resultsPage, tables } = data;

    const {calculations} = this.state;

    const mockData2 = [
      { band: '£0 - £125,000', rate: '0%', tax: '£0' },
      { band: '£125,001 - £250,000', rate: '2%', tax: '£2,500' },
    ];

    const price = this.state.propertySetPrice;

    return (
      <React.Fragment>
        <StyledTitle epsilon color="main" weight="medium" block>
          {resultsPage.title.replace('%s', formatPrice(price || 0))}
        </StyledTitle>
        <StyledSubtitle alpha color="gray" block>
          {resultsPage.subtitle}
        </StyledSubtitle>
        {calculations && this.renderTable({
          headers: [tables.band, tables.rate, tables.due],
          data: calculations,
        })}
        <StyledFormTitle epsilon color="main" weight="medium" block>
          {resultsPage.calculateAgain}

        </StyledFormTitle>

        {this.renderForm(data, true)}
       
   
      </React.Fragment>
    )
  }

  renderContent = (queryData) => {

    const data = queryData.pages.forms.stampDutyCalculator;
    const { sent, success, error, loading } = this.state.formState;

    return (
      <React.Fragment>
        {!sent && this.renderStep1(data)}
        {sent && loading && <h2 style={{textAlign: 'center', margin: '3rem 0'}}>Loading ...</h2>}
        {sent && !loading && !error && success && this.renderStep2(data)}
        {sent && error && <h2>Something failed...</h2>}
      </React.Fragment>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query StampDutyCalculator {
            pages {
              forms {
                stampDutyCalculator {
                  title
                  subtitle
                  inputs {
                    propertyPrice
                    firstTimeBuyer
                    secondaryHome
                    btnCalculate
                    btnRecalculate
                  }
                  resultsPage {
                    title
                    subtitle
                    calculateAgain
                    howItsCalculated
                  }
                  tables {
                    band
                    rate
                    due
                    tax
                  }
                }
              }
            }
          }
        `}
        render={this.renderContent}
      />
    )
  }
}

export default StampDutyCalculator;