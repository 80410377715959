import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Button as SemanticButton } from 'semantic-ui-react';
import sc from 'config/theme';
import bp from 'config/responsive';
import luminate from 'utils/luminate';

export const Button = styled(SemanticButton)`

  &&& {
    height: ${sc.sizes.height}px;
    border-radius: ${sc.sizes.borderRadius}px;
    background: ${sc.colors.white};
    color: ${sc.colors.main};
    padding: 0px 20px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    margin: unset;
    transition: background-color ease 0.25s;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;

    &:hover {
      background-color: ${luminate(sc.colors.white, -0.25)};
    }
    ${bp.computer} {
      padding: 0px 33px;
    }
    ${bp.large} {
      font-size: 16px;
    }

    ${({ fluid }) =>
      fluid &&
      css`
        width: 100%;
      `}

    ${({ transparent }) =>
      transparent &&
      css`
        background: transparent !important;
        color: ${sc.colors.white};
        border-color: transparent;
        box-shadow: none;
      `}
    ${({ main }) =>
      main &&
      css`
        background: ${sc.colors.main};
        color: ${sc.colors.white};

        &:hover {
          background-color: ${luminate(sc.colors.main, -0.2)};
        }
      `}

    ${({ alt }) =>
      alt &&
      css`
        background: #335eb3;
        color: ${sc.colors.white};

        &:hover {
          background-color: ${luminate('#335eb3', -0.2)};
        }
      `}

    ${({ borderwhite }) =>
      borderwhite &&
      css`
        background: ${sc.colors.white}; !important;
        color: ${sc.colors.main};
        border: 1px solid #cccccc;
        box-shadow: none;
      `}

  ${({ moreresults }) =>
        moreresults &&
      css`
          max-width: 300px;
          margin: 0 auto 0px;
          width: 300px;
          display: inherit;
 `}

    ${({ compact }) =>
      compact &&
      css`
        padding: 0 25px;
        font-size: 16px;
        height: 50px;
      `}

    ${({ buttonStyle }) => buttonStyle};
    ${bp.mobile} {
      /* height: ${sc.sizesMobile.height}px; */
      font-size: 16px;
    }

    &.rows {
        flex-direction: column;
    
      &> small {
          display: block;
          margin-bottom: 0.3rem;
          font-size: 65%;
          font-weight: 200;
          text-transform: none;
          margin-top: -0.3rem;
      }
    }

  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding: 10px 12px;
  border-radius: 5px;
  background: ${sc.colors.main};
  justify-content: space-between;
`;

export const PulsingButtonContainer = styled(Button)`
  &&& {
    background: transparent;
    text-align: left;
    width: ${({ fullWidth }) => fullWidth ? '100%' : '200px'};
    height: 60px;
    padding-left: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;

    color: ${sc.colors.main};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.08px;
    line-height: 14px;
    text-transform: uppercase;
    max-width: 250px;
    margin-top: 25px;

    &:hover {
      background: none;
    }
    span {
      z-index: 7;
      position: relative;
    }
  }
`;

const pulseRing = keyframes`
 0% {
    transform: scale(.33);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const pulseDot = keyframes`
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
`;

const PulseAnimation = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translateY(-50%);

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 50%;
    background-color: rgba(${sc.colors.main_rgb}, 0.12);
    border: 1px solid ${sc.colors.gray};
    animation: ${pulseRing} 2.5s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(${sc.colors.main_rgb}, 0.12);
    border-radius: 50%;
    box-shadow: 0 0 8px rgba(${sc.colors.main_rgb}, 0.12);
    animation: ${pulseDot} 2.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  }
`;

export const PulsingButton = ({ children, ...props }) => (
  <PulsingButtonContainer {...props}>
    <PulseAnimation />
    <span>{children}</span>
  </PulsingButtonContainer>
);
