import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { FormSuccess, Textarea, InputGroup, Input, Button, Checkbox } from 'components/ui';
import { Sans } from 'Typography';
import sc from 'config/theme';
import request from 'utils/request';
import $ from 'jquery';

const StyledTitle = styled(Sans)`
  margin-bottom: 20px;
`;

const StyledSubtitle = styled(Sans)`
  margin-bottom: 50px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }


`;

const InputGroupFile = styled(InputGroup)`
  &.file-input {
    position: relative;
    
    .form {
      width: 100%;

      .file-upload-wrapper {
          position: relative;
          width: 100%;
          height: 56px;

          &::after, &::before{
            font-size: 16px;
            font-weight: 400;
            font-family: 'acumin-pro',sans-serif;
          }

          &::after {
            content: attr(data-text);
            font-size: 16px;
            position: absolute;
            top: 0;
            left: 0;
            background: #fafafa;
            padding: 0px 25px;
            display: block;
            width: calc(100% - 40px);
            pointer-events: none;
            z-index: 20;
            height: 60px;
            line-height: 60px;
            color: #8391ac;
            border-radius: 5px 10px 10px 5px;
            border: 1px solid #e4e4e4;
          }

          &::before {
            content: 'Upload';
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            height: 60px;
            background: #8291ac;
            color: #fff;
            z-index: 25;
            line-height: 60px;
            padding: 0 15px;
            pointer-events: none;
            border-radius: 0 5px 5px 0;
            transition: .25s ease;
          }

          &:hover::before {
            background: #335eb3;
          }


          .file-upload-field, input {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 99;
            height: 40px;
            margin: 0;
            padding: 0;
            display: block;
            cursor: pointer;
            width: 100%;
          }
        }
    }
  }
`;


export class JoinOurTeam extends PureComponent {
    constructor(props)
    {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }


  state = {
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
    },
    form:{},
    file:null,
  }

  submitForm = async (event) => {
    event.preventDefault();


    console.log(this.state);


    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }
    if (this.state.file != null){
      data.append('attachment', this.state.file);
    }


    data.append('subject', 'Join Our Team');

    try {
      this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });
      await request.post('forms' , data);
      this.setState({ formState: { ...this.state.formState, loading: false, success: true } })
    } catch (err) {
      this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
    }
  }

  handleInputChange = (event) => {


      console.log(event.target);

      const name = event.target.name;
      const value = event.target.value;

  
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));

  }


    handleChange(selectorFiles)
    {

     this.setState({ file : selectorFiles[0]  });

     $('.file-upload-wrapper').attr("data-text",  selectorFiles[0].name);      

    }

  renderForm = (data) => {
    const { joinOurTeam } = data.pages.forms;
    const { title, subtitle, inputs } = joinOurTeam;
    const { formState } = this.state;
    inputs.position = "Please enter the position you are interested in";

    return (
      <form onSubmit={this.submitForm}>
        <StyledTitle epsilon color="main" weight="medium" block>
          {title}
        </StyledTitle>
        <StyledSubtitle alpha color="gray" block>
          {subtitle}
        </StyledSubtitle>
        <InputGroup>
          <Input placeholder={inputs.firstName} name="first_name" onChange={this.handleInputChange} required />
          <Input placeholder={inputs.lastName} name="last_name" onChange={this.handleInputChange} required />
        </InputGroup>
        <InputGroup>
          <Input fluid placeholder="Email" name="email" onChange={this.handleInputChange} required />
        </InputGroup>
        <InputGroup>
          <Input placeholder={inputs.phone} name="phone" onChange={this.handleInputChange}  />
          <Input placeholder={inputs.mobile} name="mobile" onChange={this.handleInputChange} required />
        </InputGroup>


        <InputGroup>
          <Input placeholder={inputs.position} name="position" onChange={this.handleInputChange}  required />
        </InputGroup>

        <InputGroup>
          <Textarea fluid placeholder={inputs.comments} name="comments" onChange={this.handleInputChange} />
        </InputGroup>
        <InputGroupFile className="file-input">
          <div class="form">
              <div class="file-upload-wrapper" data-text="Select your file!">
                <Input className="file-upload-field" name="file" onChange={ (e) => this.handleChange(e.target.files) } type="file" required />       
              </div>
           </div>
        </InputGroupFile>
        <CallToActionContainer>
          <Button disabled={formState.loading} alt block>
            {inputs.submit}
          </Button>
        </CallToActionContainer>
      </form>
    );
  }

  renderContent = (data) => {
    const { formState: { loading, sent } } = this.state;

    if (sent && !loading) {
      return this.renderFormState();
    } else {
      return this.renderForm(data);
    }
  }

  renderFormState = () => {
    const { errorMessage, formState: { error, success } } = this.state;

    if (error) {
      return (
        <Sans color="white" beta>
          {errorMessage || 'Error.'}
        </Sans>
      )
    }
    if (success) {
      return <FormSuccess />
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query JoinOurTeamForm {
            pages {
              forms {
                joinOurTeam {
                  title
                  subtitle
                  inputs {
                    firstName
                    lastName
                    phone
                    mobile
                    comments
                    attachment
                    submit
                  }
                }
              }
            }
          }
        `}
        render={this.renderContent}
      />
    );
  }
}

export default JoinOurTeam;
