import {TimelineMax, Back /*,TweenLite, TimelineLite, , Elastic, Power4, Power0, Linear*/ } from 'gsap';
//This i need for a plane animation: MorphSVGPlugin

import $ from 'jquery';


export function singlePageMotion() {
  
  // Condition to not perform ANY animations when building for production (just when opened in browser)
  if (typeof document !== 'undefined') {
    setTimeout(() => {
      console.log('single animation');

      var singleAnimation = new TimelineMax({delay: 2.3});
      singleAnimation
       .to(".single-loading", 0.6, {autoAlpha:0}, "-=0")
    }, 0);
  }
}


//Scroll Animations 


export function functionHeroAniamtion() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    console.log('section1');

    var point = $('#dotGrid .scale').toArray();
    var pointAnimations = new TimelineMax({repeat: -1});
    point.sort(function(){return 0.5-Math.random()});
    pointAnimations
      .staggerFromTo(point, 0.8, {autoAlpha: 0, scale: 1, ease: Back.easeOut}, {autoAlpha: 1, scale: 1, ease: Back.easeOut}, 0.25)
      .staggerTo(point, 0.8, {autoAlpha: 0, scale: 1, ease: Back.easeOut}, 0.25)
  }

  
}

export function functionSectionLoad1() {



  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
  
    .set('.sectionLoadInner', {opacity: 1})
    .staggerFrom(".sectionLoadInner > *", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

}

export function functionSectionLoad2() {


  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
    
    .set('.sectionLoad2Inner', {opacity: 1})
    .staggerFrom(".sectionLoad2Inner > *", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }
}

export function functionSectionLoad3() {


  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
    
    .set('.sectionLoad3', {opacity: 1})
    .staggerFrom(".sectionLoad3 > *", 0.6, {autoAlpha:0}, 0.1, "-=0")
  }
}






