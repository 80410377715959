import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { fonts } from 'Typography';
import sc from 'config/theme';

const afterColorHelper = ({ active, white }) => {
  if (white) {
    return active ? '#335eb3' : sc.colors.main;
  } else {
    return active ? '#335eb3' : '#c0c0c0';
  }
};

const textColorHelper = ({ active, white }) => {
  if (white) {
    return active ? sc.colors.white : '#6d7585';
  } else {
    return active ? sc.colors.main : '#c0c0c0';
  }
};

const StyledStepWrapper = styled.div`
  text-align: center;
  position: relative;
`;

const StyledStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    display: flex;
    border-radius: 50%;
    position: absolute;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ white }) => (white ? '#2b3a58' : '#dfe2e6')};
    z-index: 1;
  }

  &::after {
    content: '${({ stepNumber }) => stepNumber}';
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    left: 7px;
    top: 7px;
    background: ${afterColorHelper};
    z-index: 2;
    color: ${sc.colors.white};
    font-family: ${fonts.sans};
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.68px;
    transition: 0.3s ease;
  }
`;

const StyledText = styled.div`
  color: ${textColorHelper};
  font-family: ${fonts.sans};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.57px;
  line-height: 8.82px;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  transition: 0.3s ease;
  white-space: nowrap;
`;

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 70px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 23px;
    height: 1px;
    background: #dfe2e6;
  }
`;

export class Step extends PureComponent {
  static propTypes = {
    onSelect: PT.func,
    steps: PT.arrayOf(
      PT.shape({
        label: PT.string,
      })
    ),
    handleStepClick: PT.func,
    white: PT.bool,
  };

  render() {
    const { white, stepNumber, children, active, handleStepClick } = this.props;

    return (
      <StyledStepWrapper onClick={handleStepClick} white={white}>
        <StyledStep stepNumber={stepNumber} active={active} white={white} />
        <StyledText active={active} white={white}>
          {children}
        </StyledText>
      </StyledStepWrapper>
    );
  }
}

export class FormProgress extends PureComponent {
  render() {
    const { white, activeStep, steps, handleStepClick } = this.props;

    return (
      <StyledContainer>
        {steps.map((step, index) => (
          <Step
            handleStepClick={() => handleStepClick(index)}
            key={step.label}
            stepNumber={index + 1}
            active={index + 1 <= activeStep}
            white={white}
          >
            {step.label}
          </Step>
        ))}
      </StyledContainer>
    );
  }
}

FormProgress.Step = Step;

export default FormProgress;
