import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import sc from 'config/theme';

const Button = styled.button`
  width: 36px;
  height: 36px;
  /* background: ${sc.colors.main}; */
  border-radius: 50%;
  position: relative;
  color: ${sc.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    left: -7px;
    top: -7px;
    background: rgba(${sc.colors.gray_rgb}, 0.5);
    z-index: 0;
    border-radius: 50%;
  }

  &::after {
    content: '';
    background: ${sc.colors.main};
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100px;
  }

  &:active {
    outline: none;
  }

  i {
    margin: 0;
    z-index: 2;

  }
`;

export class CloseButton extends PureComponent {
  render() {
    const { onClick } = this.props;
    return (
      <Button onClick={onClick}>
        <Icon name="close" />
      </Button>
    );
  }
}

export default CloseButton;
