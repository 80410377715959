import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {  StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import sc from 'config/theme';
import { Button, Grid, Spacer, Column, InnerContainer, Media } from 'components/ui';
import { Script, Serif } from 'Typography';
import bp from 'config/responsive';
  

//import tempGrid from './TEMP-mid-grid.png';

const Container = styled.div`
  width: 100%;
  background-color: ${sc.colors.main};
  padding: 0px 0;
  position: relative;


  ${bp.computer} {
    padding: 20px 0;
    overflow: hidden;
  }
  ${bp.smallNotebook} {
    padding: 20px 0;
  } 

  & .svg--lines__bg {
    height: 100%;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: 0;
    bottom: 0;
    transform: scale(1.01);

    svg {
      margin: auto;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      #bg--lines, #line--anim, #line--anim-2, #line--anim-3, #line--anim-4 {
        opacity: 0.5;
      }
      #bg--lines circle, #bg--lines path  {
        visibility: hidden;
        opacity: 0.15 !important;
      }
      
    }

    ${bp.large} {
      transform: scale(1.1);
    }
  }


  .customFontSize {
    font-size: 18px;
    font-family :'acumin-pro', sans-serif;

    ${bp.computer} {
      font-size: 28px;
    }
  }


`;

const StyledColumn = styled(Column)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const PosterContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const PosterFront = styled.div`
  width: 270px;
  margin: -100px -77px 0 auto;
  
  border-radius: 10px;
  overflow: hidden;

  > * {
    vertical-align: top;
  }

  ${bp.computer} {
    width: 290px;
    height: 320px;
  }
  ${bp.large} {
    width: 490px;
    height: 560px;
  }
`;

const PosterBack = styled.div`
  width: 300px;
  border-radius: 10px;
  overflow: hidden;

  > * {
    vertical-align: top;
  }

  ${bp.computer} {
    width: 320px;
    height: 420px;
  }
  ${bp.large} {
    width: 570px;
    height: 650px;
  }
`;

const Headline = styled.div`
  margin-left: 0;
  margin-bottom: 30px;
`;

const Paragraph = styled.div`
  margin-bottom: 70px;
`;

const StyledContent = styled(Column)`
  &&& {
    align-items: flex-start;

    &.mobileWhoWeAre {
      padding-left: 25px !important;
      padding-right: 25px !important;
      padding-top: 60px!important;
      padding-bottom: 60px!important;
    }
  }
`;




export class HomeWhoWeAre extends PureComponent {
  render() {
    const { whoWeAre } = this.props.content;
    const { title, bodyText, btn, img1, img2, img1alt, img2alt} = whoWeAre;

     const  posterback = {src:img1, height:655, width: 570};
     const  posterfront = {src:img2, height:563, width: 490};

    return (
      <Container className="visibility-test">
        <div className='svg--lines__bg'>
          <svg id='lines--aniamtion' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1280 742'>
            <rect x='-44.54' y='-13.13' width='1354.66' height='761.99' fill='#0b1d3f'
            />
            <g id='bg--lines' fill='none' stroke='#fff'>
                <circle id='Oval-Copy' cx='573.78' cy='4.69' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-2' cx='759.1' cy='54.35' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-3' cx='943.78' cy='103.84' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-4' cx='1129.11' cy='153.49' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-5' cx='1079.28' cy='339.47' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-20' cx='1265.25' cy='389.3' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-21' cx='1215.42' cy='575.27' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-6' cx='1029.44' cy='525.44' r='5.7' transform='rotate(-80.78 1029.48 525.428)'
                  strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-17' cx='844.12' cy='475.78' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-19' cx='474.11' cy='376.64' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-38' cx='659.44' cy='426.3' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-7' cx='979.61' cy='711.41' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-8' cx='794.29' cy='661.76' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-9' cx='609.61' cy='612.27' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-11' cx='238.96' cy='512.96' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-36' cx='424.28' cy='562.61' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-12' cx='288.79' cy='326.98' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-13' cx='338.62' cy='141.01' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-39' cx='523.95' cy='190.67' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-15' cx='709.27' cy='240.32' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-16' cx='893.95' cy='289.81' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <path id='Line-Copy-9' d='M746.79,51.05l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-10' d='M931.47,100.54,771.74,57.74' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-12' d='M1116.15,150,956.42,107.2' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-13' d='M1301.68,199.56,1142,156.76' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-18' d='M511.43,187.49l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-29' d='M384.89-31.68l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-38' d='M570.22,18l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-39' d='M755.54,67.63l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-40' d='M940.22,117.12l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-41' d='M1125.55,166.78l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-49' d='M1311.52,216.61l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-30' d='M335.06,154.29,292.26,314' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-35' d='M520.39,204l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-37' d='M705.71,253.61l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-43' d='M1075.72,352.75l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-46' d='M1261.69,402.58l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-42' d='M890.39,303.09l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-31' d='M285.23,340.27,242.43,500' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-34' d='M470.55,389.92l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-36' d='M655.88,439.58l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-45' d='M1025.88,538.72l-42.8,159.74' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-47' d='M1211.86,588.56l-42.63,159.08' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-44' d='M840.56,489.07,797.76,648.8' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-32' d='M235.57,525.59,192.94,684.67' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-33' d='M420.9,575.25,371,761.53' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-52' d='M606.22,624.91,563.6,784' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-53' d='M790.9,674.39,748.27,833.48' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-54' d='M976.23,724.05,933.6,883.13' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-17' d='M696.75,237.14l-160.37-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-16' d='M881.67,287,721.94,244.2' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-15' d='M1066.35,336.52l-159.73-42.8' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-23' d='M461.6,373.46l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-22' d='M646.92,423.12l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-21' d='M832,472.37l-159.73-42.8' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-20' d='M1016.69,521.85,857,479.05' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-48' d='M1252,385.84,1092.29,343' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-56' d='M1524.18,458.77l-245.75-65.85' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-57' d='M1474.35,644.74,1228.6,578.89' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-50' d='M1202.19,571.82,1042.46,529' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-51' d='M1152.36,757.79,992.63,715' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-28' d='M411.77,559.43l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <circle id='Oval-Copy-24' cx='52.98' cy='463.13' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-18' cx='102.81' cy='277.15' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <circle id='Oval-Copy-14' cx='152.65' cy='91.18' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <path id='Line-Copy-19' d='M325.45,137.65l-160.37-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-65' d='M198.92-81.51,156.12,78.22' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-77' d='M805.55-119,762.75,40.74' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-79' d='M1175.55-19.84l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-78' d='M990.23-69.5,947.43,90.23' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-64' d='M149.09,104.46l-42.8,159.73' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-63' d='M99.25,290.44,56.46,450.17' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-62' d='M49.6,475.76,7,634.84' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-24' d='M275.62,323.63l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-61' d='M225.79,509.6l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-72' d='M140.13,88-20.25,45'  
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-67' d='M90.3,274-70.08,231'  
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-66' d='M40.47,459.94l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-27' d='M597.09,609.09l-160.38-43' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-26' d='M782.18,658.34l-159.73-42.8' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-25' d='M966.86,707.82,807.13,665' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <circle id='Oval-Copy-31' cx='189.3' cy='698.28' r='5.7' 
                 strokeWidth='1.342' opacity='0.3' />
                <path id='Line-Copy-96' d='M185.91,710.92,143.29,870' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-88' d='M402,755.45,201.73,701.79' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <circle id='Oval-Copy-30' cx='3.32' cy='648.45' r='5.7' transform='rotate(-89.86 3.34 648.474)'
                  strokeWidth='1.342' opacity='0.3' />
                <path id='Line-Copy-87' d='M-.06,661.09-42.69,820.17' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
                <path id='Line-Copy-86' d='M176.13,694.93,15.75,652' 
                 strokeLinecap='square' strokeWidth='0.671' opacity='0.3' />
            </g>
            <g id='anim1'>
                <g id='point'>
                    <circle id='circle--bg' cx='152.65' cy='91.18' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow' cx='152.65' cy='91.18' r='6.65' fill='#edb551'
                    />
                </g>
                <line id='line--anim' x1='384.89' y1='-54.98' x2='152.61' y2='91.16' fill='none'
                stroke='#edb551' strokeLinecap='square' strokeWidth='1.342' />
            </g>
            <g id='anim2'>
                <g id='point-3'>
                    <circle id='circle--bg-3' cx='1079.28' cy='339.47' r='12.41' 
                    fill='#cfcfcf' fillOpacity='0.365' opacity='0.564'
                    />
                    <circle id='circle--yellow-3' cx='1079.28' cy='339.47' r='6.65' fill='#edb551'
                    />
                </g>
                <g id='point-4'>
                    <circle id='circle--bg-4' cx='980.24' cy='711.58' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow-4' cx='980.24' cy='711.58' r='6.65' fill='#edb551'
                    />
                </g>
                <g id='point-5'>
                    <circle id='circle--bg-5' cx='844.1' cy='475.78' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow-5' cx='844.1' cy='475.78' r='6.65' fill='#edb551'
                    />
                </g>
                <polyline id='line--anim-2' points='1079.28 339.47 844.06 475.75 980.24 711.58'
                fill='none' stroke='#edb551' strokeLinecap='square' strokeWidth='1.342'
                />
            </g>
            <g id='anim3'>
                <g id='point-6'>
                    <circle id='circle--bg-6' cx='189.3' cy='698.31' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow-6' cx='189.3' cy='698.31' r='6.65' fill='#edb551'
                    />
                </g>
                <g id='point-7'>
                    <circle id='circle--bg-7' cx='424.48' cy='562' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow-7' cx='424.48' cy='562' r='6.65' fill='#edb551'
                    />
                </g>
                <line id='line--anim-3' x1='424.52' y1='562.02' x2='189.3' y2='698.31'
                fill='none' stroke='#edb551' strokeLinecap='square' strokeWidth='1.342'
                />
            </g>
            <g id='anim4'>
                <g id='point-8'>
                    <circle id='circle--bg-8' cx='943.74' cy='103.88' r='12.41' 
                     fill='#cfcfcf' fillOpacity='0.365' opacity='0.564' />
                    <circle id='circle--yellow-8' cx='943.74' cy='103.88' r='6.65' fill='#edb551'
                    />
                </g>
                <line id='line--anim-4' x1='943.74' y1='103.88' x2='1178.96' y2='-32.41'
                fill='none' stroke='#edb551' strokeLinecap='square' strokeWidth='1.342'
                />
            </g>
        </svg>
      </div>
        
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <InnerContainer className="scrollAnimation">
          <Grid>
            <Spacer />
            <StyledContent width={5} column center>
              <Column width={2}>
                <Headline>
                  <Script beta color="yellow">
                    {title}
                  </Script>
                </Headline>
              </Column>

              <Paragraph>
                <Serif alpha color="white" lineHeight="1.4">
                  <div className="customFontSize">{bodyText}</div>
                </Serif>
              </Paragraph>
              <Button href="/about">{btn}</Button>
            </StyledContent>
            <Spacer />
            <StyledColumn width={4} verticalAlign column center>
               <PosterContainer className="FF">
                <PosterBack className="parallax-image-1">
                  <Img fixed={posterback} alt={img1alt}/>
                </PosterBack>
                <PosterFront className="parallax-image-2">
                  <Img fixed={posterfront} alt={img2alt} />
                </PosterFront>
              </PosterContainer>

            </StyledColumn>
            <Spacer />
          </Grid>
        </InnerContainer>
      )}
      renderIfFalse={() => (
        <InnerContainer className="scrollAnimation">
          <Grid>
            <StyledColumn width={12} verticalAlign column center>
            
            </StyledColumn>
            <StyledContent width={12} column center className="mobileWhoWeAre">
              <Column width={12}>
                <Headline>
                  <Script beta color="yellow">
                    {title}
                  </Script>
                </Headline>
              </Column>

              <Paragraph>
                <Serif alpha color="white" lineHeight="1.4">
                  <div className="customFontSize">{bodyText}</div>
                </Serif>
              </Paragraph>
              <Button href="/about">{btn}</Button>
            </StyledContent>
            
          </Grid>
        </InnerContainer>  
      )} />

      </Container>
    );
  }
}

export default HomeWhoWeAre;
