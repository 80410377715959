import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slick from 'react-slick';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  SliderArrow,
  HighlightButton,
  Media,
  Button,
} from 'components/ui';
import { TeamMember } from 'components/blocks';
import { Script, Sans, Serif } from 'Typography';
import sc from 'config/theme';
import request from 'utils/request';
import bp from 'config/responsive';


const Container = styled.div`
  background: ${sc.colors.main};
  padding-top: 40px;
  padding-bottom: 40px;

  .developmentPricing {
    ${bp.computer} {
      
    }

  }

  ${bp.computer} {
    padding-top: 149px;
    padding-bottom: 202px;
  }



  .customFontSize, .customFontSize * {
    font-size: 18px;
    font-family :'acumin-pro', sans-serif;

    ${bp.computer} {
      font-size: 28px;
    }
  }
`;

const ColumnSec = styled.div`
  &.btn-center {
    display: flex;
    justify-content: center;
    width: 62.5%!important;

  }
`




const DetailButton = styled(Button)`
  max-width: 100%;
  margin-top: 20px !important;
  margin-bottom: 35px !important;

  ${bp.tablet} {
    max-width: 90%;
    /* margin-top: unset !important;
    margin-bottom: unset !important; */
  }
  ${bp.computer} {
    /*margin-top: unset !important;*/
    margin-bottom: unset !important;
  }
`;


const PropertyButton = styled(Button)`
  max-width: 100%;
  width: 100%;
  
  ${bp.tablet} {
    max-width: inherit;
    width: 100%;
    /* margin-top: unset !important;
    margin-bottom: unset !important; */
  }
  ${bp.computer} {
    /*margin-top: unset !important;*/
    margin-bottom: unset !important;
    font-size: 14px !important;
    width: 100%;
  }
`;




const TableContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;

  ${bp.computer} {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  
`;

const TableHeader = styled.div`
  display: none;
  background: #DDDDDD;
  justify-content: start;

  ${bp.computer} {
    display: flex;
  }
`;

const TableRow = styled.div`
  display: flex;
  background: ${sc.colors.white};
  flex-direction: column;
  border-bottom: 1px solid #e4e4e4;

  ${bp.computer} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TableItem = styled.div`
  padding: 1.5rem 2rem;
  font-size: 1.3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  &[data-title]::before {
    content: attr(data-title); 
    display: block;
    font-weight: 600;
    margin-bottom: 1rem;
    opacity: .4;

    ${bp.computer} {
      display: none;

    }
  }

  &.headerTitle {
    text-transform: uppercase;
    font-size: 1.1rem;
  }
  span {
    opacity: 0.7;  
  }

  ${bp.computer} {
    width: 20%;
  }
`;

/*rgb(11, 29, 63) none repeat scroll 0% 0%;*/


const StyledScript = styled(Script)`
  margin-bottom: 32px;
`;

const StyledSerif = styled(Serif)`
  display: block;
  font-size: 20px;

  ${bp.computer} {
    font-size: 30px;
  }
  ${bp.large} {
    /* font-size: 40px; */
  }
`;

  const linkClick = (link) => {
      window.location.href =  link;
  }

export class DevelopmentsPricing extends PureComponent {
  state = {
    selectedMember: {},
    data: [],
  };

  sliderSettings = {
    className: 'slider-outer',
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 1,
    variableWidth: true,
    variableHeight: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',

    responsive: [
      
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]

  };

  handleClick = member => this.setState({ selectedMember: member });

  getData = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('aboutTeamMembersv2');

      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };

  componentDidMount() {
    this.getData();
  }







  render() {
    const { pricing  } = this.props;
    const { pricing_link  } = this.props;
    return (
        <Container>

          <InnerContainer className="developmentPricing">
            <Grid>
              <Spacer />
              <Column width={10}>
                <StyledScript color="yellow" beta>
                  Pricing and Availability
                </StyledScript>
              </Column>
              <Spacer width={1} />
            </Grid>

            <Grid>
                <Spacer />

                  <Column width={10}>
                  
                   <TableContainer>
                      <TableHeader>
                        <TableItem className="headerTitle">Type</TableItem>
                        <TableItem className="headerTitle">Size</TableItem>
                        <TableItem className="headerTitle">Price</TableItem>
                        <TableItem className="headerTitle">Status</TableItem>
                        <TableItem className="headerTitle">Details</TableItem>
                      </TableHeader>
                      
                      
                      {pricing.map((el, index) => {
                        return (
                          <TableRow>
                            <TableItem data-title="Type"><span>{el.type}</span></TableItem>
                            <TableItem data-title="Size"><span>{el.size}</span></TableItem>
                            <TableItem data-title="Price"><span>{el.price}</span></TableItem>
                            <TableItem data-title="Status"><span>{el.status}</span></TableItem>
                            <TableItem>    
                              <a href={el.details} target="_blank">
                              <PropertyButton main compact >
                                View Property
                              </PropertyButton>
                              </a>
                            </TableItem>
                          </TableRow>
                        );
                      })}

                    </TableContainer>


                  </Column>
                
                <Spacer />
              </Grid>
            
              <Grid>
                <Spacer />

                <ColumnSec width={10} className="btn-center">
                  <DetailButton alt compact onClick={() => linkClick(pricing_link) }>
                    See All Properties
                  </DetailButton>
                </ColumnSec>

                <Spacer />
              </Grid>
            

          </InnerContainer>
        </Container>
    );
  }
}

export default DevelopmentsPricing;