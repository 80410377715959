import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fonts } from 'Typography';
import sc from 'config/theme';

const DatePickerWrapper = styled.div`
  .react-datepicker {
    border-radius: 5px;
    border: 1px solid #d4d4d4;
  }
  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__week,
  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
  }
  .react-datepicker__header {
    background: transparent;
    border-bottom: none;
    padding-top: 33px;
  }
  .react-datepicker__current-month {
    padding: 0 45px;
    text-align: left;
    color: #6b7897;
    font-family: ${fonts.sans};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.59px;
    margin-bottom: 10px;
  }
  .react-datepicker__day-names,
  .react-datepicker__month {
    padding: 0 45px;
    width: calc(100% + 26px);
    margin: 0 -13px;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #7c86a2;
    font-family: ${fonts.sans};
    font-size: 15px;
    font-weight: 500;
  }
  .react-datepicker__day-name {
    opacity: 0.54;
  }
  .react-datepicker__month {
    padding-bottom: 25px;
  }
  .react-datepicker__day--outside-month {
    color: #e1e4e7;
  }
  .react-datepicker__day,
  .react-datepicker__month-text {
    border-radius: 50% !important;
  }
  .react-datepicker__day--selected {
    border-radius: 50%;
    color: ${sc.colors.white};
    background-color: ${sc.colors.main};
  }
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover {
    border-radius: 50%;
    color: ${sc.colors.white};
    background-color: ${sc.colors.main};
  }
  .react-datepicker__navigation {
    top: 37px;
  }
  .react-datepicker__navigation--next {
    right: 45px;
    transform: translateX(100%);
  }
  .react-datepicker__navigation--previous {
    left: auto;
    right: 70px;
  }
`;

export class DatePicker extends PureComponent {
  render() {
    return (
      <DatePickerWrapper>
        <ReactDatePicker
          {...this.props}
          inline
        />
      </DatePickerWrapper>
    )
  }
}