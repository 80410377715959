import React, { PureComponent } from 'react';
import PT from 'prop-types';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import marker from 'images/map-marker.svg';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSectionLoad3} from 'motion/single-property.js';
import bp from 'config/responsive';


const key = 'AIzaSyCuS5Z05YuKuDXjDrLkjFC1a9HzxeIjJXw';

const MarkerIcon = styled(() => <img src={marker} alt="marker" />)`
  width: 22px;
`;

const Marker = styled.div`
    width: 22px;
    margin-top: -47px;
    margin-left: -11px;
`; 

const Container = styled.div`
  width: 100%;
  display: none;
  height: 55vh;
  min-height: 350px;

  ${bp.tablet} {
    min-height: 85vh;
  }

  ${bp.smallNotebook} {
    height: 100vh;
    min-height: 700px;
  }


`;

export class SinglePropertyMap extends PureComponent {
  static propTypes = {
    center: PT.shape({
      lat: PT.number,
      lng: PT.number,
    }),
    zoom: PT.number,
  }

  render() {
    const { center, zoom } = this.props;
    var showstreet = true;
    return (
      <ViewportEnterAnimation offset={100} animation={functionSectionLoad3}>
        <Container className="sectionLoad3 maptoggler">
          <GoogleMapReact
            options={{streetViewControl:true}}
            bootstrapURLKeys={{ key }}
            defaultCenter={center}
            defaultZoom={zoom}
          >
            <Marker {...center} >
            <MarkerIcon/>
            </Marker>
          </GoogleMapReact>
        </Container>
      </ViewportEnterAnimation>
    );
  }
}

export default SinglePropertyMap;
