import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import { Sans } from 'Typography';
import sc from 'config/theme';
import { Icon } from 'semantic-ui-react';

const TimePickerWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 72px;
  justify-content: center;
  align-items: center;
  padding: 19px 12px 12px;
  background: white;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  text-align: center;

  input {
    display: none;
  }

  .subtitle {
    color: ${({ checked }) => checked ? `rgba(${sc.colors.white_rgb}, 0.52)` : '#0b1d3f'};
  }

  ${({ checked }) => checked && css`
    border-color: transparent;
    background: ${sc.colors.main};
  `}
`;

const Check = styled(Icon)`
  &&& {
    position: absolute;
    right: 5px;
    top: 5px;
    color: white;
  }
`;

const StyledTitle = styled(Sans)`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.57px;
  text-transform: uppercase;
  margin-bottom: 7px;
`;

const StyledSubtitle = styled(Sans)`
  font-size: 14px;
  font-weight: 400;
`;

export class TimePicker extends PureComponent {
  static propTypes = {
    name: PT.string,
    value: PT.string,
    title: PT.string,
    subtitle: PT.string,
    checked: PT.bool,
    onChange: PT.func,
  }

  uniqueId = `TimePicker-ID-${Math.random().toString(32).substr(2)}`;

  render() {
    const {
      title,
      subtitle,
      checked,
      ...props
    } = this.props;

    return (
      <TimePickerWrapper checked={checked}>
        <input id={this.uniqueId} type="radio" checked={checked} {...props} />
        {/* <label htmlFor={this.uniqueId}> */}
          {checked && <Check name="check" />}
          <StyledTitle color={checked ? 'white' : 'main'} block>
            {title}
          </StyledTitle>
          <StyledSubtitle className="subtitle" block>
            {subtitle}
          </StyledSubtitle>
        {/* </label> */}
      </TimePickerWrapper>
    );
  }
}