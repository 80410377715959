import styled, { css } from 'styled-components';
import { Header as H } from 'semantic-ui-react';
import sc from 'config/theme';

export const Header = styled(H)`
  && {
    color: ${sc.colors.white};
    ${({ color }) =>
      color &&
      css`
        color: ${sc.colors[color]};
      `}
  }
`;
