import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import {Button, Grid, Spacer, Column, Hero, Media } from 'components/ui';
import { PropertySearchSimple } from 'components/blocks';
import sc from 'config/theme';
import breakpoint from 'config/responsive';
import { Script, Sans } from 'Typography';
import bp from 'config/responsive';



const Background = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(${sc.colors.main_rgb}, 0.90),
      rgba(${sc.colors.muted_rgb}, 0.50)
    );
    top: 0;
    left: 0%;
  }
`;

const MobilePropertyBox = styled.div`
  position: relative;
  z-index: 1;
`;


const StyledBackgroundSection =  styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src}) ;
  background-size: cover;
`;

const ContentContainer = styled.div`
  top: 0;
  left: 50%;
  width: 100%;
  max-width: ${sc.screens.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
  padding-top: 70px;
  min-height: 375px;


  ${breakpoint.large} {
    opacity: 1;
  }
  ${bp.computer} {
    height: inherit;
  }


  .grid {
    opacity: 1;

    ${bp.computer} {
      opacity: 0;
    }
  }

`;

const Headline = styled.div`
  margin-bottom: 15px;

  ${bp.computer} {
    margin-bottom: 15px;
  }

  ${bp.large} {
    margin-bottom: 42px;
  }
`;

const TextContainer = styled.div`
  width: 90%;

  ${bp.computer} {
    width: 50%;
    max-width: 620px;
  }
`;

const PropertySearchContainer = styled.div`
  flex: 0 0 120px;
  margin: 0 0 0 auto;

  ${bp.computer} {
    flex: 0 0 380px;
  }

  ${bp.large} {
    flex: 0 0 520px;
  }
`;

const InstaValuationModal = () => {
  console.log('Click on BTN for Modal friend');
  //window.open('https://valuations.ernest-brooks.com/', '_blank');
  if (typeof window !== 'undefined') {
    window.openInstantValuation && window.openInstantValuation();
  }
  
}

export class HomeHero extends PureComponent {
  render() {
    const { propertySearchForm } = this.props;
    const { landing } = this.props.content;
    const { title, bodyText, img } = landing;

    return (

      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Hero text="Scroll">
          <Background>
            <StyledBackgroundSection src={img} />
          </Background>
          <ContentContainer>
            <Grid>
              <Spacer />
              <Column verticalAlign center width={10}>
                <TextContainer>
                  <Headline>
                    <Script alpha block color="white" className="title">
                      {title}
                    </Script>
                  </Headline>
                  <Sans alpha block color="white" className="headerDescription">
                    {bodyText}
                  </Sans>
                  <br /><br />
                  <Button onClick={InstaValuationModal} className="rows" ><small>Selling Or Letting?</small>Instant Valuation</Button>

                </TextContainer>
                <PropertySearchContainer className="propertySearchBox">
                <PropertySearchSimple showTabs={true} defaultChecked="sales" content={propertySearchForm} />   
                </PropertySearchContainer>

              </Column>
              <Spacer />
            </Grid>
          </ContentContainer>
        </Hero>
      )}
      renderIfFalse={() => (
        <Hero>
          <Background>
            <StyledBackgroundSection src={img} />
          </Background>
          <ContentContainer>
            <Grid>

              <Column verticalAlign center width={12}>
                <TextContainer>
                  <Headline>
                    <Script alpha block color="white" className="title">
                      {title}
                    </Script>
                  </Headline>
                  <Sans alpha block color="white" className="headerDescription">
                    {bodyText}
                  </Sans>
                </TextContainer>

              </Column>

            </Grid>
          </ContentContainer>
          <MobilePropertyBox className="mobilePropertyBox">
            <PropertySearchContainer className="propertySearchBox">
             <PropertySearchSimple showTabs={true} defaultChecked="sales" content={propertySearchForm} />   
            </PropertySearchContainer>
          </MobilePropertyBox>
        </Hero>

      )} />
    );
  }
}

export default HomeHero;
