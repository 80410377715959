/**
 * hex2rgb
 *
 * @description Creates RGB array from HEX color
 * @param {String} hexCode HEX code with or without # ranging from 3 to 6 chars
 * @returns {Array|String} Returns in format [R, G, B]
 */
function hex2rgb (hexCode) {
  try {
    let newHex = String(hexCode).replace(/[^0-9a-f]/gi, ''); // Remove all non-hexadecimal characters

    if (newHex.length < 6) { // Duplicate if 3 characters are supplied
      newHex = newHex.split('').map(char => `${char}${char}`).join('');
    }

    if (newHex.length !== 6) {
      throw new Error('Invalid HEX format');
    }

    // Parse each pair of characters
    const red = parseInt(newHex.substr(0, 2), 16);
    const green = parseInt(newHex.substr(2, 2), 16);
    const blue = parseInt(newHex.substr(4, 2), 16);

    return [red, green, blue];
  } catch (err) {
    return hexCode; // Return original color on error
  }
};

export default hex2rgb;
