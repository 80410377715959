import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';

import {
  InputGroup,
  PillMenu,
  Button,
  InputRange,
  Input,
  Dropdown,
  Checkbox,
} from 'components/ui';
import sc from 'config/theme';
import { Sans } from 'Typography';
import { FormContext } from 'context/propertySearch';
import { searchOptions } from '../../../data';


import bp from 'config/responsive';
import { redirectTo } from '@reach/router';


const Container = styled.div`
  width: 100%;
  background: ${sc.colors.main};
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 29px;
  padding-right: 28px;
  

  ${bp.computer} {
    padding-top: 22px;
    padding-bottom: 36px;
    padding-left: 29px;
    padding-right: 28px;
  }
  ${bp.large} {
    padding-top: 42px;
    padding-bottom: 76px;
    padding-left: 49px;
    padding-right: 48px;
  }
  
  & .newInput * {
    color: #0b1d3f;
  }

`;

const DropdownContainer = styled.div`
  margin-bottom: 15px;

  ${bp.computer} {

  }
  ${bp.large} {
    margin-bottom: 15px;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;

const PillMenuContainer = styled.div`
  margin: 0 0 15px;
  display: ${({ hideTabs }) => (hideTabs ? 'none' : 'block')};

  ${bp.computer} {

  }
  ${bp.large} {
    margin: 0 0 20px;
  }
`;

const PriceRangeContainer = styled(InputGroup)`
  margin-bottom: 40px;
`;

const Title = styled.div`
  margin-bottom: 25px;

  ${bp.computer} {

  }
  ${bp.large} {
    margin-bottom: 42px;
  }
`;


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #ddd',
    color: '#0B1D3F',
    padding: 20,
    

  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 'inherit',
    background: 'white',
    borderRadius: 6,
    'padding-top': 5,
    'padding-bottom': 5,
    'padding-left': 10,
    'padding-right': 10,
    display: 'flex',
    alignItems: 'center', 
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "'acumin-pro',sans-serif",
    "border": "1px solid rgba(151,151,151,0.22)",
    height: 54, 
    
  }),
  placeholder: () => ({
    color: 'rgba(22,49,99,0.52) !important',
    "marginLeft": "2px",
    "marginRight": "2px",
    "position": "absolute",
    "top": "50%",
    "transform": "translateY(-50%)",
    "boxSizing": "border-box"
    
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


const DropdownIndicator = (props) => {
  return (
    null
  );

};
const IndicatorSeparator = (props) => {
  return (
    null
  );
};



const filterSearch = (area) => {
  const matches = searchOptions.filter(i =>
    i.label.toLowerCase().includes(area.toLowerCase())
  );

  return matches
};

const loadOptions = (area, callback) => {
  setTimeout(() => {
    callback(filterSearch(area));
  }, 1000);
};


export class PropertySearch extends PureComponent {
  static propTypes = {
    hideTabs: PT.bool,
    defaultChecked: PT.string,
  };

  static defaultProps = {
    defaultChecked: 'lettings',
  };

  state = {
    form: {
      priceMin: this.props.content.defaultCost.min,
      priceMax: this.props.content.defaultCost.max,
      enquiryType: 'Lettings',
      area: null,
      propertyType: null,
      bedrooms: null,
      includeNewHomes: true,
      sortBy: 'date' ,
      sortOrder: 'DESC', 
      rangeMin : 0,
      rangeMax : 2000,
      page : 1,

    },
    error: false,
    inputValue : null,
    menuIsOpen : false,
  };

  closeMenu = () => {
    this.setState({ menuIsOpen: false });
   };

  openMenuIsOpen = () => {
    this.setState({ menuIsOpen: true });

  };
  handleInputChange = (newValue) => {

    if (newValue != ""){
        this.openMenuIsOpen();
    }else{
      this.closeMenu();
    }
  };

  handleInputChange1 = (newValue, { form, setArea }) => {

    if (newValue != null){
      if (newValue.hasOwnProperty('value') ){

        const inputValue = newValue.value;
        this.setState({ inputValue });

        this.closeMenu();
        setArea(inputValue);
        return inputValue;
      }else{
        this.closeMenu();
        setArea('');

        return null;
      }
    }else{
      this.closeMenu();
      setArea('');
      return null;
    }
  };

  createlabel = (inputValue) => {
    return "Search \"".concat(inputValue, "\"");
  }

  handlePillChange = (offerType, { form, handleChange }) => {

    const name = 'enquiryType';
    const value = form[name] === offerType ? '' : offerType;
    if (offerType === "Rent" ){
      form.rangeMax = 1000;
      form.rangeMin = 200;
      form.priceMin = 200;
      form.priceMax = 500;
      form.stepSize = 50;
    }else{
       form.rangeMin = 100000;
       form.rangeMax = 1000000;
       form.priceMin = 100000;
       form.priceMax = 1000000;
       form.stepSize = 10000;
    }
   

    const event = {
      target: {
        name,
        value,
      },
    };
    handleChange(event);


  };

  handleSearchfake = (e) =>{
    window.location.href = '/search-results?priceMin=200&priceMax=10000&rangeMin=200&rangeMax=10000&enquiryType=Rent&area=&propertyType=null&bedrooms=null&more=1-option&stepSize=50&sortBy=date&sortOrder=DESC&page=1';

  }




  render() {
    const { hideTabs, defaultChecked , hideTabsfake} = this.props;
    const { title, offerTypes } = this.props.content;
    const { labels, selects } = this.props.content.form;

    return (
      <FormContext.Consumer>
        {({
          form,
          inputValue,
          handleChange,
          handleSearch,
          handleSemanticChange,
          handleRangeChange,
          setArea
        }) => {
          return (
            <Container>
              <Title>
                <Sans epsilon block color="white" weight="medium">
                  {title}
                </Sans>
              </Title>
              <PillMenuContainer hideTabs={hideTabs}>
                <PillMenu inputName="enquiryType2" showCheck>
                  
                  <PillMenu.Pill
                    onClick={() =>
                      this.handlePillChange(offerTypes.sales, {
                        form,
                        handleChange,
                      })
                    }
                    value={offerTypes.sales}
                    checked={form['enquiryType'] === offerTypes.sales}
                  >
                    {offerTypes.sales}
                  </PillMenu.Pill>
                  <PillMenu.Pill
                    onClick={() =>
                      this.handlePillChange(offerTypes.lettings, {
                        form,
                        handleChange,
                      })
                    }
                    value={offerTypes.lettings}
                    checked={
                      form['enquiryType'] === offerTypes.lettings
                    }
                  >
                    {offerTypes.lettings}
                  </PillMenu.Pill>
                </PillMenu>
              </PillMenuContainer>

              <InputGroup className="newInput">
              
                <AsyncCreatableSelect

                    components={{ DropdownIndicator, IndicatorSeparator }}
                    placeholder="Search"
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    isClearable = {true}
                    formatCreateLabel = {this.createlabel}
                    menuIsOpen={this.state.menuIsOpen}
                    styles={customStyles}
                    onChange={(value) => {
                        this.handleInputChange1( value, {form, setArea})
                      }
                    }
                    
                    onInputChange= {this.handleInputChange}

                />
                 <input 
                  type="hidden" 
                  name="area" 
                  value={inputValue} 
                />  

                <input 
                  type="hidden" 
                  name="sortBy" 
                  value={form.sortBy} 
                />  
                <input 
                  type="hidden" 
                  name="sortOrder"
                  value={form.sortOrder} 
                />  
                <input 
                  type="hidden" 
                  name="page"
                  value="1" 
                />  

              </InputGroup>
              <DropdownContainer>
                <InputGroup>

                  <Dropdown
                    placeholder={labels.bedrooms}
                    selection
                    onChange={handleSemanticChange}
                    name="bedrooms"
                    value={form.bedrooms}
                    options={selects.bedrooms.map(select => ({
                      ...select,
                      key: select.value,
                    }))}
                  />
                  <Dropdown
                    placeholder={labels.apartment}
                    selection
                    onChange={handleSemanticChange}
                    name="propertyType"
                    value={form.propertyType}
                    options={selects.apartment.map(select => ({
                      ...select,
                      key: select.value,
                    }))}
                  />
                </InputGroup>
              </DropdownContainer>

              {!hideTabsfake &&
              <PriceRangeContainer>
                <InputRange
                  typeT = {form.enquiryType}
                  stepSize = {form.stepSize}
                  minValue = {form.rangeMin}
                  maxValue = {form.rangeMax}
                  value={{
                    min: form.priceMin,
                    max: form.priceMax,
                  }}

                  handleChange={handleRangeChange}
                />
              </PriceRangeContainer>
              }
              <CallToActionContainer>
                <Checkbox
                  label={labels.includeNewHomes}
                  name="includeNewHomes"
                  checked={form.includeNewHomes}
                  onChange={handleSemanticChange}
                  defaultChecked
                />


                {hideTabsfake ?(

                <Button alt block onClick={this.handleSearchfake}>
                  {labels.searchBtn}
                </Button>


                ):(

                 <Button alt block onClick={handleSearch}>
                  {labels.searchBtn}
                 </Button>

                )}


              </CallToActionContainer>
            </Container>
          );
        }}
      </FormContext.Consumer>
    );
  }
}

export default PropertySearch;
