import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  FormProgress,
  Input,
  InputGroup,
  Checkbox,
  Button,
  FormSuccess,
} from 'components/ui';
import { Sans } from 'Typography';
import sc from 'config/theme';
import request from 'utils/request';

const PaddedRow = styled.div`
  margin-bottom: 50px;
`;

const Text = styled.div`
  margin: 0 60px;
`;

const StyledTitle = styled(Sans)`
  margin-bottom: 40px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }
  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;

const ErrorMessage = styled.div`
  color: #d80000;
  margin: 0 0 15px;
`;

const SectionTitle = styled(Sans)`
  letter-spacing: 0.57px;
  line-height: 8.82px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 27px;
  display: block;
`;

const SubmitButton = styled(Button)`
  &&& {
    margin: 0 auto !important;
    display: block;
  }
`;

const StyledForm = styled.form``;

const form = {};

export class ReferFriend extends PureComponent {

  constructor(props) {
    super(props);


    this.state = {
      form,
      activeStep: 1,
      formState: {
        loading: false,
        error: false,
        success: false,
        sent: false,
      },
      handleChange: this.handleChange,
      handleStepClick: this.handleStepClick,
      gdprChecked: false,
      gdprCheckedfriend: false,
      gdprWarning: false,
      warning : "",
    }

    this.handleChange = ({ target: { name, value } }) => {
      console.log({ target: { name, value }})
      
      this.setState( { warning: '' } );

      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };

    this.handleGdprChange = () => {
      this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
      this.setState(({ gdprCheckedfriend }) => ({ gdprCheckedfriend: !gdprCheckedfriend }));
      this.setState( { gdprWarning: false } );

    }



    this.handleStepClick = (stepIndex = 1) => {
      
      this.setState( { warning: '' } );
     
      const isvalid = this.validateStep(stepIndex);

      if (!isvalid){

        this.setState( { warning: 'All fields are required.' } );

        return;
      }

      this.setState({ activeStep: stepIndex + 1 });

      if (stepIndex === 2) {
        this.submitForm && this.submitForm();
      }

    }


  }



  hasErrorField = (field) =>{
    const form  = this.state.form;

    if (form.hasOwnProperty(field) ){
      if (form[field] == '' ){
        return true;
      }
    }else{
      return true;
    }
    return false;
  }

  validateStep = (step) =>{

    console.log('validate');
   
    var errors =  {};

    if (step === 1){

      errors['first_name'] = this.hasErrorField("first_name");
      errors['last_name'] = this.hasErrorField("last_name");
      errors['email'] = this.hasErrorField("email");
      errors['mobile'] = this.hasErrorField("mobile");


    }

    if (step === 2){

      errors['friend_first_name'] = this.hasErrorField("friend_first_name");
      errors['friend_last_name'] = this.hasErrorField("friend_last_name");
      errors['friend_email'] = this.hasErrorField("friend_email");
      errors['friend_mobile'] = this.hasErrorField("friend_mobile");

      if (!this.state.gdprCheckedfriend){
        errors['gdprfriend'] = true;
        this.setState( { gdprWarning: true } );
      }
    }



  
    const values = Object.values(errors)
    for (const value of values) {
      if (value){
        return false;
      }
    }

    return true;

  };



  handleGdprChange = () => {
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }

  submitForm = async (event) => {

    const { form } = this.state;
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    let formFilled = true;



    Object.keys(form).forEach(entry => {
      console.log(entry, form[entry])
      if (form[entry] === '' || form[entry] === null) {
        formFilled = false;
      }
    })


    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }

    data.append('subject', 'Refer a friend');

    if (formFilled) {
      try {
        this.setState({ formState: { ...this.state.formState, loading: true } });
        await request.post('forms', data);
        this.setState({ formState: { ...this.state.formState, loading: false, success: true, sent: true } });
      } catch (err){
        console.error('Sorry, form was not sent.');
        this.setState({ formState: { ...this.state.formState, loading: false, error: true, sent: true } });
      }
    }
  }

  renderStep1 = (formData) => {
    const { form, gdprWarning, formState } = this.state;

    return (
      <React.Fragment>
        <InputGroup>
          <Input
            fluid
            name="first_name"
            value={form.first_name}
            onChange={this.handleChange}
            placeholder={formData.inputs.firstName}
          />
          <Input
            fluid
            name="last_name"
            value={form.last_name}
            onChange={this.handleChange}
            placeholder={formData.inputs.lastName}
          />
        </InputGroup>
        <InputGroup>
          <Input
            fluid
            name="email"
            value={form.email}
            onChange={this.handleChange}
            placeholder={formData.inputs.email}
          />
          <Input
            fluid
            name="mobile"
            value={form.mobile}
            onChange={this.handleChange}
            placeholder={formData.inputs.mobile}
          />
        </InputGroup>
        <PaddedRow>
          <CallToActionContainer>

            <Button disabled={formState.loading} alt block onClick={() => this.handleStepClick(1)}>
              {formData.inputs.submit}
            </Button>
          </CallToActionContainer>
        </PaddedRow>
      </React.Fragment>
    );
  }

  renderStep2 = (formData) => {
    const { form, gdprCheckedfriend, gdprWarning, formState } = this.state;

    return (
      <React.Fragment>
        <InputGroup>
          <Input
            fluid
            name="friend_first_name"
            value={form.friend_first_name}
            onChange={this.handleChange}
            placeholder={formData.inputs.firstName}
          />
          <Input
            fluid
            name="friend_last_name"
            value={form.friend_last_name}
            onChange={this.handleChange}
            placeholder={formData.inputs.lastName}
          />
        </InputGroup>
        <InputGroup>
          <Input
            fluid
            name="friend_email"
            value={form.friend_email}
            onChange={this.handleChange}
            placeholder={formData.inputs.email}
          />
          <Input
            fluid
            name="friend_mobile"
            value={form.friend_mobile}
            onChange={this.handleChange}
            placeholder={formData.inputs.mobile}
          />
        </InputGroup>
        <PaddedRow>
          <CallToActionContainer>
            <Checkbox
              color="main"
              name="gdpr"
              required
              label={formData.inputs.gdpr}
              checked={gdprCheckedfriend}
              onChange={this.handleGdprChange}
              error={gdprWarning}
            />
            <Button disabled={formState.loading} alt block onClick={() => this.handleStepClick(2)}>
              {formData.inputs.submit}
            </Button>
          </CallToActionContainer>
        </PaddedRow>
      </React.Fragment>
    );
  }

  renderForm = (data) => {
    const formData = data.pages.forms.referFriend;
    const { title } = formData;
    const { activeStep, formState, warning } = this.state;

    const steps = formData.steps.map(step => ({ label: step }));

    return (
      <StyledForm onSubmit={e => e.preventDefault()}>
        <StyledTitle epsilon color="main" weight="medium" block>
          {title}
        </StyledTitle>
        <PaddedRow>
          <FormProgress
            handleStepClick={this.handleStepClick}
            activeStep={activeStep}
            steps={steps}
          />
        </PaddedRow>
        {(formState.sent && !formState.loading) || activeStep === 3 ? (
          <React.Fragment>
            {formState.error && (
              <Sans color="main" beta>
                Error
              </Sans>
            )}
            {formState.success && <FormSuccess />}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ErrorMessage> { warning } </ErrorMessage>
            {activeStep === 1 && this.renderStep1(formData)}
            {activeStep === 2 && this.renderStep2(formData)}
          </React.Fragment>
        )}
      </StyledForm>
    );
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query ReferFriendModal {
            pages {
              forms {
                referFriend {
                  title
                  subtitle
                  steps
                  inputs {
                    firstName
                    lastName
                    mobile
                    email
                    gdpr
                    submit
                  }
                }
              }
            }
          }
        `}
        render={this.renderForm}
      />
    )
  }
}

export default ReferFriend;
