import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import {
  Input,
  InputGroup,
  Button,
  Checkbox,
  Textarea,
  FormSuccess,
} from 'components/ui';
import { Sans, Script, Serif } from 'Typography';
import request from 'utils/request';
import bp from 'config/responsive';


const Title = styled.div`
  margin-bottom: 25px;

  ${bp.computer} {
    max-width: 570px;
  }
`;

const Content = styled.div`
  margin-bottom: 62px;

  .customFontSize, .customFontSize * {
    font-size: 18px;
    font-family :'acumin-pro', sans-serif;

    ${bp.computer} {
      font-size: 28px;
    }
  }

  ${bp.computer} {
    max-width: 570px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;

  ${bp.computer} {
    max-width: 570px;
  }
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;



export class NewHomesContactForm extends PureComponent {
  state = {
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
    },
    gdprChecked: false,
    gdprWarning: false,
  }

  static propTypes = {
    title: PT.string,
    description: PT.string,
    fields: PT.shape({
      firstName: PT.string,
      lastName: PT.string,
      phone: PT.string,
      email: PT.string,
      price: PT.string,
      plotNumber: PT.string,
      address: PT.string,
      moreInfo: PT.string,
      gdprCheckbox: PT.string,
      submit: PT.string,
    }),
  }

  handleGdprChange = () => {
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }

  submitForm = async (event) => {
    const { gdprChecked } = this.state;

    event.preventDefault();


    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }



    data.append('subject', 'Off plan new home');




    if (gdprChecked) {
      try {
        this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });
        await request.post('forms', data);
        this.setState({ formState: { ...this.state.formState, loading: false, success: true } })
      } catch (err) {
        this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
      }
    } else {
      this.setState({ gdprWarning: true });
    }
  }


  handleInputChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;

  
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));

  }

  renderForm = () => {
    const {
      firstName,
      lastName,
      phone,
      email,
      price,
      plotNumber,
      address,
      moreInfo,
      gdprCheckbox,
      submit,
    } = this.props.fields;
    const { formState, gdprChecked, gdprWarning } = this.state;

    return (
      <FormWrapper>
        <form onSubmit={this.submitForm}>
          <InputGroup>
            <Input placeholder={firstName} name="first_name" onChange={this.handleInputChange} required />
            <Input placeholder={lastName} name="last_name" onChange={this.handleInputChange} required />

          </InputGroup>
          <InputGroup>
            <Input placeholder={phone} name="phone" onChange={this.handleInputChange}  />
            <Input placeholder={email} name="email" onChange={this.handleInputChange} required />
          </InputGroup>
          <InputGroup>
            <Input name="price" placeholder={price}  onChange={this.handleInputChange} required />
            <Input name="plotNumber" placeholder={plotNumber} onChange={this.handleInputChange} required />
          </InputGroup>
          <InputGroup>
            <Input name="address" placeholder={address} onChange={this.handleInputChange} required />
          </InputGroup>
          <InputGroup>
            <Textarea name="comments" placeholder={moreInfo} onChange={this.handleInputChange}  />
          </InputGroup>
          <FormFooter>
            <Checkbox name="gdpr" required label={gdprCheckbox} checked={gdprChecked} onChange={this.handleGdprChange} error={gdprWarning} />
            <Button disabled={formState.loading} alt>{submit}</Button>
          </FormFooter>

        </form>
      </FormWrapper>
    )
  }

  renderContent = () => {
    const { formState: { loading, sent } } = this.state;

    if (sent && !loading) {
      return this.renderFormState();
    } else {
      return this.renderForm();
    }
  }

  renderFormState = () => {
    const { errorMessage, formState: { error, success } } = this.state;

    if (error) {
      return (
        <Sans color="white" beta>
          {errorMessage || 'Error.'}
        </Sans>
      )
    }
    if (success) {
      return <FormSuccess white />
    }
  }

  render() {
    const { title, description } = this.props;

    return (
      <React.Fragment>
        <Title>
          <Script beta color="yellow">{title}</Script>
        </Title>
        <Content>
          <div className="customFontSize">
            <Serif delta color="white">{description}</Serif>
          </div>
        </Content>
        {this.renderContent()}
      </React.Fragment>
    )
  }
}