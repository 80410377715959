import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import styled, { css } from 'styled-components';
import { Sans } from 'components/ui';
import sc from 'config/theme';
import formatPrice from 'utils/formatPrice';
import marker from 'images/map-marker.svg';
import { fitBounds } from 'google-map-react/utils';

const key = 'AIzaSyCuS5Z05YuKuDXjDrLkjFC1a9HzxeIjJXw';
const RentTag = styled(Sans)`
  display: ${({ display }) => (display ? 'inline' : 'none')};
  margin-left: 2px;
  font-size: 12px!important;
`;
const MarkerContent = styled(Link)`
  visibility: hidden;
  opacity: 0.8;
  width: 178px;
  /* height: 203px; */
  position: absolute;
  background: white;
  bottom: 35px;
  left: -78px;
  z-index: 9;
  transition: 0.2s ease-in-out;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
`;

const MarkerWrapper = styled.div`
  position: relative;
  width: 22px;
  height: 29px;
  margin-top: -29px;
  margin-left: -11px;

  &:hover {
    ${MarkerContent} {
      visibility: visible;
      opacity: 1;
      transform: translateY(-5px);
    }
  }
  ${({ active }) =>
    active &&
    css`
      ${MarkerContent} {
        visibility: visible;
        opacity: 1;
        transform: translateY(-5px);
      }
    `}
`;

const MarkerPoster = styled.div`
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 140px;
`;

const MarkerMeta = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  padding: 13px;
  padding-bottom: 9px;
  padding-top: 4px;
  color: ${sc.colors.main};
`;

const Address = styled(Sans)`
  font-size: 12px;
  margin-bottom: 5px;
`;

const MarkerIcon = styled.img`
  width: 22px;
`;

const Marker = ({ marker: { address, price, src, id, rent, pricePerWeek, ...rest }, active }) => (
  <MarkerWrapper active={active}>
    <MarkerIcon src={marker} alt={address} />
    <MarkerContent
      to={
       `/single-property?id=${id}`
      }
    >
      <MarkerPoster src={src} />
      <MarkerMeta>
        <Address color="main" medium>
          {address}
        </Address>
        <Sans color="main" delta weight="bold">
          {formatPrice(price)}
            <RentTag display={pricePerWeek} alpha>
              pw
            </RentTag>
        </Sans>
      </MarkerMeta>
    </MarkerContent>
  </MarkerWrapper>
);

const Container = styled.div`
  width: 100%;
  height: 100%;
`;




export class SearchResultsMap extends PureComponent {

  state = {
    center: {
      lat: 51.5150401,
      lng: -0.1428472,
    },
    zoom: 12,
    map: {},
    maps: {},
    centermarkers: {},
  };

  getMapCenter = markers => {
    const {map} = this.state;
    const {maps} = this.state;

    if (maps != undefined){


      const bounds = new maps.LatLngBounds();
 
      maps.event.addListenerOnce(map, 'bounds_changed', function() { this.setZoom(Math.min(15, this.getZoom())); })

      //const {centermarkers} = this.state;

      markers.map(place => {
        if (place.lat != 0 || place.lng !=0){
          let pos = { lat: parseFloat(place.lat), lng: parseFloat(place.lng) }
          bounds.extend(pos); 
        }
       
      });
      map.fitBounds(bounds);
    }

  };

  componentDidUpdate(prevProps) {

    const { markers } = this.props;

    if (prevProps.markers !== markers) {

      this.setState({ centermarkers: markers });
    
      if(markers.hasOwnProperty('error')){
       return;
      }
      this.getMapCenter(markers);

    }
  }


  loadHandler = (map, maps) => {

    this.setState({ map: map });
    this.setState({ maps: maps });

  };



  render() {
    const { markers, currentHovered } = this.props;

    if(markers.hasOwnProperty('error')){
     return (
        <Container>
          <GoogleMapReact
            bootstrapURLKeys={{ key }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
          >
          </GoogleMapReact>
        </Container>
      );
    }else{

        return (
        <Container>
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals={true}
             onGoogleApiLoaded={({ map, maps }) => this.loadHandler(map, maps)}
             center={this.state.center}
             zoom={this.state.zoom}
            bootstrapURLKeys={{ key }}
          >
            {markers.map(marker => (
              <Marker
                active={marker === currentHovered}
                lat={marker.lat}
                lng={marker.lng}
                marker={marker}
              />
            ))}
          </GoogleMapReact>
        </Container>
      );

    }
  }
}

export default SearchResultsMap;
