import React, { PureComponent } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {
  RecommendationsSlider,
  HomeOffices,
  HomePulseContainer,
} from 'components/blocks';
import { ViewportEnterAnimation } from 'components/ui';
import { functionHomePulseContainer, functionRecommendationsSlider, functionHomeOffices } from 'motion/index.js';
import bp from 'config/responsive';


const Container = styled.div`
  background: #f5f5f5;
  padding-bottom: 100px;
  overflow: hidden;

  ${bp.computer} {
    padding-bottom: 150px;
  }

  ${bp.large} {
    overflow: inherit;
    padding-bottom: 150px;
  }

`;

export class HomeLocations extends PureComponent {
  state = {
    recommendations: [],
  };
  async componentDidMount() {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await axios.get(
        'https://apidev.ernest-brooks.com/api/homeRecommendationsv2'
      );
      this.setState({ recommendations: data, loading: false });
    } catch (err) {
      this.setState({ loading: false, error: true });
    }
  }
  render() {
    const { recommendations } = this.state;
    const { content } = this.props;
    return (
      <Container>
        <ViewportEnterAnimation offset={100} animation={functionRecommendationsSlider}>
          <RecommendationsSlider data={recommendations} />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={functionHomeOffices}>
          <HomeOffices content={content.offices} />
        </ViewportEnterAnimation>

        <ViewportEnterAnimation offset={100} animation={functionHomePulseContainer}>
          <HomePulseContainer content={content.about} />
        </ViewportEnterAnimation>
      </Container>
    );
  }
}

export default HomeLocations;
