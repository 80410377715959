import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql, navigate , Link} from 'gatsby';
import Img from 'gatsby-image';
import { Icon } from 'semantic-ui-react';
import sc from 'config/theme';
import bp from 'config/responsive';
import { PreloadSlide } from 'components/ui';
import { fonts, Sans } from 'Typography';
import formatPrice from 'utils/formatPrice';
import request from 'utils/request';

const bezier = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: default;
  background: ${sc.colors.main};

  border-radius: 11px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  
  & * {
    -webkit-transform:translateZ(0);
  }
  
`;
const RentTag = styled(Sans)`
  display: ${({ display }) => (display ? 'inline' : 'none')};
  margin-left: 8px;
`;

const InnerRail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(${({ width }) => width} * 100%);
  height: 100%;
  display: flex;
  background: ${sc.colors.main};
  transform: translate3d(
    calc((-100% / ${({ width }) => width}) * ${({ moveX }) => moveX}),
    0,
    0
  );
  transition: transform 0.8s ${bezier};
  backface-visibility: hidden; 
`;

const Button = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 15%;
  z-index: 8;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.55s ${bezier};

  ${bp.computer} { 
    padding-left: 33px;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 0.55s ${bezier};
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.25),
      rgba(255, 255, 255, 0.1) 15%,
      transparent
    );
    opacity: 0;

    ${({ next }) =>
      next &&
      css`
        transform: scaleX(-1);
      `}
  }

  ${({ prev }) =>
    prev &&
    css`
      left: 0;
    `}
  ${({ next }) =>
    next &&
    css`
      right: 0;
      justify-content: flex-end;
      padding-left: 0;
      padding-right: 15px;

      ${bp.computer} { 
        padding-right: 33px;
      }
    `}

  i {
    font-size: 22px;
    margin: 0;
    color: ${sc.colors.white};

    ${bp.large} {
      font-size: 22px;
    }
  }

  ${({ disabled, active }) =>
    disabled &&
    active &&
    css`
      opacity: 0.2;
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
  `}
`;

const Meta = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  z-index: 6;
  padding: 0 40px 40px;
  cursor: pointer;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.21) 50%,
    #051127 100%
  );
  ${bp.mobile} {
    padding: 0 23px 14px;
  }
`;

const MetaCol = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${bp.mobile} {
    justify-content: flex-start;
    &:last-of-type {
      display: none;
    }
  }

  &.metaTitle-left {
    justify-content: flex-start;  
  }
  &.metaTitle-right {
    justify-content: flex-end;  
  }
`;

const Price = styled.div`
  background: #0B1D3F;
  position: absolute;
  border-radius: 5rem;
  top: 30px;
  left: 30px;
  padding: 10px 25px;
  line-height: 30px;

  ${bp.computer} {
    background: rgba(${sc.colors.white_rgb}, 0.14);
    position: relative;
    top: 0;
    left: 0px;
    padding: 5px 20px;
    line-height: 33.3px;
  }
`;

const PriceMobile = styled.div`
  display: none;

  ${bp.mobile} {
    display: block;
    background: ${sc.colors.main};
    border-radius: 5rem;
    padding: 10px 25px;
    position: absolute;
    z-index: 2;
    left: 15px;
    top: 15px;
  }
`;

const StyledImage = styled(Img)`

  position: absolute;
  overflow: hidden;
  z-index: 9999;
  left: 10px;
  top: 10px;
  width: 90px;
   ${bp.mobile} {
     display: none;

   }



  picture img,
  img {
    
  }
`;

export const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "helpicon.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <StyledImage fluid={data.placeholderImage.childImageSharp.fluid} alt="logo" />
    )}
  />
);

export const RibbonLabel = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  white-space: nowrap;
  color: white;

  div {
    transform: rotateZ(45deg) translateX(30%) translateY(-75%);
    width: 260px;
    padding: 10px 0 12px;
    text-align: center;
    border: 1px solid rgba(${sc.colors.white_rgb}, 0.3);
    font-family: ${fonts.sans};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.57px;
    line-height: 1em;
    text-transform: uppercase;
     ${({ colour }) =>
      colour == 'ribbonBlue' &&
      css`
        background: ${sc.colors.main};
      `}

    ${({ colour }) =>
      colour == 'ribbonWhite' &&
      css`
        background: ${sc.colors.white};
        color: ${sc.colors.main};
      `}

    ${({ colour }) =>
      colour == 'ribbonYellow' &&
      css`
        background: ${sc.colors.yellow};
      `}

    ${({ colour }) =>
      colour == 'ribbonRed' &&
      css`
        background: ${sc.colors.red};
      `}


    ${bp.mobile} {
      transform: rotateZ(45deg) translateX(30%) translateY(-125%);
    }
  }
`;

export class SliderInnerCard extends PureComponent {

  state = { currentData: [], current: 0, loading: false, error: false };

  getData = async () => {
    try {
      this.setState({ loading: true, error: false });

      this.setState({

        currentData: [this.props.cover, ...this.props.cover.images],
        current: 0,
        loading: false,

      });

    } catch (err) {
      console.log(err.message);
      this.setState({ loading: false, error: true });
    }
  };

  componentDidMount() {
    this.getData();
  }

  handleChange = bool => {
    const { current, currentData } = this.state;

    const nextIndex = bool
      ? current === this.props.cover.images.length - 1
        ? current
        : current + 1
      : current === 0
      ? current
      : current - 1;


    this.setState({
      current: nextIndex,
    });


  };

  handleLink = (propID, linktype) => {

    console.log ('HERE');
    
    let hyperlink = linktype  == "let" ?  `/single-property?id=${propID}&type=rent` : `/single-property?id=${propID}&type=sale`

    console.log(hyperlink);

    window.location(hyperlink);

  };
  componentDidUpdate(prevProps, prevState, snapshot){

    if (prevProps.cover.id !=  this.props.cover.id){
      this.setState({ 
        current: 0,
        currentData: [this.props.cover, ...this.props.cover.images],
      });
    }

  }

  render() {
    const { currentData, current } = this.state;
    const { active, cover} = this.props;
    let {htb}  = this.props;
    const width = cover.images.length;
    const moveX = current;
    const images = cover.images;
    let disabled = ( current === (width - 1) )
    if (htb == null){
        htb = false;
    }
    var ptype =  cover.type  == "let" ? 'rent' : 'sale';

    let hyperlink = cover.type  == "let" ?  `/single-property?id=${cover.id}&type=rent` : `/single-property?id=${cover.id}&type=sale`
    return (
      <Container>
        <Button
          active={active}
          disabled={current === 0}
          prev
          onClick={() => this.handleChange(false)}
        >
          <Icon name="chevron left" />
        </Button>
        <PriceMobile>
          <Sans delta color="white" weight="bold">
            {formatPrice(cover.price)}
              <RentTag display={cover.pricePerWeek} alpha>
                pw
              </RentTag>
          </Sans>
        </PriceMobile>

        { (cover.helptobuy == 1 && htb) && 
        <Logo />
        }

        <RibbonLabel colour={cover.ribbon.class} >
            <div>{cover.ribbon.text}</div>
        </RibbonLabel>


        <InnerRail moveX={moveX} width={width}>
          {images.map(({ src }, index) => (
            <PreloadSlide
              width={width}
              key={`${src}${index}`}
              url={src}
              index={index}
              current={current}
            />
          ))}
        </InnerRail>

        <Button
          active={active}
          disabled={disabled}
          next
          onClick={() => this.handleChange(true)}
        >
          <Icon name="chevron right" />
        </Button>

        <a  href={hyperlink} > 
        <Meta>
          <MetaCol className="metaTitle-left">
            <Sans eta color="white" weight="medium">
              {cover.address}
            </Sans>
          </MetaCol>
          <MetaCol className="metaTitle-right">
            <Price>
              <Sans epsilon color="white" weight="medium">
                {formatPrice(cover.price)}
                <RentTag display={cover.pricePerWeek} alpha>
                          pw
              </RentTag>
              </Sans>
            </Price>
          </MetaCol>
        </Meta>
        </a>
      </Container>
    );
  }
}

export default SliderInnerCard;
