import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  Card,
} from 'components/ui';
import sc from 'config/theme';
import { Serif } from 'Typography';
import bp from 'config/responsive';


const Container = styled.div`
  background: ${sc.colors.whiteDim};
  padding: 40px 0;

  ${bp.computer} {
    padding: 60px 0 100px;
  }
`;

const Title = styled.div`
  margin-bottom: 20px;

  ${bp.computer} {
    margin-bottom: 100px;
  }
`;

const CardWrapper = styled.div`
  margin: 15px 0;
  width: 100%;
  
  ${bp.computer} {
    margin: 15px;
  }
  ${bp.bigNotebook} {
    width: inherit;
  }
`;

const CardsContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export class AboutOurValues extends PureComponent {
  static propTypes = {
    title: PT.string,
    values: PT.arrayOf(PT.shape({
      title: PT.string,
      content: PT.string,
    })),
  }

  render() {
    const { title, values } = this.props;

    return (
      <Container id="about-our-values">
        <InnerContainer className="aboutValues">
          <Grid>
            <Spacer />
            <Column width={10}>
              <Title class="aboutValuesTitle">
                <Serif alpha color="main" block align="center">{title}</Serif>
              </Title>
              <CardsContainer className="aboutValuesCards" >
                {values.map(value => (
                  <CardWrapper>
                    <Card title={value.title} content={value.content} />
                  </CardWrapper>
                ))}
              </CardsContainer>
            </Column>
            <Spacer />
          </Grid>
        </InnerContainer>
      </Container>
    );
  }
}