/**
 * luminate
 *
 * @description Adds or removes lightness from HEX colors
 * @param {String} hexCode
 * @param {Number} luminosityFactor A number ranging from -1 to 1, where 0 is no change and 0.5 is 50% lighter
 * @returns {String} HEX color
 */
const luminate = (hexCode, luminosityFactor = 0) => {
  try {
    let newHex = String(hexCode).replace(/[^0-9a-f]/gi, ''); // Remove all non-hexadecimal characters

    if (newHex.length < 6) { // Duplicate if 3 characters are supplied
      newHex = newHex.split('').map(char => `${char}${char}`).join('');
    }

    if (newHex.length !== 6) {
      throw new Error('Invalid HEX format');
    }

    let resultHex = '#';
    for (let i = 0; i < 3; i++) {
      const decimal = parseInt(newHex.substr(i * 2, 2), 16);
      const factored = Math.round(Math.min(Math.max(0, decimal + (decimal * luminosityFactor)), 255)).toString(16);
      resultHex += `00${factored}`.substr(factored.length);
    }

    return resultHex;
  } catch (err) {
    return hexCode; // Return original color on error
  }
}

export default luminate;
