import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import sc from 'config/theme';

const StyledButton = styled(Button)`
  &&& {
    display: block;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-color: ${sc.colors.white};
    color: ${sc.colors.main};
    /* font-size: 32px; */
    /* line-height: 24px; */
    position: absolute;
    z-index: 1;
    margin-right: 6px;
    margin-left: auto;
    padding: 0;
    right: 40px;

    i {
      color: ${sc.colors.main};
    }
    &::before {
      display: block;
      content: '';
      z-index: -1;
      width: 40px;
      height: 40px;
      background-color: ${sc.colors.white};
      opacity: 0.13;
      position: absolute;
      left: ${(28 - 40) / 2}px;
      top: ${(28 - 40) / 2}px;
      border-radius: 50%;
    }
    ${({ dark }) =>
      dark &&
      css`
        background-color: ${sc.colors.main};

        i {
          color: ${sc.colors.white};
        }
        &::before {
          background-color: ${sc.colors.main};
        }
      `}
  }
`;

// export const PlusButton = styled.div`
//   display: block;
//   border-radius: 50%;
//   width: 28px;
//   height: 28px;
//   background-color: ${sc.colors.white};
//   color: ${sc.colors.main};
//   font-size: 32px;
//   line-height: 24px;
//   position: relative;
//   z-index: 1;
//   margin-right: 0;
//   margin-left: auto;

//   &::before {
//     display: block;
//     content: '';
//     z-index: -1;
//     width: 40px;
//     height: 40px;
//     background-color: ${sc.colors.white};
//     opacity: 0.13;
//     position: absolute;
//     left: ${(28 - 40) / 2}px;
//     top: ${(28 - 40) / 2}px;
//     border-radius: 50%;
//   }
//   ${({ dark }) =>
//     dark &&
//     css`
//       background-color: ${sc.colors.main};
//       color: ${sc.colors.white};
//       &::before {
//         background-color: ${sc.colors.main};
//       }
//     `}
// `;

export const HighlightButton = ({ children, icon, ...rest }) => (
  <StyledButton {...rest} icon={icon}>
    {children}
  </StyledButton>
);

export default HighlightButton;
