import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Grid, Spacer, Column, InnerContainer, Media } from 'components/ui';
import { PropertyDetailHighlights, StampDutyCalculator } from 'components/blocks';
import { Serif } from 'Typography';
import sc from 'config/theme';

import { SlidingContainer, ViewportEnterAnimation } from 'components/ui';
import { functionHeroAniamtion} from 'motion/single-property.js';
import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.whiteDim};
  padding: 0 0 70px;

  .fullscreenProperty {
    padding: 0!important;
    width: 100vw;
    flex: initial;
  }
  ${bp.smallNotebook} {
    padding: 172px 0 140px;
  }

  .smallSingleProperty {

  }
`;

const StyledColumn = styled(Column)`
  &&&&& {
    display: flex;
    align-items: flex-start;
  }
`;

const TextWrapper = styled.div`
  padding-right: 25px;
  padding-left: 25px;

  ${bp.smallNotebook} {
    padding-right: 180px;
    padding-left: 0;
  }
`;

const HighlightsContainer = styled.div`
  flex: 0 0 0;


  ${bp.smallNotebook} {
    flex: 440px 0 0;
    margin: -${172 + 280}px 0 0 auto;
  }

  ${bp.bigNotebook} {
    flex: 0 0 480px;
    margin: -${172 + 280}px 0 0 auto;
  }
  ${bp.large} {
    flex: 0 0 570px;
    margin: -${172 + 280}px 0 0 auto;
  }

`;

export class SinglePropertyMainInfo extends PureComponent {
  static propTypes = {
    description: PT.string.isRequired,
    highlights: PT.object,
    isStampDutyOpen: PT.bool,
    toggleStampDutyModal: PT.func,
  }

  render() {
    const { isStampDutyOpen, toggleStampDutyModal, description, highlights } = this.props;

    return (
      <React.Fragment>
        <Media
          breakpoint={bp.smallNotebook}
          renderIfTrue={() => (
          <ViewportEnterAnimation offset={100} animation={functionHeroAniamtion}>
            <Container>
              <InnerContainer>
                <Grid>
                  <Spacer />
                  <StyledColumn width={10}>
                    <TextWrapper>
                      <Serif gamma>
                        {description}
                      </Serif>
                    </TextWrapper>
                    <HighlightsContainer>

                    </HighlightsContainer>
                  </StyledColumn>
                  <Spacer />
                </Grid>
              </InnerContainer>
            </Container>
          </ViewportEnterAnimation>
        )}
        renderIfFalse={() => (
          <ViewportEnterAnimation offset={100} animation={functionHeroAniamtion}>
            <Container>
              <InnerContainer>
                <Grid>

                  <HighlightsContainer className="fullscreenProperty">

                  </HighlightsContainer>

                  <StyledColumn width={12} className="smallSingleProperty">
                    <TextWrapper>
                      <Serif gamma>
                        {description}
                      </Serif>
                    </TextWrapper>

                  </StyledColumn>

                </Grid>
              </InnerContainer>
            </Container>
          </ViewportEnterAnimation>
        )} />
        <SlidingContainer closeButton fullscreenOverlay aboveNavbar isOpen={isStampDutyOpen}>
          <StampDutyCalculator />
        </SlidingContainer>
      </React.Fragment>
    );
  }
}

export default SinglePropertyMainInfo;
