import axios from 'axios';

const requestWrapper = (method = 'GET', url, ...otherParams) => {
  return axios[method.toLowerCase()](
    `https://apidev.ernest-brooks.com/api/${url}`,
    ...otherParams
  );
};

const request = requestWrapper.bind(null, 'GET');

const restMethods = ['GET', 'POST', 'PUT', 'DELETE'];
restMethods.forEach(method => {
  request[method.toLowerCase()] = requestWrapper.bind(null, method);
});

/**
 * USAGE:
 * request('https://google.com/', ...params) === axios.get('https://google.com/', ...params);
 * request.get('https://google.com/', ...params) === axios.get('https://google.com/', ...params);
 * request.post('https://google.com/', ...params) === axios.post('https://google.com/', ...params);
 * request.put('https://google.com/', ...params) === axios.put('https://google.com/', ...params);
 * request.delete('https://google.com/', ...params) === axios.delete('https://google.com/', ...params);
 */

export default request;
