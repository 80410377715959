import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { FormSuccess, Textarea, InputGroup, Input, Button, Checkbox, DatePicker, TimePicker} from 'components/ui';
import { Sans } from 'Typography';
import queryString from 'query-string';
import sc from 'config/theme';
import request from 'utils/request';

const StyledTitle = styled(Sans)`
  margin-bottom: 40px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 40px;
`;

const TimePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% + 10px);
  margin: 0 -5px 60px;
  align-items: stretch;
`;

export class PropertyEnquiry extends PureComponent {
  state = {
    form : {},
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
      form:{},
    },
    gdprChecked: false,
    gdprWarning: false,
  }

  handleGdprChange = () => {
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }

  submitForm = async (event) => {
   
    const { gdprChecked } = this.state;

    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }

    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });

    if(vars.hasOwnProperty("id")){
      data.append('propertyID', vars['id']);
      data.append('enquiry_type', vars['type']);
      data.append('propertyURL', window.location.href);
      data.append('subject', 'Enquiry [Property ID: '+vars['id']+']');
      
    }else{
     data.append('subject', 'Enquiry');
    }

   

   event.preventDefault();

   

    if (gdprChecked) {
      try {
        this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });

        await request.post('forms' , data);

        this.setState({ formState: { ...this.state.formState, loading: false, success: true } })


      } catch (err) {
        this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
      }
    } else {
      this.setState({ gdprWarning: true });
    }

  }

  handleDateChange = (dateChoice) => {
    this.setState({ form: { ...this.state.form, dateChoice } });
  }


  handleInputChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;

  
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));

  }

   handleChange = ({ target: { name, value } }) => {
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));
    };




  renderForm = (data) => {
    const { enquiry } = data.pages.forms;
    const { title, inputs } = enquiry;
    const { form, formState, gdprChecked, gdprWarning } = this.state;

   const formData = data.pages.forms.instantValuation;

   return (
      <form onSubmit={this.submitForm} type="post">
        <StyledTitle epsilon color="main" weight="medium" block>
          Book a viewing
        </StyledTitle>
        <InputGroup>
          <Input 
          name="first_name"
          placeholder={inputs.firstName} 
          onChange={this.handleInputChange} 
          required />
          <Input placeholder={inputs.lastName} name="last_name" onChange={this.handleInputChange} required />
        </InputGroup>
        <InputGroup>
          <Input placeholder="Email" name="email" onChange={this.handleInputChange} required />
          <Input placeholder="Phone" name="phone" onChange={this.handleInputChange} />
        </InputGroup>

        <DatePickerWrapper>
          <DatePicker
            onChange={this.handleDateChange}
            selected={form.dateChoice}
          />

        </DatePickerWrapper>
        <TimePickerWrapper>
          <TimePicker
            name="timeChoice"
            value="morning"
            title={formData.datepicker.timesOfDay.morning.title}
            subtitle={formData.datepicker.timesOfDay.morning.subtitle}
            checked={form.timeChoice === "morning"}
            onChange={this.handleChange}
          />
          <TimePicker
            name="timeChoice"
            value="afternoon"
            title={formData.datepicker.timesOfDay.afternoon.title}
            subtitle={formData.datepicker.timesOfDay.afternoon.subtitle}
            checked={form.timeChoice === "afternoon"}
            onChange={this.handleChange}
          />
          <TimePicker
            name="timeChoice"
            value="evening"
            title={formData.datepicker.timesOfDay.evening.title}
            subtitle={formData.datepicker.timesOfDay.evening.subtitle}
            checked={form.timeChoice === "evening"}
            onChange={this.handleChange}
          />
        </TimePickerWrapper>




        <CallToActionContainer>
          <Checkbox
            color="main"
            name="gdpr"
            required
            label={inputs.gdpr}
            checked={gdprChecked}
            onChange={this.handleGdprChange}
            error={gdprWarning}
          />
          <Button disabled={formState.loading} alt block>
            {inputs.submit}
          </Button>
        </CallToActionContainer>
      </form>
    );
  }

  renderContent = (data) => {
    const { formState: { loading, sent } } = this.state;

    if (sent && !loading) {
      return this.renderFormState();
    } else {
      return this.renderForm(data);
    }
  }

  renderFormState = () => {
    const { errorMessage, formState: { error, success } } = this.state;

    if (error) {
      return (
        <Sans color="white" beta>
          {errorMessage || 'Error.'}
        </Sans>
      )
    }
    if (success) {
      return <FormSuccess />
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query propertyEnquiryForm {
 
            
            pages {
              forms {
                enquiry {
                  title
                  inputs {
                    firstName
                    lastName
                    phone
                    mobile
                    city
                    address
                    postcode
                    comments
                    gdpr
                    submit
                  }
                }
                instantValuation {
                  title
                  subtitle
                  pills {
                    salePrice {
                      label
                      value
                    }
                    rentalPrice {
                      label
                      value
                    }
                  }
                  steps
                  footnote
                  inputs {
                    firstName
                    lastName
                    phone
                    mobile
                    address
                    city
                    postcode
                    gdpr
                    submit
                    propertyType
                    bedrooms
                  }
                  dropdowns {
                    propertyType {
                      value
                      text
                    }
                    bedrooms {
                      value
                      text
                    }
                  }
                  datepicker {
                    title
                    timesOfDay {
                      morning {
                        title
                        subtitle
                      }
                      afternoon {
                        title
                        subtitle
                      }
                      evening {
                        title
                        subtitle
                      }
                    }
                    submit
                  }
                }
              }

            }
          }
        `}
        render={this.renderContent}
      />
    );
  }
}

export default PropertyEnquiry;
