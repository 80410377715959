import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Grid, Spacer, Column, InnerContainer, PillMenu, Media } from 'components/ui';
import { LatestPropertiesSlider } from 'components/blocks';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import request from 'utils/request';
import bp from 'config/responsive';


const Container = styled.div`
  width: 100%;
  background: ${sc.colors.whiteDim};
  display: flex;
  padding-top: 30px;
  overflow: hidden;

  ${bp.computer} { 
    padding-top: 60px;
  }

  .HomeLatestPropertiesHeader {
    padding-left: 25px !important;
    padding-right: 25px !important;
    flex-wrap: wrap;

    & > div {
      width: 100%;
    }
  }
`;

const HeadlineWrapper = styled.div`
  margin-bottom: 50px;
  width: 100%;
  position: relative;

  padding: 0 10px;

  ${bp.computer} { 
    margin-bottom: 30px;
    padding: 0 60px;
  }
  ${bp.widescreen} { 

    padding: 0 0;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 10px ${52 + 58 + 50}px;

  ${bp.computer} { 
    padding: 0 60px ${52 + 58 + 100}px;
  }
  ${bp.widescreen} { 
    padding: 0 0 ${52 + 58 + 100}px;
  }
`;

const TextContent = styled.div``;

const OfferTypeSwitch = styled.div`
  margin: 40px 0 0 auto;
  background: ${sc.colors.main};
  align-self: flex-start;
  padding: 10px 12px;
  border-radius: 5px;

  ${bp.computer} { 
    margin: 8px 0 0 auto;
  }
`;

export class HomeLatestProperties extends PureComponent {
  state = {
    data: [],
    loading: true,
    error: false,
  };

  getData = async (filter) => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('homeLatestProperties'+filter);
      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };
  async componentDidMount() {
    let query = '?propertyType=all'
    this.getData(query);
  }

  handleLatestPropertiesChange(option){

    let query = '?propertyType=all'

    switch(option) {
      case 'BUY':
        query = '?propertyType=sale'
        break;
      case 'RENT':
        query = '?propertyType=let'
        break;
      case 'NEW HOMES':
        query = '?propertyType=new'
        break;
      default:
        query = '?propertyType=all'
    } 

    this.getData(query);
  }


  render() {
    const { data } = this.state;
    const { content } = this.props;
    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container className="HomeLatestProperties">
          <InnerContainer>
            <HeadlineWrapper>
              <Grid>
                <Column width={12} verticalAlign>
                  <TextContent>
                    <h2>
                      <Script beta color="main">
                        {content.title}
                      </Script>
                    </h2>
                    
                  </TextContent>
                  <OfferTypeSwitch>
                    <PillMenu inputName="offer-type-switch">
                      {content.options.map((option, index) => (
                        <PillMenu.Pill
                          key={option}
                          value={option}
                          defaultChecked={index === 0}
                          onClick={() =>
                            this.handleLatestPropertiesChange(option )
                          }
                        >
                          {option}
                        </PillMenu.Pill>
                      ))}
                    </PillMenu>
                  </OfferTypeSwitch>
                </Column>
              </Grid>
            </HeadlineWrapper>

            <SliderWrapper>
              <Grid>
                <Column width={12}>
                  <LatestPropertiesSlider data={data} />
                </Column>
              </Grid>
            </SliderWrapper>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container className="HomeLatestProperties">
          <InnerContainer>
            <HeadlineWrapper>
              <Grid>
                <Column width={12} verticalAlign className="HomeLatestPropertiesHeader">
                  <TextContent>
                    <h2>
                      <Script beta color="main">
                        {content.title}
                      </Script>
                    </h2>
                    
                  </TextContent>
                  <OfferTypeSwitch>
                    <PillMenu inputName="offer-type-switch">
                      {content.options.map((option, index) => (
                        <PillMenu.Pill
                          key={option}
                          value={option}
                          defaultChecked={index === 0}
                          onClick={() =>
                            this.handleLatestPropertiesChange(option )
                          }
                        >
                          {option}
                        </PillMenu.Pill>
                      ))}
                    </PillMenu>

                  </OfferTypeSwitch>
                </Column>
              </Grid>
            </HeadlineWrapper>
            <SliderWrapper>
              <Grid>
                <Spacer />
                <Column width={10}>
                  <LatestPropertiesSlider data={data} />
                </Column>
                <Spacer />
              </Grid>
            </SliderWrapper>
          </InnerContainer>
        </Container> 
      )} />   
    );
  }
}

export default HomeLatestProperties;
