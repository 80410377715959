import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  InnerContainer,
  Grid,
  Column,
  HighlightButton,
  SocialMediaIcon,
  Media, 
} from 'components/ui';
import { Script, Sans } from 'Typography';
import sc from 'config/theme';
import bp from 'config/responsive';

const animationLength = 700;

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(${sc.colors.white_rgb}, 0.8);
  z-index: 21;
  top: 0;
  left: 0;

  box-sizing: border-box;
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  transition: all ${animationLength / 2}ms ease-out;

  display: flex;
  align-items: center;
  justify-content: center;

  ${bp.largeNo} {
    padding: 0;
  }

  &&& > div {
    max-width: 100%;
    height: 100vh;

    .teamColumn {
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch; 
    }

    /*${bp.computer} {
      height: 85vh;
    }

     ${bp.smallNotebook} {
      max-width: 1024px;
    }
    ${bp.bigNotebook} {
      max-width: 1280px;
    }
    ${bp.large} {
      max-width: 1440px;
    } 
    ${bp.widescreen} {
      height: 65vh;
    }*/
    

  }
`;

const StyledInnerContainer = styled(InnerContainer)`
  background: ${sc.colors.main};
  height: 100%;
`;

const Avatar = styled.div`
  width: 100%;
  height: 100%;
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
`;

const StyledColumn = styled(Column)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 90px;
    padding-bottom: 90px;
    overflow: auto;

  ${bp.computer} {
    height: 100%;
    overflow: inherit;
    padding-left: 60px;
    padding-right: 60px;
  }

  ${bp.largeNo} {
    width: 100%;
    padding-left: 109px;
    padding-right: 112px;
    padding-top: 118px;
    padding-bottom: 0;

  }


`;

const DetailsContent = styled.div`
  margin-bottom: 71px;
`;

const Paragraph = styled.div`
  margin-bottom: 30px;
`;

const SocialMediaContent = styled.div`
  display: flex;
`;

const StyledName = styled(Script)`
  display: block;
  margin-bottom: 32px;
`;

const StyledPosition = styled(Sans)`
  display: block;
  margin-bottom: 18px;
`;

const StyledHighlightButton = styled(HighlightButton)`
  &&& {
    position: absolute;
    top: 39px;
    right: 36px;
  }
`;

export class TeamMember extends PureComponent {
  state = {
    show: false,
    member: {},
  };
  componentDidUpdate(prevProps) {
    if (prevProps.member !== this.props.member) {
      this.setState({
        member: this.props.member,
        show: !!Object.keys(this.props.member).length,
      });
    }
  }
  render() {
    const { handleClick } = this.props;
    const { member, show } = this.state;


    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container show={show}>
          <StyledInnerContainer show={show}>
            <Grid>
              <StyledColumn width={5} className="teamColumn">
                <Avatar src={member.avatar} />
              </StyledColumn>
              <StyledColumn width={7} className="teamColumn">
                <ContentContainer>
                  <StyledHighlightButton
                    icon="close"
                    onClick={() => handleClick({})}
                  />
                  <DetailsContent>
                    <StyledName beta color="white">
                      {member.name}
                    </StyledName>

                    <StyledPosition color="yellow" theta weight="bold">
                      {member.position}
                    </StyledPosition>

                    <SocialMediaContent>
                      <SocialMediaIcon name="at" href={`mailto:${member.email}`} />
                      <SocialMediaIcon name="phone" href={`tel:${member.phone}`} />
                      <SocialMediaIcon name="linkedin" href={member.linkedin} target="_blank"/>
                    </SocialMediaContent>
                  </DetailsContent>

                  <div>
                    <Paragraph>
                      <Sans alpha color="white">
                        {member.main}
                      </Sans>
                    </Paragraph>
                    <Paragraph>
                      <Sans alpha color="white">
                        {member.second}
                      </Sans>
                    </Paragraph>
                  </div>
                </ContentContainer>
              </StyledColumn>
            </Grid>
          </StyledInnerContainer>
        </Container>
      )}
      renderIfFalse={() => ( 
        <Container show={show}>
          <StyledInnerContainer show={show}>
            <Grid>
              <StyledColumn width={12}>
                <Avatar src={member.avatar} />
              </StyledColumn>
              <StyledColumn width={12}>
                <ContentContainer>
                  <StyledHighlightButton
                    icon="close"
                    onClick={() => handleClick({})}
                  />
                  <DetailsContent>
                    <StyledName beta color="white">
                      {member.name}
                    </StyledName>

                    <StyledPosition color="yellow" theta weight="bold">
                      {member.position}
                    </StyledPosition>

                    <SocialMediaContent>
                      <SocialMediaIcon name="at" href={`mailto:${member.email}`} />
                      <SocialMediaIcon name="phone" href={`tel:${member.phone}`} />
                      <SocialMediaIcon name="linkedin" href={member.linkedin} target="_blank"/>
                    </SocialMediaContent>
                  </DetailsContent>

                  <div>
                    <Paragraph>
                      <Sans alpha color="white">
                        {member.main}
                      </Sans>
                    </Paragraph>
                    <Paragraph>
                      <Sans alpha color="white">
                        {member.second}
                      </Sans>
                    </Paragraph>
                  </div>
                </ContentContainer>
              </StyledColumn>
            </Grid>
          </StyledInnerContainer>
        </Container>  
      )} />
    );
  }
}

export default TeamMember;
