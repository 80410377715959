import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Sans } from 'Typography';

import { ViewportEnterAnimation } from 'components/ui';
import {indexPageScroll } from 'motion/index.js';
import bp from 'config/responsive';


const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;


  ${bp.computer} {

    height: calc(100vh + 1px);
  }
  

  .scrollElement {

    display: none;

    ${bp.computer} {
      display: block;

      visibility: hidden;
      opacity: 0;
    }
  }

`;

const ScrollIndicator = styled(Sans)`
  position: absolute;
  bottom: 23px;
  left: 5%;
  padding: 0 23px 0 3px;
  border-bottom: 2px solid transparent;
  transform: rotateZ(90deg);

  &::before {
    content: '${({ text }) => text}';
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
  & > .inline {
    background: #fff;
    position: absolute;
    left: 0;
    height: 2px;
    width: 72px;
    top: 23px;
  }
`;

export class Hero extends PureComponent {
  render() {
    const { children, text } = this.props;
    return (
      <ViewportEnterAnimation offset={100} animation={indexPageScroll}>
        <Container>
          {children} 
          <ScrollIndicator text={text} className="scrollElement">
              <span className="inline"/>
            </ScrollIndicator>
        </Container>
      </ViewportEnterAnimation>
    );
  }
}

export default Hero;
