import React from 'react';

export const AnimatedGrid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="202"
    height="184"
    viewBox="0 0 202 184"
    id="dotGrid"
  >
    <path fill="#edb551" d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M59 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M115 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M144 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M173 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M2 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M59 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M87 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M115 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M144 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M173 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M2 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M31 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M59 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M115 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale"  fill="#edb551" d="M144 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale"  fill="#edb551" d="M173 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 54a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M2 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M59 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M115 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M144 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M173 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 79a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M2 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M59 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M115 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M144 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M173 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 104a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M2 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M59 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M115 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M144 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M173 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 129a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M2 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M31 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M59 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M115 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M144 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M173 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M200 154a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M2 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M31 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M59 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M87 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M115 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path fill="#edb551" d="M144 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M173 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
    <path class="scale" fill="#edb551" d="M200 179a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
  </svg>
);

export default AnimatedGrid;
