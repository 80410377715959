import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import { Grid, Column, InnerContainer, Media, Button } from 'components/ui';
import { SimilarPropertiesSlider } from 'components/blocks';
import sc from 'config/theme';
import { Script, Sans } from 'Typography';
import bp from 'config/responsive';
import request from 'utils/request';



const Container = styled.div`

  background: ${sc.colors.white};
  padding: 50px 10px 150px;
  .propeprtySlider {
    ${bp.computer} {
      opacity: 0;
    }
  }

  ${bp.computer} {
    padding: 104px 60px 142px;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 50px;

  .subtitle {
    margin-top: 10px;
  }
`;
const SearchProperties = styled(Button)`
  max-width: 100%;
  margin-top: 20px !important;
  margin-bottom: 35px !important;

`;

export class SinglePropertySimilarSliderbyID extends PureComponent {
  static propTypes = {
    data: PT.arrayOf(PT.object).isRequired,
    title: PT.string.isRequired,
    description: PT.string.isRequired,
  };

  state = {
    data: [],
    loading: true,
    error: false,
  };


  getData = async (filter, type) => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('homeLatestProperties'+filter);
      this.setState({ data, loading: false });
    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }

    if (this.state.data.length == 0){
        var query = '?propertyType='+type;
      //  this.getData(query);
    }



  };
  async componentDidMount() {
    var id = 'id';
    var type = "";

    var queryDict = {}
    window.location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})

  
    let query = "";

    if (this.props.type == "new"){
       type = "new";
       query = '?propertyType='+type+'&id='+queryDict.id;
    }else if (this.props.type == 'newdevelopments'){
       type = "newdevelopments";
       query = '?propertyType='+type+'&id='+this.props.string;
    }else{
       type = queryDict.type;
       query = '?propertyType='+type+'&id='+queryDict.id;
    }



    

    this.getData(query , type);


  }





  render() {
    const { data } = this.state;
    const { content } = this.props;

    const { type } = this.props;
    const { string } = this.props;

    let display = data.length;


    return (

      <React.Fragment>
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
          <Container>
            <InnerContainer>
              <Grid className="propeprtySlider">
                <Column width={12} className="propeprtySliderInner">
                  <TitleWrapper>
                  {display   > 0      ?
                  <React.Fragment>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                      <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                    </React.Fragment>
                  :
                  <React.Fragment>
                    <Script delta color="main" block className="title">
                    Sold out
                    </Script>

                    <Sans alpha color="gray" block className="subtitle">
                    There are no more available properties at this development.
                    </Sans>

                    <SearchProperties alt compact href="/search-results?priceMin=100000&priceMax=1000000&rangeMin=100000&rangeMax=1000000&enquiryType=Buy&area=&propertyType=null&bedrooms=null&more=1-option&includeNewHomes=true&sortBy=price&sortOrder=DESC&stepSize=10000&page=1">
                      Search Properties
                    </SearchProperties>
                  </React.Fragment>

                  }

                   
                  </TitleWrapper>
                  <SimilarPropertiesSlider data={data} />
                </Column>
              </Grid>
              
            </InnerContainer>
          </Container>
        )}
        renderIfFalse={() => (
          <Container>
            <InnerContainer>
              <Grid className="propeprtySlider">
                <Column width={12} className="propeprtySliderInner">
                  <TitleWrapper>
                  {display   > 0      ?
                  <React.Fragment>
                    <Script delta color="main" block className="title">
                      {content.title}
                    </Script>
                      <Sans alpha color="gray" block className="subtitle">
                      {content.description}
                    </Sans>
                    </React.Fragment>
                  :
                  <React.Fragment>
                    <Script delta color="main" block className="title">
                    Sold out
                    </Script>

                    <Sans alpha color="gray" block className="subtitle">
                    There are no more available properties at this development.
                    </Sans>

                    <SearchProperties alt compact href="/search-results?priceMin=100000&priceMax=1000000&rangeMin=100000&rangeMax=1000000&enquiryType=Buy&area=&propertyType=null&bedrooms=null&more=1-option&includeNewHomes=true&sortBy=price&sortOrder=DESC&stepSize=10000&page=1">
                      Search Properties
                    </SearchProperties>
                  </React.Fragment>

                  }
                  </TitleWrapper>
                  
                </Column>
              </Grid>
              <Grid>
                <Column width={1}></Column>
                <Column width={10}>
                  <SimilarPropertiesSlider data={data} />
                </Column>
                <Column width={1}></Column>
              </Grid>
            </InnerContainer>
          </Container>
        )} />


      </React.Fragment>
    );
  }
}

export default SinglePropertySimilarSliderbyID;

