import React, { PureComponent } from 'react';
import $ from 'jquery';

export class SlideReveal extends PureComponent {
  $ref = null;

  componentDidMount() {
    const { isOpen } = this.props;

    if (this.$ref) {
      if (!isOpen) {
        this.$ref.hide();
      }
    } else {
      setTimeout(() => this.$ref && this.$ref.hide(), 5);
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (prevProps.isOpen === false && isOpen === true) {
      this.slideRevealDown();
    }
    if (prevProps.isOpen === true && isOpen === false) {
      this.slideRevealUp();
    }
  }

  slideRevealUp = () => this.$ref.stop().slideUp();

  slideRevealDown = () => this.$ref.stop().slideDown();

  render() {
    const { children, ...otherProps } = this.props;

    return (
      <div className="answerText" {...otherProps} ref={(ref) => this.$ref = $(ref)}>
        {children}
      </div>
    );
  }
}

export default SlideReveal;