import React, { PureComponent } from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { StaticQuery, graphql } from 'gatsby';
import sc from 'config/theme';
import { InnerContainer, Grid, Spacer, Column, Button } from 'components/ui';
import { Script } from 'Typography';

import { Media } from 'components/ui';
import bp from 'config/responsive';



const Container = styled.div`
  width: 100%;
  position: relative;

  & .homeInstantValuation {
    opacity: 1;
    ${bp.computer} {
      opacity: 0;
    }
  }

  .homeInstantValuationTitle {
    padding: 0;

    max-width: 925px;
    margin: auto;

    ${bp.computer} {
      padding: 0 100px;
    }
    ${bp.smallNotebook} {
      padding: 0;
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(${sc.colors.main_rgb}, 0.2) 10%,
      rgba(${sc.colors.main_rgb}, 0.9)
    );
    top: 0;
    left: 0%;
  }
`;

const StyledBackgroundSection =  styled.div`
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;

`;

const ContentContainer = styled(InnerContainer)`
  padding-top: 120px;
  padding-bottom: 120px;

  ${bp.computer} {
  padding-top: 150px;
  padding-bottom: 180px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.div`
  margin-bottom: 60px;
`;

export class HomeInstantValuation extends PureComponent {
  handleButtonClick = () => {
    console.log('clickedthe button')
    if (typeof window !== 'undefined') {
      window.openInstantValuation && window.openInstantValuation();
    }
  }

  render() {
    const { content } = this.props;
    return (

      <Media
      breakpoint={bp.widescreen}
      renderIfTrue={() => (
        <Container>
          <Background>
            <StyledBackgroundSection src={content.img} />
          </Background>
          <ContentContainer className="homeInstantValuation">
            <Grid>
              <Spacer width={3} />
              <Column verticalAlign center width={6}>
                <Content>
                  <Heading>
                    <Script gamma block align="center" color="white" className="homeInstantValuationTitle">
                      {content.title}
                    </Script>
                  </Heading>
                  <Button href="https://www.ernest-brooks-interiors.com/" target="_blank">{content.btn}</Button>
                </Content>
              </Column>
              <Spacer width={3} />
            </Grid>
          </ContentContainer>
        </Container>
      )}
      renderIfFalse={() => (     
        <Container>
          <Background>
            <StyledBackgroundSection src={content.img} />
          </Background>
          <ContentContainer className="homeInstantValuation">
            <Grid>
              <Spacer width={1} />
              <Column verticalAlign center width={10}>
                <Content>
                  <Heading>
                    <Script gamma block align="center" color="white" className="homeInstantValuationTitle">
                      {content.title}
                    </Script>
                  </Heading>
                  <Button href="https://www.ernest-brooks-interiors.com/" target="_blank">{content.btn}</Button>
                </Content>
              </Column>
              <Spacer width={1} />
            </Grid>
          </ContentContainer>
        </Container>
      )} />       
    );
  }
}

export default HomeInstantValuation;
