import React from 'react';
import styled, { css } from 'styled-components';
import { Grid as G } from 'semantic-ui-react';
import sc from 'config/theme';

export const Grid = styled(G)`
  &&&& {
    width: calc(100% / 12 * 16);
    height: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
`;

export const Column = styled(G.Column)`
  &&&& {
    ${({ verticalAlign }) =>
      verticalAlign &&
      css`
        display: flex;
        align-items: center;
      `}

    ${({ column }) =>
      column &&
      css`
        display: flex;
        flex-direction: column;
      `}

      ${({ center }) =>
        center &&
        css`
          display: flex;
          justify-content: center;
        `}
  }
`;

export const Spacer = props => <G.Column width={1} {...props} />;

export const InnerContainer = styled.div`
  width: 100%;
  max-width: ${sc.screens.maxWidth}px;
  margin: 0 auto;
`;
