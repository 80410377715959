import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Script, Sans } from 'Typography';
import { Button } from 'components/ui';
import sc from 'config/theme';
import bp from 'config/responsive';


const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .aboutContactSectionColContent {
    ${bp.computer} {
      opacity: 0;
    }
  }
`;

const Column = styled.div`

  position: relative;
  background: ${sc.colors.gray};
  padding-top: 76px;
  padding-bottom: 77px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;

  ${bp.computer} {
    flex: 1;
    padding-left: 70px;
    padding-right: 70px;
    width: 50%;
  }
  ${bp.large} {
    padding-right: 350px;
  }

`;

const ColumnInner = styled.div`
  z-index: 2;
  position: relative;
  text-align: center;

  ${bp.computer} {
    text-align: inherit;
  }
`;

const Headline = styled.div`
  margin-bottom: 24px;
  margin-left: 14px;
`;

const Sub = styled.div`
  margin-bottom: 35px;

  ${bp.computer} {
    margin-bottom: 249px;
  }
`;

const StyledBackgroundImage = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  background-color: #0B1D3F;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${({ src }) => src}) ;
`;

const buttonStyle = css`
  &&& {
    padding: 0 70px;
  }
`;

const InstaValuationModal = () => {
  console.log('Click on BTN for Modal friend');
  //window.open('https://valuations.ernest-brooks.com/', '_blank');
  if (typeof window !== 'undefined') {
    window.openInstantValuation && window.openInstantValuation();

  }
  
}
const referFriendModal = () => {
  console.log('Click on BTN for Modal friend');
  if (typeof window !== 'undefined') {
    window.openReferFriend && window.openReferFriend();
  }
}

const joinOurTeamModal = () => {
  console.log('Click on BTN for Modal Join us');
  if (typeof window !== 'undefined') {


    window.openJoinOurTeam && window.openJoinOurTeam();
  }
}

export class HomeFooter extends PureComponent {
  render() {
    const { content } = this.props;
        return (
            <React.Fragment>
              <Container className="aboutContactSection">
                <Column className="aboutContactSectionCol1">
                  <StyledBackgroundImage
                    src={content.join.img}
                  />
                  <ColumnInner className="aboutContactSectionColContent">
                    <Headline>
                      <Script alpha color="white" className="aboutContactSectionTitle">
                        {content.join.title}
                      </Script>
                    </Headline>
                    <Sub className="aboutContactSectionDescription">
                      <Sans eta color="white">
                      {content.join.description}
                      </Sans>
                    </Sub>

                    <Button href="https://www.ernest-brooks-interiors.com/" target="_blank" buttonStyle={buttonStyle}>Ernest Brooks Interiors</Button>
                  </ColumnInner>
                </Column>
                <Column className="aboutContactSectionCol2">
                  <StyledBackgroundImage
                    src={content.refer.img}
                  />
                  <ColumnInner className="aboutContactSectionColContent">
                    <Headline>
                      <Script alpha color="white" className="aboutContactSectionTitle">
                        {content.refer.title}
                      </Script>
                    </Headline>
                    <Sub className="aboutContactSectionDescription">
                      <Sans eta color="white">
                        {content.refer.description}
                      </Sans>
                    </Sub>

                    <Button onClick={InstaValuationModal} buttonStyle={buttonStyle}>Instant Valuation</Button>
                  </ColumnInner>
                </Column>
              </Container>
            </React.Fragment>
    );
  }
}

export default HomeFooter;
