import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Media, Sans, fonts } from 'components/ui';
import { SliderInnerCard } from 'components/blocks';
import sc from 'config/theme';
import bp from 'config/responsive';
import formatPrice from 'utils/formatPrice';
import bed from 'images/bed.svg';
import bath from 'images/bath.svg';
import couch from 'images/couch.svg';
import parking from 'images/parking.svg';

const Container = styled.div`
  padding-bottom: 70px;
  ${bp.mobile} {
    padding-bottom: 30px;
    padding-top: 10px;
  }
`;


const Card = styled(Link)`
  background: white;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  margin-bottom: 30px;
  ${bp.mobile} {
    margin-bottom: 14px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const CardPoster = styled.div`
  background: url(${({ src }) => src}) no-repeat center center;
  background-size: cover;
  width: 40%;
  position: relative;
  overflow: hidden;
`;


const RibbonLabel = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  width: 75px;
  height: 75px;
  color: white;
  left: 0;
  div {
    position: absolute;
    transform: translatey(-50%) rotate(-45deg);
    width: 200%;
    left: -50%;
    top: 50%;
    text-align: center;
    font-size: 12px;
    border: 1px solid rgba(${sc.colors.white_rgb}, 0.3);
    padding: 10px 0 12px;
    font-family: ${fonts.sans};
    font-weight: 600;
    letter-spacing: 0.57px;
    line-height: 1em;
    text-transform: uppercase;

    ${({ colour }) =>
      colour == 'ribbonBlue' &&
      css`
        background: ${sc.colors.main};
      `}

    ${({ colour }) =>
      colour == 'ribbonWhite' &&
      css`
        background: ${sc.colors.white};
        color: ${sc.colors.main};
      `}

    ${({ colour }) =>
      colour == 'ribbonYellow' &&
      css`
        background: ${sc.colors.yellow};
      `}

    ${({ colour }) =>
      colour == 'ribbonRed' &&
      css`
        background: ${sc.colors.red};
      `}


  }
`;

const CardContent = styled.div`
  padding: 23px;
  padding-left: 41px;
  padding-right: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${sc.colors.gray};
`;

const CardIcons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 31px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 47px;
  color: ${sc.colors.main};
`;
const Icon = styled.img`
  width: ${({ width }) => width}px;
  margin: 0;
  margin-right: 8px;
`;

const Address = styled(Sans)`
  margin-bottom: 2px;
`;

const Price = styled(Sans)`
  margin-bottom: 16px;
`;

const SliderWrapper = styled.div`
  padding-top: 70% !important;
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  margin-top: 14px;
`;

const RentTag = styled(Sans)`
  display: ${({ display }) => (display ? 'inline' : 'none')};
  margin-left: 8px;
`;
const StyledImageHtb = styled(Img)`

  position: absolute !important;
  overflow: hidden;
  z-index: 4;
  right: 10px;
  top: 10px;
  width: 90px;
   ${bp.mobile} {
     display: none;
   }

  picture img,
  img {
    
  }
`;

export const Logohtb = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "helpicon.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <StyledImageHtb fluid={data.placeholderImage.childImageSharp.fluid} alt="logo" />
    )}
  />
);

export class SearchResultsProperties extends PureComponent {
  render() {
    const { markers, handleEnter} = this.props;
    let  {htb} = this.props
    if (htb == '1'){
        htb = true;
    }else{
      htb = false;
    }




    if(markers.hasOwnProperty('error')){
          return (
        <Container>
            <Address color="main" beta weight="bold">
            No property found
            </Address>
        </Container>
        );

    }else{


      return (
        <Container>

  

          <Media
            breakpoint={bp.mobile}
            renderIfTrue={() =>


              markers.map(marker => (
                <SliderWrapper key={marker.id}>
                  <SliderInnerCard active cover={marker} />
                </SliderWrapper>
              ))


            }
            renderIfFalse={() =>



              markers.map(marker => {
                const defaultPrice = formatPrice(marker.price);
                const link = marker.type  == "let" ?  `/single-property?id=${marker.id}&type=rent` : `/single-property?id=${marker.id}&type=sale`
                return (
                  <Card
                    key={marker.id}
                    onMouseEnter={() => handleEnter(marker)}
                    onMouseLeave={() => handleEnter(null)}
                    to={link}
                  >
                    <CardPoster src={marker.src}>
                      
                      { (marker.helptobuy == 1 && htb) && 

                      <Logohtb />
                      }

                      <RibbonLabel
                        colour={marker.ribbon.class}  
                      >
                        <div>
                          {marker.ribbon.text}
                        </div>
                      </RibbonLabel>

                    </CardPoster>
                    <CardContent>
                      <Address color="main" beta weight="bold">
                        {marker.address}
                      </Address>
                      <Price color="main" epsilon weight="bold">
                        {marker.priceMax
                          ? `${defaultPrice} - ${formatPrice(marker.priceMax)}`
                          : defaultPrice}
                        <RentTag display={marker.pricePerWeek} alpha>
                          pw
                        </RentTag>
                      </Price>
                      <Sans delta color="gray">
                        {marker.description}
                      </Sans>
                      <CardIcons>
                        {marker.bedrooms != 0 && (
                          <IconWrapper>
                            <Icon width={32} src={bed} alt="bed" />
                            <Sans alpha color="main">
                              {marker.bedrooms}
                            </Sans>
                          </IconWrapper>
                        )}
                        {marker.bathrooms != 0 && (
                          <IconWrapper>
                            <Icon width={24} src={bath} alt="bath" />
                            <Sans alpha color="main">
                              {marker.bathrooms}
                            </Sans>
                          </IconWrapper>
                        )}
                        {marker.livingrooms != 0  && (
                          <IconWrapper>
                            <Icon width={30} src={couch} alt="couch" />
                            <Sans alpha color="main">
                              {marker.livingrooms}
                            </Sans>
                          </IconWrapper>
                        )}
                        {marker.parking != 0 && (
                          <IconWrapper>
                            <Icon width={25} height={25} src={parking} alt="parking" />
                            <Sans alpha color="main">
                            ✓
                            </Sans>
                          </IconWrapper>
                        )}
                      </CardIcons>
                    </CardContent>
                  </Card>
                );
              })
            }
          />
        </Container>
      );

    } 


  }
}

export default SearchResultsProperties;
