import React, { PureComponent } from 'react';
import { navigate } from 'gatsby';
import  { css } from 'styled-components';
import formatPrice from 'utils/formatPrice';

import Slick from 'react-slick';
import PT from 'prop-types';
import styled from 'styled-components';
import { Grid, Spacer, Column, InnerContainer,  SliderArrow,
  SocialMediaIcon,
  Button,
 } from 'components/ui';
import {  Sans, Serif } from 'Typography';


import bp from 'config/responsive';
import sc from 'config/theme';

import shareIconDark from 'images/share-icon-dark.svg';
import callEnquiry from 'images/call-enquiry-icon.svg';




const StyledSlick = styled(Slick)`
  width: 100%;
  position: relative;
  height: 55vh;
  min-height: 350px;

  ${bp.tablet} {
    min-height: 85vh;
  }

  ${bp.smallNotebook} {
    height: 80vh;
    min-height: 700px;
  }

  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > * {
    height: 100%;
  }

  .slick-slide,
  .slick-slide > * {
    outline: none;
  }

  .slick-prev,
  .slick-next {
    left: 55px;
    bottom: 26px;
    top: unset;

    ${bp.computer} {
      left: 75px;
      bottom: 36px;
    }
    
  }
  .slick-next {
    left: 111px;

    ${bp.computer} {
      left: 161px;
    }
  }


`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  outline: none;
`;

const CoverImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-image: url(${({ source }) => source});
`;


const Container = styled.div`
  background: ${sc.colors.main};
  padding: 2rem 0 3rem;
  height: auto;


  ${bp.bigNotebook} {
    padding: 2rem 0;
    height:16vh;
    min-height: 200px; 
  }

`;

const StyledColumn = styled(Column)`
  &&&&& {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 0rem;
    padding-right: 0rem;

    ${bp.smallNotebook} {
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    ${bp.bigNotebook} {
      align-items: flex-start;
    }
  }
`;

// const TextWrapper = styled.div`
//   padding-right: 25px;
//   padding-left: 25px;

//   ${bp.smallNotebook} {
//     padding-right: 180px;
//     padding-left: 0;
//   }
// `;

const TextWrapperBigger = styled.div`
  padding-right: 0;
  padding-left: 0;
  width: 100%;

  ${bp.smallNotebook} {
    padding-right: 60px;
    padding-left: 0;
    width: 25vw;
  }
`;

const TextWrapperNogap = styled.div`
  padding-right: 0;
  padding-left: 0;
  width: 100%;

  ${bp.smallNotebook} {
    padding-right: 0;
    padding-left: 0;
    width: auto;
  }
`;


const HighlightsContainer = styled.div`
  flex: 0 0 0;
  background: ${sc.colors.white};
  border: 1px solid #cccccc;
  width: 100%;



  ${bp.smallNotebook} {
    width: auto;
    flex: 440px 0 0;
    margin: -${200}px 0 0 auto;
  }

  ${bp.bigNotebook} {
    flex: 0 0 480px;
    margin: -${200}px 0 0 auto;
  }
  ${bp.large} {
    flex: 0 0 570px;
    margin: -${170}px 0 0 auto;
  }

  & .rightImage > div {
    padding-top: 40px;
    padding-bottom: 0;

    ${bp.computer} {
      padding-bottom: 10px;
    }
  }

`;

const Price = styled(Sans)`
  color: ${sc.colors.yellow};
  font-size: 46px;

  ${bp.bigNotebook} {
    margin-top: 1rem;
  }
`;


const TopLevelWrapper = styled.div`
  position: relative;

  & .hideonMobile {
    visibility: visible;

    ${bp.mobile} {
      visibility: hidden;
    }
  }
`;

const PersonThumb = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 2rem;
  background: #ddd;
  border-radius: 8px;

  ${bp.bigNotebook} {
    width: 90px;
    height: 90px;
  }

${({ bgimage }) =>
  bgimage &&
  css`
   background-image: url(${ bgimage });

  `}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const DefaultWrapper = styled.div`
  padding: 0 25px;

  &.fullWidthButtons {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;

      ${bp.computer} {
        padding-top: 0px;
        padding-bottom: 0px;

        flex-direction: row;

        button, a {
          &.left-btn {
           width: 100%;
            margin-right: 1rem !important;
          }
          &.right-btn {
            margin-left: 1rem !important;
          }
        }
      }
  }

  ${bp.computer} {
    padding: 0 35px;

    
  }
`;

const Heading = styled(DefaultWrapper)`
  ${Serif}:first-child {
    margin-bottom: 16px;
    padding-right: 60px;
  }
`;


const SocialShareBox = styled.div`
  position: absolute;
    background: rgb(11, 29, 63);
    width: 85%;
    height: auto;
    right: 0px;
    left: 0;
    margin: auto;
    border-radius: 5px;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: .4s ease;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.05);
    will-change: transform, opacity;

    ${bp.tablet} {
      width: auto;
      right: 0;
      left: inherit;
      bottom: 0;
      top: 0;
      height: 55px;
    }

  a, a * {
    color: #fff !important;
    background: transparent !important;
  }

  a:last-child {
    margin-right: 0 !important;
  }

  i {
    font-size: 28px !important;
    line-height: 28px !important;
  }

  &:hover {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }


`


const ShareLink = styled.button`
  display: inline-flex;
  width: auto;
  flex-grow: 0;
  flex-basis: 0;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  outline: none;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 27px;
    height: 30px;
    background: url(${shareIconDark}) center no-repeat;
    margin-right: 15px;
    opacity: .2;
  }

  &:hover ~ .shareBox {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  ${bp.computer} {
    justify-content: center;
  }
`;
const Cta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};

  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}

  > * {
    flex: ${({ compact }) => (compact ? 0 : 1)} 1
      ${({ elements }) => (elements ? `${100 / elements}%` : 'auto')};
      width: 100%;

      ${bp.computer} {
        width: inherit;
      }
  }


`;

const NormalText = styled.div`
    line-height: 2;
    opacity: .5;
`;


const ForSale = styled.div`
  margin-top: 3rem;

  ${bp.tablet} {
    margin-top: 0;
  }
  span {
    display: block;
  }
`;

const ForSaleLabel = styled(Sans)`
  color: ${sc.colors.white};
  font-size: 14px;
  text-transform: uppercase;

  ${bp.smallNotebook} {
    margin-top: 1rem;
  }
`;

const Footer = styled(Cta)`
  margin: 30px 0 0;
  position: relative;

  > * {
    flex: 0 1 auto;
  }

  flex-direction: column;
  align-items: flex-start;

  ${bp.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  flex-wrap: wrap;

  ${bp.smallNotebook} {

  }
`;

const SmallGap = styled.div`
  height: 1rem;
`;


const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #F5F5F5;
  margin-top: 3rem;
`;

const GraySmalltext = styled.div`
  font-size: 18px;
  color: #fff;
  opacity: 0.5
`;

const FlexContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column; 
  margin-bottom: 3rem;
  width: 100%;

  ${bp.tablet} {
    flex-direction: row; 
    margin-bottom: 3rem;
  }
  ${bp.bigNotebook} {
    flex-direction: row; 
    margin-bottom: 0rem;
    width: auto;
  }
`;





const CallUsCta = styled.a`
  display: flex;
  background: url(${callEnquiry}) left bottom no-repeat;
  align-items: center;

  small * {
    font-size: 14px;
  }
  strong {
    display: block;
    margin-bottom: 6px;
  }
  span {
    transition: 0.25s ease;
  }

  margin-bottom: 50px;

  ${bp.tablet} {
    margin-bottom: 0;
  }
`;


const CtaButton = styled(Button)`
  max-width: 100%;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  width: 100%;

  ${bp.tablet} {
    max-width: 90%;
    /* margin-top: unset !important;
    margin-bottom: unset !important; */
  }
  ${bp.computer} {
    /*margin-top: unset !important;*/
    margin-bottom: unset !important;
    max-width: 100%;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
`;

  const ctaClick = () => {
      navigate(`stamp-duty-calculator`);
  }


export class DevelopmentsHeader extends PureComponent {
  static propTypes = {
    photoList: PT.arrayOf(PT.string),
  };

  sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',
  };


  render() {
    const { photoList } = this.props;
    const { description } = this.props;
    let { contact } = this.props;
    if (contact.brochure ==  ""){
      contact.brochure = 'mailto:'+contact.contactEmail+'?&subject=Brochure Request - '+description.title+'&body=Hello,%0D%0A I am interested in the development: '+description.title+' '+description.address+'%0D%0A Please can you send me a brochure with more information.%0D%0A Thank you';
    }

    return (
     <React.Fragment>
      <StyledSlick {...this.sliderSettings}>
        {photoList.map(photoUrl => (
          <Slide key={photoUrl}>
            <CoverImage source={photoUrl} />
          </Slide>
        ))}
      </StyledSlick>

      <Container>
        <InnerContainer>
          <Grid>
            <Spacer />
            <StyledColumn width={10}>
              
              <FlexContent>
                <TextWrapperBigger>
                  <Serif gamma color="white">
                    {description.title}
                  </Serif>
                  <SmallGap></SmallGap>
                  <GraySmalltext>
                    {description.address}
                  </GraySmalltext>
                </TextWrapperBigger>
                <TextWrapperNogap>
                  
                  <ForSale>
                    <ForSaleLabel lineHeight="1">Starting prices</ForSaleLabel>
                    <Price lineHeight="1.4" weight="medium">
                      {formatPrice(description.starting_price)}
                    </Price>
                  </ForSale>
                  
                </TextWrapperNogap>
              </FlexContent>
              
            <HighlightsContainer>

             <TopLevelWrapper className="rightImage">
               <div>
                   <Heading>
                    <Serif beta color="main" lineHeight="1.66" block>
                      {contact.contactTitle}
                    </Serif>
                    <NormalText>
                      {contact.contactDescription}
                    </NormalText>
                    


                    
                  </Heading>
                
                  <DefaultWrapper>
                    <Footer spaceBetween>
              
                     <CallUsCta
                        href={`tel:+44${contact.contactTel.replace(/\s/g, '')}`}
                        title="Make an enquiry"
                      >
                        <PersonThumb bgimage={contact.contactImg}>
                        </PersonThumb>
                        <div>
                          <strong>
                            <Sans
                              epsilon
                              color="main"
                              weight="bold"
                              lineHeight="1.2"
                            >
                            {contact.contactTel}
                            </Sans>
                          </strong>
                          <small>
                            <Sans color="main" lineHeight="1.3" >
                              {contact.contactEmail}
                            </Sans>
                          </small>
                        </div>
                      </CallUsCta>

                      <ShareLink >
                        <Sans alpha color="main">
                          Share
                        </Sans>
                      </ShareLink>
                      <SocialShareBox className="shareBox">
                        <Column column width={4}>
                          <Row>
                            <SocialMediaIcon
                              name="facebook"
                              href={ "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent("https://www.ernest-brooks.com/developments/"+contact.shareid) }

                              title="Facebook"
                            />

                            <SocialMediaIcon
                              name="twitter"
                             href={ "https://twitter.com/intent/tweet?text="+description.title+" "+description.address+"&url=https://www.ernest-brooks.com/developments/"+contact.shareid }
                              title="Twitter"
                            />
                          </Row>
                        </Column>
                      </SocialShareBox>

                      

                    </Footer>
                    </DefaultWrapper>

                    <Divider />

                    <DefaultWrapper className="fullWidthButtons">
                      <a href={contact.brochure}  className="left-btn">
                      <CtaButton alt compact className="left-btn">
                        Brochure
                      </CtaButton>
                      </a>
                      <CtaButton compact borderwhite className="right-btn" onClick={ctaClick}>
                       Stamp Duty
                      </CtaButton>
                     </DefaultWrapper>
                  
                </div>
              </TopLevelWrapper>

            </HighlightsContainer>

            </StyledColumn>
          </Grid>
        </InnerContainer>
      </Container>
     </React.Fragment>

    );
  }
}

export default DevelopmentsHeader;
