import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  PulsingButton,
  Media, 
} from 'components/ui';
import { PulseImage } from 'components/blocks';
import { Script, Sans, Serif } from 'Typography';
import sc from 'config/theme';
import $ from 'jquery';

import { ViewportEnterAnimation } from 'components/ui';
import { functionAboutDescription } from 'motion/about.js';
import { indexScrollState} from 'motion/index.js';
import bp from 'config/responsive';

indexScrollState();

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 0px;
  padding-bottom: 100px;

  ${bp.computer} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .aboutDescription {
    ${bp.computer} {
      opacity: 0;
    }

    & > div {
      padding-left: 25px !important;
      padding-right: 25px !important;

      ${bp.computer} {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }

  .aboutDescriptionText {
    padding-left: 15px;
    padding-right: 15px;

    ${bp.computer} {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const Paragraph = styled.p`
  display: block;

`;

const StyledGrid = styled(Grid)`
  &&& {
    padding-top: 50px;
    margin-bottom: 30px;

    ${bp.tablet} {
      margin-bottom: 100px;
    }

    ${bp.computer} {
      padding-top: 0;
      margin-bottom: 100px;
    }
  }
`;

const CallToActionContainer = styled.div`
  padding-top: 60px;
`;

const StyledScript = styled(Script)`
  display: block;
  margin-bottom: 20px;
`;

const StyledSerif = styled(Serif)`
  display: block;
  margin-bottom: 40px;
`;

export class AboutDescription extends PureComponent {
  clickCta = (event) => {
    const $ourValues = $('#about-our-values');
    event.preventDefault();

    if ($ourValues.length) {
      $('html,body').animate(
        {
          scrollTop: $ourValues.offset().top,
        },
        1000
      );
    }
  }

  renderComponent = (data) => {
    const { content } = this.props;
    const { description } = content;

    const  img = {src:description.img, height:616, width: 616};


    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <InnerContainer>
          <ViewportEnterAnimation offset={100} animation={functionAboutDescription}>
            <StyledGrid className="aboutDescription">
              <Spacer />
              <Column width={4}>
                <div>
                  <Serif alpha color="main">
                    {description.usp}
                  </Serif>
                </div>
              </Column>
              <Spacer />
              <Column width={5}>
                <Sans alpha color="gray">
                  <Paragraph>{description.description1}</Paragraph>
                  <Paragraph>{description.description2}</Paragraph>
                </Sans>
              </Column>
              <Spacer />
            </StyledGrid>
            </ViewportEnterAnimation>
            <PulseImage fixedImg={img}>
              <CallToActionContainer  className="aboutDescriptionText">
                <StyledScript color="main" beta>
                  {description.ethicsTitle}
                </StyledScript>
                <div>
                  <Sans alpha color="gray">
                  <Paragraph>{description.ethicsBody}</Paragraph>
                </Sans>
                  <PulsingButton onClick={this.clickCta}>{description.ethicsBtn}</PulsingButton>
                </div>
              </CallToActionContainer>
            </PulseImage>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container>
          <InnerContainer>
          <ViewportEnterAnimation offset={100} animation={functionAboutDescription}>
            <StyledGrid className="aboutDescription">
              <Column width={12}>
                <div>
                  <Serif alpha color="main">
                    {description.usp}
                  </Serif>
                </div>
              </Column>
              <Column width={12}>
                <Sans alpha color="gray">
                  <Paragraph>{description.description1}</Paragraph>
                  <Paragraph>{description.description2}</Paragraph>
                </Sans>
              </Column>
            </StyledGrid>
            </ViewportEnterAnimation>
            <PulseImage fixedImg={img}>
              <CallToActionContainer  className="aboutDescriptionText">
                <StyledScript color="main" beta>
                  {description.ethicsTitle}
                </StyledScript>
                <div>
                <Sans alpha color="gray">
                  <Paragraph>{description.ethicsBody}</Paragraph>
                </Sans>
                  <PulsingButton onClick={this.clickCta}>{description.ethicsBtn}</PulsingButton>
                </div>
              </CallToActionContainer>
            </PulseImage>
          </InnerContainer>
        </Container>
      )} />
    );
  }

  render() {
    return (
        this.renderComponent()
    );
  }
}

export default AboutDescription;
