import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { InnerContainer, PulsingButton } from 'components/ui';
import { PulseImage } from 'components/blocks';
import { Sans, Serif } from 'Typography';
import sc from 'config/theme';
import bp from 'config/responsive';

const Container = styled.div`
  background: ${sc.colors.white};
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;

  ${bp.tablet} {
    padding-left: 25px;
    padding-right: 25px;
  }
  ${bp.computer} {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 0;
    padding-right: 0;
  }

.buyLandingInfo {
  ${bp.computer} {
    opacity: 0;
  }
}
  .title {
    margin-bottom: 30px;
  }
`;

const Paragraph = styled.p`
  display: block;
  line-height: inherit;
`;

export class BuyLandingInfo extends PureComponent {

  handleButtonClick = () => {
    console.log('clickedthe button')
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }


  static propTypes = {
    title: PT.string,
    text: PT.string,
    buttonText: PT.string,
    buttonAction: PT.func,
  };

  static defaultProps = {
    title: '',
    text: '',
    buttonText: '',
  };

  renderComponent = data => {
    const { buttonAction, content } = this.props;

    const  img = {src:content.img, height:616, width: 616};



    return (
      <Container>
        <InnerContainer className="buyLandingInfo">
          <PulseImage fixedImg={img} imageOnRight  className="pulseImage" alt={content.imgalt}>
            <Serif color="main" alpha className="title" block>
              {content.title}
            </Serif>
            <div>
              <Sans alpha color="gray" block>
                <Paragraph>{content.paragraph1}</Paragraph>
                <Paragraph>{content.paragraph2}</Paragraph>
                <Paragraph>{content.paragraph3}</Paragraph>
              </Sans>
              {content.callToAction && (
                <PulsingButton fullWidth onClick={this.handleButtonClick}>
                  {content.callToAction}
                </PulsingButton>
              )}
            </div>
          </PulseImage>
        </InnerContainer>
      </Container>
    );
  };

  render() {
    return (

        this.renderComponent()
     
    );
  }
}

export default BuyLandingInfo;
