import React, { PureComponent } from 'react';
import styled , { css }from 'styled-components';
import PT from 'prop-types';
import {
  LightboxWrapper,
  SlideReveal,
  List,
  Button,
  AnimatedGrid,
  HighlightButton,
  Column,
  SocialMediaIcon,
} from 'components/ui';
import { fonts, Sans, Serif } from 'Typography';

import sc from 'config/theme';
import formatPrice from 'utils/formatPrice';
import { CloseButton } from 'components/ui';

import bed from 'images/white-bed.svg';
import bath from 'images/white-bath.svg';
import couch from 'images/white-couch.svg';
import parking from 'images/white-parking.svg';
import callEnquiry from 'images/call-enquiry-icon.svg';
import shareIcon from 'images/share-icon.svg';
import floorPlan from 'images/floorplan.svg';
import mortgage from 'images/mortgage.svg';

import brochure from 'images/brochure.svg';
import stampDuty from 'images/stamp-duty.svg';
import bp from 'config/responsive';
import $ from 'jquery';



const Container = styled.div`
  display: block;
  background: ${sc.colors.main};
  padding: 50px 0 40px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 60px;


`;
const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(255,255,255,0.95);
  top: 0;
  left: 0;
  z-index: 999;



`;

const PaddedRow = styled.div`
  margin-top: 35px;
`;


const Modal = styled.div`
    width: 400px;
    height: 260px;
    background: white;
    box-shadow: 0 0 30px rgba(0,0,0,0.2);
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    padding: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: #1a2f59;
    flex-direction: column;

    span {
      float: right;
      cursor:pointer;
    }
    p{
      margin-top: 15px;
      font-size: 18px;
    }
    & .pdfIcon {
      padding: 20px;
      background: #335eb3;
      border-radius: 100px;
      width: 110px;
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        opacity: 0.4;
      }
    }
`;

export const RibbonLabel = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  white-space: nowrap;
  color: white;

  div {
    transform: rotateZ(45deg) translateX(30%) translateY(-75%);
    width: 260px;
    padding: 10px 0 12px;
    text-align: center;
    border: 1px solid rgba(${sc.colors.white_rgb}, 0.3);
    font-family: ${fonts.sans};
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.57px;
    line-height: 1em;
    text-transform: uppercase;
     ${({ colour }) =>
      colour == 'ribbonBlue' &&
      css`
        background: ${sc.colors.yellow};
      `}

    ${({ colour }) =>
      colour == 'ribbonWhite' &&
      css`
        background: ${sc.colors.white};
        color: ${sc.colors.main};
      `}

    ${({ colour }) =>
      colour == 'ribbonYellow' &&
      css`
        background: ${sc.colors.yellow};
      `}

    ${({ colour }) =>
      colour == 'ribbonRed' &&
      css`
        background: ${sc.colors.red};
      `}


    ${bp.mobile} {
      transform: rotateZ(45deg) translateX(30%) translateY(-125%);
    }
  }
`;

const TopLevelWrapper = styled.div`
  position: relative;

  & .hideonMobile {
    visibility: visible;

    ${bp.mobile} {
      visibility: hidden;
    }
  }
`;

const DefaultWrapper = styled.div`
  padding: 0 25px;

  ${bp.computer} {
    padding: 0 35px;

  }
`;

const Heading = styled(DefaultWrapper)`
  ${Serif}:first-child {
    margin-bottom: 32px;
    padding-right: 60px;

  }
`;

const Spacer = styled.div`
height:30px;
`;

const Cta = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};

  ${({ spaceBetween }) => spaceBetween && 'justify-content: space-between;'}

  > * {
    flex: ${({ compact }) => (compact ? 0 : 1)} 1
      ${({ elements }) => (elements ? `${100 / elements}%` : 'auto')};
      width: 100%;

      ${bp.computer} {
        width: inherit;
      }
  }


`;

const ForSale = styled.div`
  span {
    display: block;
  }
`;

const ForSaleLabel = styled(Sans)`
  color: ${sc.colors.white};
  font-size: 14px;
  text-transform: uppercase;
`;

const Price = styled(Sans)`
  color: ${sc.colors.yellow};
  font-size: 30px;
`;

const RoomList = styled.div`
  display: flex;
  align-items: center;
  margin: 30px 0 42px;
  flex-direction: row;

  ${bp.computer} {
    display: flex;
    margin: 68px 0 42px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin-right: 60px;
  color: ${sc.colors.main};
  ${bp.mobile} {
  margin-right: 20px;
  }
`;

const Icon = styled.img`
  width: ${({ width }) => width}px;
  margin: 0;
  margin-right: 16px;
  align-self: center;
  ${bp.mobile} {
  width:50%;
  height:30px
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid #293856;
`;

const KeyFeaturesButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 87px;
  padding: 0 35px;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  transition: 0.25s ease;

  display: none !important;

  &:hover {
    background: #293856;
  }

`;

const DownloadLink = styled.a`
  display: inline-block;
  width: auto;
  margin: 49px 0 39px;
  text-align: center;

  img {
    display: block;
    height: 58px;
    margin: 0 auto 15px;
    opacity: 0.25;
    transition: 0.25s ease;
  }

  &:hover img {
    opacity: 0.75;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  flex-wrap: wrap;

  ${bp.smallNotebook} {

  }
`;

const CallUsCta = styled.a`
  display: block;
  background: url(${callEnquiry}) left bottom
    no-repeat;
  padding-left: 70px;

  small * {
    font-size: 14px;
  }
  strong {
    display: block;
    margin-bottom: 6px;
  }
  span {
    transition: 0.25s ease;
  }

  &:hover span {
    color: rgba(${sc.colors.white_rgb}, 0.4);
  }

  margin-bottom: 50px;

  ${bp.tablet} {
    margin-bottom: 0;
  }
`;

const SocialShareBox = styled.div`
  position: absolute;
    background: rgb(11, 29, 63);
    width: 85%;
    height: auto;
    right: 0px;
    left: 0;
    margin: auto;
    border-radius: 5px;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: .4s ease;
    opacity: 0;
    visibility: hidden;
    transform: scale(1.05);
    will-change: transform, opacity;

    ${bp.tablet} {
      width: auto;
      right: 30px;
      left: inherit;
    }

  a, a * {
    color: #fff !important;
    background: transparent !important;
  }

  a:last-child {
    margin-right: 0 !important;
  }

  i {
    font-size: 28px !important;
    line-height: 28px !important;
  }

  &:hover {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }


`

const ShareLink = styled.button`
  display: inline-flex;
  width: auto;
  flex-grow: 0;
  flex-basis: 0;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  border: none;
  appearance: none;
  cursor: pointer;
  outline: none;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 27px;
    height: 30px;
    background: url(${shareIcon}) center no-repeat;
    margin-right: 15px;
  }

  &:hover ~ .shareBox {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
  ${bp.computer} {
    justify-content: center;
  }
`;

const Footer = styled(Cta)`
  margin: 30px 0 0;

  > * {
    flex: 0 1 auto;
  }

  flex-direction: column;
  align-items: flex-start;

  ${bp.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const AnimatedGridWrapper = styled.div`
  z-index: 0;
  position: absolute;
  bottom: -90px;
  right: -70px;
`;

const ArrangeViewing = styled(Button)`
  max-width: 100%;
  margin-top: 20px !important;
  margin-bottom: 35px !important;

  ${bp.tablet} {
    max-width: 50%;
    /* margin-top: unset !important;
    margin-bottom: unset !important; */
  }
  ${bp.computer} {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
`;
const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;

    & button {
      &:before {
        top: -8px !important;
      }
    }
`;



export const KeyFeatures = ({ keyFeatures, isKeyFeaturesOpen, ...props }) => (
  <React.Fragment>
    <KeyFeaturesButton {...props}>
      <Sans beta uppercase color="white">
        Key Features
      </Sans>
      <HighlightButton icon="plus" />

    </KeyFeaturesButton>
    <SlideReveal isOpen={isKeyFeaturesOpen}>
      <DefaultWrapper>
        <List alt>
          {keyFeatures.map(feature => (
            <List.Item>{feature}</List.Item>
          ))}
        </List>
      </DefaultWrapper>
    </SlideReveal>
    {/*<Divider />*/}
  </React.Fragment>
);

export class DevelopmentDetailHighlights extends PureComponent {

  handleButtonClick = () => {
    console.log('clickedthe button')
    if (typeof window !== 'undefined') {
      window.openPropertyEnquiry && window.openPropertyEnquiry();
    }
  }


  static propTypes = {
    title: PT.string,
    ribbon: PT.array,
    offerType: PT.oneOf(['sale', 'rent', 'new-home']),
    price: PT.number.isRequired,
    rooms: PT.shape({
      bedrooms: PT.number,
      bathrooms: PT.number,
      livingrooms: PT.number,
    }),
    keyFeatures: PT.arrayOf(PT.string),
    downloads: PT.shape({
      floorplan: PT.string,
      brochure: PT.string,
      stampDuty: PT.string,
    }),
    ctaPhoneNumber: PT.string.isRequired,
    toggleStampDutyModal: PT.func,
  };

  state = {
    isKeyFeaturesOpen: false,
    modal: false,
  };

  toggleKeyFeatures = () =>{

    this.setState(({ isKeyFeaturesOpen }) => ({
      isKeyFeaturesOpen: !isKeyFeaturesOpen,
    }));


  }

    handleLink = (event) =>{
      event.preventDefault();
      this.setState({ modal: true });
    }
    handleModalClose = () =>{
      this.setState({ modal: false });
    }


  scrolltofeatures = (e) => {

    var postion = $('.development_content').offset().top;
    postion = postion - 100;
     $('html, body').animate({
        scrollTop: postion 
    }, 500);
  }


  render() {
    const { isKeyFeaturesOpen , modal} = this.state;
    const {
      id,
      title,
      type,
      pricePerWeek,
      price,
      rooms,
      keyFeatures,
      downloads,
      ctaPhoneNumber,
      toggleStampDutyModal,
      content,
      mainInfo,
      short_description
    } = this.props;
    const isAnyRoomDefined = rooms;
    const isAnyDownloadDefined = downloads;

   
   

    const modaltext = "File not available.";

    return (

      <TopLevelWrapper className="rightImage">

       {modal &&
         <ModalWrapper>
            <Modal>
              <CloseButtonWrapper>
                <CloseButton onClick={() => this.handleModalClose()} />
              </CloseButtonWrapper>
              <div className="pdfIcon">
                <img src={brochure} alt="Brochure" />
              </div>
              <p>{modaltext}</p>
            </Modal>
          </ModalWrapper>
        }

        <AnimatedGridWrapper className="hideonMobile">
          <AnimatedGrid />
        </AnimatedGridWrapper>
        <Container>

          <RibbonLabel colour="ribbonWhite" >
              <div>NEW HOMES</div>
          </RibbonLabel>


          <Heading>

          <PaddedRow>
          </PaddedRow>


            <Serif beta color="white" lineHeight="1.66" block>
              {title}
            </Serif>

          <PaddedRow>
          </PaddedRow>

            <Cta wrap>
              <ForSale>
                <ForSaleLabel lineHeight="1">Starting Prices</ForSaleLabel>
                <Price lineHeight="1.4" weight="medium">
                  {formatPrice(price)}
                </Price>
              </ForSale>
              <ArrangeViewing alt compact onClick={this.handleButtonClick} >
                Arrange a viewing
              </ArrangeViewing>
            </Cta>

     
          
          </Heading>
                   
           <PaddedRow>
          </PaddedRow>
          <Divider />

          {keyFeatures && (
            <KeyFeatures
              isKeyFeaturesOpen={isKeyFeaturesOpen}
              onClick={this.toggleKeyFeatures}
              keyFeatures={keyFeatures}
            />
          )}



            <React.Fragment>
              <DefaultWrapper>
                <Cta compact elements={3}>

                  <DownloadLink className='triggerfloorplan' onClick={(e) => this.scrolltofeatures(e)}>
                        <img src={floorPlan} alt="Overview" />
                        <Sans delta color="white" uppercase>
                          Overview
                        </Sans>
                      </DownloadLink>
              
        
                    <DownloadLink target='_bank' href='https://link.smartrbuyer.com/ac/aaa/vEsDyA9F6mb'>
                      <img src={mortgage} alt="Mortgage" />
                      <Sans delta color="white" uppercase>
                        Mortgage
                      </Sans>
                 
                    </DownloadLink>

                    <DownloadLink
                      //onClick={toggleStampDutyModal}
                      href='https://www.ernest-brooks.com/stamp-duty-calculator/'
                      title="Stamp Duty calculator"
                    >
                      <img src={stampDuty} alt="Stamp duty" />
                      <Sans delta color="white" uppercase>
                        Stamp duty
                      </Sans>
                    </DownloadLink>
                </Cta>
              </DefaultWrapper>
              <Divider />
            </React.Fragment>
             <DefaultWrapper>
                <PaddedRow>
                <Sans delta color="white"  dangerouslySetInnerHTML={{__html: short_description}} />
                 
                </PaddedRow>

                <PaddedRow>
                </PaddedRow>

             </DefaultWrapper>
               <Divider />
        

          <DefaultWrapper>
            <Footer spaceBetween>
              <CallUsCta
                href={`tel:+44${ctaPhoneNumber.replace(/\s/g, '')}`}
                title="Make an enquiry"
              >
                <small>
                  <Sans color="white" lineHeight="1.3" uppercase>
                    Make an enquiry
                  </Sans>
                </small>
                <strong>
                  <Sans
                    epsilon
                    color="white"
                    weight="bold"
                    lineHeight="1.2"
                  >
                    {ctaPhoneNumber}
                  </Sans>
                </strong>
              </CallUsCta>
              <ShareLink >
                <Sans alpha color="white">
                  Share
                </Sans>
              </ShareLink>
              <SocialShareBox className="shareBox">
                <Column column width={4}>
                  <Row>
                    <SocialMediaIcon
                      name="facebook"
                      href={ "https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent("https://www.ernest-brooks.com/single-property/?id="+id) }
                      title="Facebook"
                    />

                    <SocialMediaIcon
                      name="twitter"
                      href={ "https://twitter.com/intent/tweet?text="+title+"&url=https://www.ernest-brooks.com/single-property/?id="+id}
                      title="Twitter"
                    />
                  </Row>
                </Column>
              </SocialShareBox>
            </Footer>
          </DefaultWrapper>
        </Container>
      </TopLevelWrapper>
    );
  }
}

export default DevelopmentDetailHighlights;
