import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { Button, Script, Sans } from 'components/ui';
import sc from 'config/theme';
import bp from 'config/responsive';

import resultsBanner from 'images/results-banner.png';
import iconEmail from 'images/icon-email.svg';
import iconShare from 'images/icon-share.svg';

import { FormContext } from 'context/propertySearch';


const Container = styled.div`
  padding-top: 44px;
  margin-bottom: 44px;

  ${bp.mobile} {
    padding-top: 42px;
    margin-bottom: 28px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsContainer = styled.div`
  color: ${sc.colors.gray};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Content = styled.div`
  background: white;
  border-radius: ${sc.sizes.borderRadius}px;
  margin-top: 66px;
  padding: 0 100px;
  padding-top: 56px;
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  ${bp.mobile} {
    margin-top: 37px;
    padding: 0 24px;
    padding-top: 42px;
    padding-bottom: 84px;
  }
`;

const StyledHeader = styled(Sans)`
  margin-bottom: 23px;

  ${bp.mobile} {
    margin-bottom: 46px;
  }
`;

const BannerImg = styled.img`
  width: 100px;
  margin-bottom: 0;
  position: absolute;
  left: 0;

  ${bp.mobile} {
    bottom: 25px;
    width: 131px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const IconLink = styled.a`
  display: inline-block;
  margin-left: 16px;
  width: 20px;
  height: 20px;
  background: transparent url(${({ icon }) => icon}) center no-repeat;
  background-size: auto;
  cursor: pointer;
  transition: opacity .25s;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;

export class SearchResultsBanner extends PureComponent {


  render() {

   const { handleSignup, content } = this.props;

    return (
      <FormContext.Consumer>
        {({
          form,
          handleChange,
          handleSearch,
          handleSemanticChange,
          handleRangeChange,
          toggleSort
        }) => {
          return (
                <Container>
                  <Row>
                    <Script color="main" delta>
                      Results
                    </Script>

                    <IconsContainer>
                      <IconLink title="Sign Up to Property Alerts" icon={iconEmail}  onClick={handleSignup}/>
                      <IconLink title="Sort by Price" icon={iconShare}  onClick={toggleSort}/>
                    </IconsContainer>
                  </Row>
                  {/* <Content>
                    <BannerImg src={resultsBanner} alt="results banner" />
                    <ContentWrapper>
                      <StyledHeader align="center" color="main" zeta>
                        {content.title}
                      </StyledHeader>
                      <Button onClick={handleSignup} main>
                        {content.btn}
                      </Button>
                    </ContentWrapper>
                  </Content> */}
                </Container>
             );
        }}
      </FormContext.Consumer>
      );
    }






}

export default SearchResultsBanner;
