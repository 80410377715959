import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Script, Sans } from 'Typography';
import { LightboxWrapper, Grid, Spacer, Column, InnerContainer, Button } from 'components/ui';
import sc from 'config/theme';
import formatPrice from 'utils/formatPrice';

import bedIcon from 'images/bedrooms-icon.svg';


import { ViewportEnterAnimation } from 'components/ui';
import { functionSectionLoad2} from 'motion/single-property.js';
import bp from 'config/responsive';


const Container = styled.div`
  background: ${sc.colors.main};
  padding: 60px 0;

  ${bp.smallNotebook} {
    padding: 100px 0 160px;
  } 

  .sectionLoad2Inner {
    opacity: 0;
  }
  .mobileButton {
    display: block !important;

    ${bp.computer} {
      display: none !important;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 45px;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
`;

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 230px;
  margin: 0 0 22px;
  background: white;
  border-radius: 7px;

  ${bp.smallNotebook} {
    min-height: 178px;
    width: calc(50% - 16px);
  } 
`;

const CardImage = styled.div`
  flex: 0 0 95px;
  width: 95px;
  height: 100%;
  background: url(${({ thumbnail }) => thumbnail}) no-repeat center;
  background-size: contain;
  margin-right: 10px;
  cursor: pointer;

  ${bp.tablet} {
    flex: 0 0 200px;
    width: 200px;
    margin-right: 40px;
  } 

  ${bp.computer} {
    flex: 0 0 178px;
    width: 178px;
    margin-right: 34px;
  } 

`;

const CardContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  padding: 20px 0;
  padding-right: 20px;
`;

const CardDetails = styled.div`
  display: none;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 0;
  padding-right: 32px;

  ${bp.computer} {
    display: flex;
  }
`;

const Price = styled.div``;

const Address = styled.div``;

const Bedrooms = styled.div``;

const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
`;

const SizeLabel = styled.div``;

const SizeNumber = styled.div``;

const Size = styled.div`
  color: ${sc.colors.main};
  font-size: 23px;
  font-weight: 600;
  display: inline-block;
`;

const SizeSmall = styled.div`
  color: ${sc.colors.main};
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  margin-left: 6px;
`;

export class SinglePropertyPlots extends PureComponent {
  render() {
    const { title, buttonText, sizeLabel, approx, sizeUnit, data } = this.props;

    return (
      <ViewportEnterAnimation offset={100} animation={functionSectionLoad2}>
        <Container className="sectionLoad2">
          <InnerContainer className="sectionLoad2Inner">
            <Grid>
              <Spacer />
              <Column width={10}>
                <Title>
                  <Script delta color="yellow">
                    {title}
                  </Script>
                </Title>
                <CardsWrapper>
                  {data.map(card => (
                    <CardWrapper>
                      <LightboxWrapper img={card.photoUrl}>
                        <CardImage
                          thumbnail={card.thumbnailUrl}
                        />
                      </LightboxWrapper>
                      <CardContent>
                        <Price>
                          <Sans alpha color="main" weight="bold">
                            {formatPrice(card.price)}
                          </Sans>
                        </Price>
                        <Address>
                          <Sans delta color="gray">
                            {card.address}
                          </Sans>
                        </Address>
                        <Bedrooms>
                          <Icon src={bedIcon} alt="Bedrooms" />
                          <Sans gamma color="main">
                            {card.bedrooms}
                          </Sans>
                        </Bedrooms>
                        <Button fluid main className="mobileButton">
                          {buttonText}
                        </Button>
                      </CardContent>
                      <CardDetails>
                        <SizeLabel>
                          <Size>{sizeLabel}</Size>
                          <SizeSmall>{approx}</SizeSmall>
                        </SizeLabel>
                        <SizeNumber>
                          <Sans color="gray" zeta>{Number(card.size).toLocaleString()} {sizeUnit}</Sans>
                        </SizeNumber>
                        <Button fluid main>
                          {buttonText}
                        </Button>
                      </CardDetails>
                    </CardWrapper>
                  ))}
                </CardsWrapper>
              </Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      </ViewportEnterAnimation>
    );
  }
}