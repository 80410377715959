import { TweenLite, TimelineLite, TimelineMax, Back } from 'gsap';
//This i need for a plane animation: MorphSVGPlugin

import $ from 'jquery';

// Placeholder for production builds only
let SplitText = function() {};

// Load SplitText from gatsby-browser.js (referenced on window)
if (typeof window !== 'undefined') {
  

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    SplitText = window.SplitText;
  }
}

// Scroll States
export function indexScrollState() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    window.addEventListener('scroll', () => {
      requestAnimationFrame(scrollMotion);
    });
  
    function scrollMotion() {
      const scrollTop = $(window).scrollTop();
  
      const $parallax1 = $('.parallax-image-1');
      if ($parallax1) {
        $parallax1.css('transform', `translate3d(0, ${scrollTop / 30 * 1.5}px, 0)`);
      }
      const $parallax2 = $('.parallax-image-2');
      if ($parallax2) {
        $parallax2.css('transform', `translate3d(0, -${scrollTop / 20 * 1}px, 0)`);
      }
    }
  }

  
}


export default function aboutPageMotion() {
  // Condition to not perform ANY animations when building for production (just when opened in browser)
  if (typeof document !== 'undefined') {
    setTimeout(() => {

      let windowWidth = $(window).width();

    if(windowWidth < 992) {
      
      var aboutAnimations = new TimelineLite();

      aboutAnimations
      .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
      .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")


    } else {
      var point = $('#dotGrid .scale').toArray();
      var pointAnimations = new TimelineMax({repeat: -1});
      point.sort(function(){return 0.5-Math.random()});
      pointAnimations
        .staggerFromTo(point, 0.8, {autoAlpha: 0, scale: 1, ease: Back.easeOut}, {autoAlpha: 1, scale: 1, ease: Back.easeOut}, 0.25)
        .staggerTo(point, 0.8, {autoAlpha: 0, scale: 1, ease: Back.easeOut}, 0.25)

      var aboutAnimations = new TimelineLite(),
        mySplitText = new SplitText(".title", { type: "words,chars" }),
        chars = mySplitText.chars;

      TweenLite.set(".title", { perspective: 400 });

      aboutAnimations
      .to('.loadingOverlay', 0.4, {autoAlpha: 0}, "-=0")
      .set('.grid', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .from('.headerDescription', 0.8, {opacity: 0, y: 10, ease: Back.easeOut }, "-=0")
      .from('.rightImage', 0.8, {opacity: 0, scale: 0.95, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.4")
      .from('.rightImage svg', 0.8, {opacity: 0, y: -10, ease: Back.easeOut }, "-=0")
      .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")
    }

      
      
    }, 0);
  }
}



//Scroll Animations 
export function functionAboutContact() {


  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var aboutContactCta = new TimelineLite({ delay: 0.5 }),
    mySplitText = new SplitText(".aboutContactSectionTitle", { type: "words,chars" }),
    chars = mySplitText.chars;
  
    TweenLite.set(".aboutContactSectionTitle", { perspective: 400 });
  
    aboutContactCta
      .set('.aboutContactSectionColContent', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .staggerFrom(".aboutContactSection button, .aboutContactSection .aboutContactSectionDescription", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}



export function functionSliderAbout() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.aboutSlider', {opacity: 1})
      .staggerFrom(".aboutSlider .grid", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}
export function functionWhiteBox1() {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .staggerFrom(".animateBox", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }
}
export function functionAboutAccordion() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.aboutOptions', {opacity: 1})
      .staggerFrom(".aboutOptions .aboutOptionsInner", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}

export function functionAboutDescription() {

  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.aboutDescription', {opacity: 1})
      .staggerFrom(".aboutDescription > *", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}


export function functionAboutValues() {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .set('.aboutValues', {opacity: 1})
      .staggerFrom(".aboutValuesCards > *, .aboutValuesTitle", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }
  
}