import { TweenLite, TimelineLite, Back, TimelineMax, Power4, Power0, Linear/*, Elastic */  } from 'gsap';
//This i need for a plane animation: MorphSVGPlugin

import $ from 'jquery';

// Placeholder for production builds only
let SplitText = function() {};
let MorphSVGPlugin = function() {};

// Load SplitText from gatsby-browser.js (referenced on window)
if (typeof window !== 'undefined') {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {
    MorphSVGPlugin = window.MorphSVGPlugin;
  } else {
    SplitText = window.SplitText;
    MorphSVGPlugin = window.MorphSVGPlugin;
  }


}

// Scroll States
export function indexScrollState() {
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', () => {
      requestAnimationFrame(scrollMotion);
    });

    function scrollMotion() {
      const scrollTop = $(window).scrollTop();

      const $parallax1 = $('.parallax-image-1');
      if ($parallax1) {
        $parallax1.css('transform', `translate3d(0, ${scrollTop / 30 * 1.5}px, 0)`);
      }
      const $parallax2 = $('.parallax-image-2');
      if ($parallax2) {
        $parallax2.css('transform', `translate3d(0, -${scrollTop / 20 * 1}px, 0)`);
      }
      const $parallax3 = $('.parallax-image-3');
      if ($parallax3) {
        $parallax3.css('transform', `translate3d(0, ${scrollTop / 10 * 0.5}px, 0)`);
      }
      const $parallax4 = $('.parallax-image-4');
      if ($parallax4) {
        $parallax4.css('transform', `translate3d(0, -${scrollTop / 20 * 1}px, 0)`);
      }
      const $parallax5 = $('.parallax-image-5');
      if ($parallax5) {
        $parallax5.css('transform', `translate3d(0, -${scrollTop / 15 * 1.3}px, 0)`);
      }
      const $parallax6 = $('.parallax-image-6');
      if ($parallax6) {
        $parallax6.css('transform', `translate3d(0, -${scrollTop / 80 * 1}px, 0)`);
      }
    }
  }
}

//Static Animations Homepage


export function indexPageMotion() {
  
  // Condition to not perform ANY animations when building for production (just when opened in browser)
  if (typeof document !== 'undefined') {

    setTimeout(() => {


    let windowWidth = $(window).width();

    if(windowWidth < 992) {

      var indexAnimations = new TimelineLite({ delay: 1 }),
      mySplitText = new SplitText(".title", { type: "words,chars" }),
      chars = mySplitText.chars;

      TweenLite.set(".title", { perspective: 400 });

      indexAnimations
      .to('.loadingOverlay', 0.1, {autoAlpha: 0}, "-=0")
      .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")

    } else {
      
      var indexAnimations = new TimelineLite({ delay: 1 }),
      mySplitText = new SplitText(".title", { type: "words,chars" }),
      chars = mySplitText.chars;

      TweenLite.set(".title", { perspective: 400 });

      indexAnimations
      .to('.loadingOverlay', 0.1, {autoAlpha: 0}, "-=0")
      .set('.grid', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .from('.headerDescription', 0.8, {opacity: 0, y: 10, ease: Back.easeOut }, "-=0")
      .from('.rows', 0.8, {opacity: 0, y: 10, ease: Back.easeOut }, "-=0")
      .from('.propertySearchBox', 0.8, {opacity: 0, scale: 0.95, transformOrigin: "50% 50%", ease: Back.easeOut }, "-=0.4")
      .to('.scrollElement', 0.4, {autoAlpha: 1}, "-=0")


      // const sections = new TimelineMax({ delay: 0.5 });
      // const container = $('.section1');

      // sections.staggerFrom(container.find('span'), 1, { opacity: 0, y: 100 }, 0.2);
      // sections.to('.section1', 1, { opacity: 1, y: 0 });

    }



    TweenLite.set("#bg--lines circle, #bg--lines path ", {visibility:"visible"});

    var drawBgLines = new TimelineMax({onComplete: startOrangelines});

    //line aniamtions
    var drawOrangeLines1 = new TimelineMax({paused: true, repeat: -1, repeatDelay: 5, yoyo: true});
    var animation1 = $('#anim1');

    var drawOrangeLines2 = new TimelineMax({paused: true, repeat: -1, delay: 1.5, repeatDelay: 2, yoyo: true});
    var animation2 = $('#anim2');

    var drawOrangeLines3 = new TimelineMax({paused: true, repeat: -1, delay: 3.5, repeatDelay: 6, yoyo: true});
    var animation3 = $('#anim3');

    var drawOrangeLines4 = new TimelineMax({paused: true, repeat: -1, delay: 4.5, repeatDelay: 3, yoyo: true});
    var animation4 = $('#anim4');

    //animate the plugin text first, drawing to 100%
    drawBgLines
      .from("#bg--lines", 2.5, {scale: 1.1, rotation: 2.5, transformOrigin: "50% 50%", ease: Power4.easeOut}, "-=0")
      .from("#bg--lines circle, #bg--lines path", 2.5, {drawSVG:0, autoAlpha: 0, ease: Power4.easeOut}, "-=2.3")


    drawOrangeLines1
      .from(animation1.find("#line--anim"), 3, {drawSVG:"0", ease: Power0.easeNone}, "-=0")
      .from(animation1.find("#circle--bg, #circle--yellow"), 0.3, {autoAlpha: 0}, "-=0")
      .to(animation1.find("#line--anim"), 3, {drawSVG: "100% 100%", ease: Power0.easeNone}, "-=0")
      .to(animation1.find("#circle--bg, #circle--yellow"), 0.3, {autoAlpha: 0, transformOrigin: "50% 50%", ease: Power0.easeNone}, "-=0")

    drawOrangeLines2
      .from(animation2.find("#circle--bg-3, #circle--yellow-3"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=0")
      .from(animation2.find("#line--anim-2"), 3, {drawSVG:"0", ease: Power0.easeNone}, "-=0")
      .from(animation2.find("#circle--bg-5, #circle--yellow-5"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=1.5")
      .to(animation2.find("#line--anim-2"), 3, {drawSVG: "100% 100%", ease: Power0.easeNone}, "-=0")
      .from(animation2.find("#circle--bg-4, #circle--yellow-4"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=3")
      .to(animation2.find("#circle--bg-3, #circle--yellow-3"), 0.3, {autoAlpha: 0, transformOrigin: "50% 50%", ease: Power0.easeNone}, "-=3")
      .to(animation2.find("#circle--bg-5, #circle--yellow-5"), 0.3, {autoAlpha: 0, transformOrigin: "50% 50%", ease: Power0.easeNone}, "-=1.5")
      .to(animation2.find("#circle--bg-4, #circle--yellow-4"), 0.3, {autoAlpha: 0, transformOrigin: "50% 50%", ease: Power0.easeNone}, "-=0")

    drawOrangeLines3
      .from(animation3.find("#circle--bg-7, #circle--yellow-7"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=0")
      .from(animation3.find("#line--anim-3"), 1.5, {drawSVG:"0", ease: Power0.easeNone}, "-=0")
      .from(animation3.find("#circle--bg-6, #circle--yellow-6"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=0")
      .to(animation3.find("#line--anim-3"), 1.5, {drawSVG: "100% 100%", ease: Power0.easeNone}, "-=0")
      .to(animation3.find("#circle--bg-7, #circle--yellow-7"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=1.5")
      .to(animation3.find("#circle--bg-6, #circle--yellow-6"), 0.3, {autoAlpha: 0, transformOrigin: "50% 50%", ease: Power0.easeNone}, "-=0")

    drawOrangeLines4
      .from(animation4.find("#circle--bg-8, #circle--yellow-8"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=0")
      .from(animation4.find("#line--anim-4"), 1.5, {drawSVG:"0", ease: Power0.easeNone}, "-=0")
      .to(animation4.find("#circle--bg-8, #circle--yellow-8"), 0.3, {autoAlpha: 0, ease: Power0.easeNone}, "-=0")
      .to(animation4.find("#line--anim-4"), 1.5, {drawSVG: "100% 100%", ease: Power0.easeNone}, "-=0")

    function startOrangelines() {
      drawOrangeLines1.play();
      drawOrangeLines2.play();
      drawOrangeLines3.play();
      drawOrangeLines4.play();
    }

    TweenLite.render();


    var plane = new TimelineMax({paused:true, repeat:-1, repeatDelay: 1});
    var bezierData = MorphSVGPlugin.pathDataToBezier("#animation-line");
    var bezierData2 = MorphSVGPlugin.pathDataToBezier("#animation-line2");

    plane
      .set("#plane, #point-orange, #point-blue, #animation-bits *, #animation-line", {visibility:"visible"})
      .set("#plane, #plane2", { autoAlpha: 0, xPercent:0, yPercent: 0})
      .from("#point-blue", 0.5, {autoAlpha:0, scale: 0,  transformOrigin: "50% 50%"}, "+=1")
      .from("#point-orange", 0.5, {autoAlpha:0, scale: 0,  transformOrigin: "50% 50%"}, "-=0")

      .to("#plane", 10, {rotation: -115, bezier: {values:bezierData, type:"cubic"}, ease:Linear.easeNone, transformOrigin: "50% 50%"}, "+=0.2")
      .to('#plane', 0.5, {autoAlpha:1}, "-=10")
      .staggerFrom("#animation-bits *", 0.2,{autoAlpha: 0, ease: Power0.easeNone}, 0.235, "-=10")
      .to("#point-blue, #animation-bits *, #animation-line, #point-orange, #plane", 0.5, {autoAlpha:0}, "-=0")

      .from("#point-blue2", 0.5, {autoAlpha:0, scale: 0,  transformOrigin: "50% 50%"}, "+=1")
      .from("#point-orange2", 0.5, {autoAlpha:0, scale: 0,  transformOrigin: "50% 50%"}, "-=0")

      .to("#plane2", 10, {rotation: 95, bezier: {values:bezierData2, type:"cubic"}, ease:Linear.easeNone, transformOrigin: "50% 50%"}, "+=0.2")
      .to('#plane2', 0.5, {autoAlpha:1}, "-=10")
      .staggerFrom("#animation-bits2 *", 0.2,{autoAlpha: 0, ease: Power0.easeNone}, -0.235, "-=10")
      .to("#point-blue2, #animation-bits2 *, #animation-line2, #point-orange2, #plane2", 0.5, {autoAlpha:0}, "-=0")


    var pulseBlue = new TimelineMax({repeat:-1, repeatDelay: 1, delay: 2});
      pulseBlue
        .fromTo("#point-blue > #pulse1b, #point-blue2 > #pulse1d", 2, {autoAlpha:0.3, scale: 0,  transformOrigin: "50% 50%"},  {autoAlpha:0, scale: 1.5,  transformOrigin: "50% 50%"}, "-=0")
        .fromTo("#point-blue > #pulse2b, #point-blue2 > #pulse2d", 2, {autoAlpha:1, scale: 0,  transformOrigin: "50% 50%"},  {autoAlpha:0, scale: 2,  transformOrigin: "50% 50%"}, "-=1.5")


      var pulseOrange = new TimelineMax({repeat:-1, repeatDelay: 1, delay: 1.6});
      pulseOrange
        .fromTo("#point-orange > #pulse1a, #point-orange2 > #pulse1c", 2, {autoAlpha:0.3, scale: 0,  transformOrigin: "50% 50%"},  {autoAlpha:0, scale: 1.5,  transformOrigin: "50% 50%"}, "-=0")
        .fromTo("#point-orange > #pulse2a, #point-orange2 > #pulse2c", 2, {autoAlpha:1, scale: 0,  transformOrigin: "50% 50%"},  {autoAlpha:0, scale: 2,  transformOrigin: "50% 50%"}, "-=1.5")

    plane.play();






  }, 0);

}
}

//Scroll Animations Homepage

export function indexPageScroll() {
  var scrollElementAnimation = new TimelineMax({delay: 1, repeat: -1, repeatDelay: 1});
  scrollElementAnimation
    .fromTo(".scrollElement > .inline", 1, {scaleX: 0, transformOrigin: "0% 0%" }, {scaleX: 1, transformOrigin: "0% 0%"  }, "-=0")
    .to(".scrollElement > .inline", 0.5, {scaleX: 0, transformOrigin: "100% 100%" }, "-=0")
}

export function functionProperties() {
  let windowWidth = $(window).width();

  if(windowWidth < 992) {

  } else {
    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .staggerFrom(".HomeLatestProperties > div", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }

  
}

export function functionWhoWeAre() {

  let windowWidth = $(window).width();
  
  if(windowWidth < 992) {

  } else {

    var scrollAnimation = new TimelineMax({delay: 1});
    scrollAnimation
      .staggerFrom(".scrollAnimation > div > div", 0.6, {y: 30, autoAlpha:0}, 0.1, "-=0")
  }
}

export function functionHomePulseContainer() {

  let windowWidth = $(window).width();
  
  if(windowWidth < 992) {

  } else {

  var Cont={val:0} , NewVal = 1000 ;

  var scrollAnimation = new TimelineMax({delay: 1});
  scrollAnimation
    .from(".centerBubble", 0.6, {scale: 0, ease: Power4.easeOut}, "-=0")
    .to(Cont,1.5,{val:NewVal,roundProps:"val", onUpdate:function(){
      document.getElementById("counting").innerHTML=Cont.val
    }}, "-=0.6")

    .staggerFrom(".bubbles-in", 0.6, {scale: 0}, -0.1, "-=1.5")
    .from(".bubbleBranches", 0.4, {scale: 0, ease: Power4.easeOut}, "-=0")
    .from(".bubbleLanguages", 0.4, {scale: 0, ease: Power4.easeOut}, "-=0")
    .from(".bubbleOffices", 0.4, {scale: 0, ease: Power4.easeOut}, "-=0")

  }

}

export function functionRecommendationsSlider() {

  let windowWidth = $(window).width();
  
  if(windowWidth < 992) {

  } else {

    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .to(".recommendationsSlider", 0.6, {y: 0, autoAlpha:1}, "-=0")

  }

}


export function functionHomeOffices() {

  let windowWidth = $(window).width();
  
  if(windowWidth < 992) {

  } else {

    var scrollAnimation = new TimelineMax({delay: 0.5});
    scrollAnimation
      .to(".homeOfficeText", 0.6, {y: 0, autoAlpha:1}, "-=0")

  }
}

export function functionHomeInstantValuation() {

  let windowWidth = $(window).width();
  
  if(windowWidth < 992) {

  } else {

    var valuationAnimations = new TimelineLite({ delay: 0.5 }),
    mySplitText = new SplitText(".homeInstantValuationTitle", { type: "words,chars" }),
    chars = mySplitText.chars;

    TweenLite.set(".homeInstantValuationTitle", { perspective: 400 });

    valuationAnimations
      .set('.homeInstantValuation', {opacity: 1})
      .staggerFrom(chars, 0.8, { opacity: 0, scale: 0, x: -50, transformOrigin: "0% 50% -50", ease: Back.easeOut }, 0.01, "-=0")
      .from(".homeInstantValuation button, .homeInstantValuation a", 0.6, {y: 30, autoAlpha:0}, "-=0")

  }
}


