import styled from 'styled-components';
import React from 'react';
import { Dropdown as SemanticDropdown } from 'semantic-ui-react';
import sc from 'config/theme';
import bp from 'config/responsive';
import { fonts } from 'Typography';

import imgArrow from './arrow.svg';

const background = '#fafafa';

export const Dropdown = styled(props => (
  <SemanticDropdown selection {...props} />
))`
  &&& {
    height: ${sc.sizes.height}px;
    border-color: rgba(151, 151, 151, 0.22);
    background: ${background};
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${sc.colors.muted};
    border-radius: ${sc.sizes.borderRadius}px;
    padding: 0px 38px 0 20px;
    user-select: none;
    flex: initial;
    width: calc(50% - 16px);

    &.active {
      border-color: ${sc.colors.gray};
    }
    &&& .icon {
      background: no-repeat center url(${imgArrow});
      width: 17px;
      height: 9px;
      padding: 0;
      top: 50%;
      opacity: 1;
      margin: -4px 0 0 0;

      &::before {
        display: none;
      }
    }
    .menu {
      border-color: ${sc.colors.gray};
    }
    .text {
      font-size: 16px;
      font-weight: 400;
      font-family: ${fonts.sans};
      color: ${sc.colors.main};
      user-select: none;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      height: 16px;


      &.default {
        color: rgba(${sc.colors.muted_rgb}, 0.52);
      }
    }
    ${bp.mobile} {
      
      width: calc(50% - 16px);
      
      
      .text {
        font-size: 16px;
      }
    }
  }
`;
