import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { FormSuccess, Textarea, InputGroup, Input, Button, Checkbox } from 'components/ui';
import { Sans } from 'Typography';
import queryString from 'query-string';
import sc from 'config/theme';
import request from 'utils/request';

const StyledTitle = styled(Sans)`
  margin-bottom: 40px;
`;

const CallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  ${Button} {
    width: calc(50% - ${sc.sizes.margin}px);
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  ${Checkbox} {
    width: calc(50% - ${sc.sizes.margin}px);
  }
`;

export class Enquiry extends PureComponent {
  state = {
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
      form:{},
    },
    gdprChecked: false,
    gdprWarning: false,
  }

  handleGdprChange = () => {
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }

  submitForm = async (event) => {
   
    const { gdprChecked } = this.state;

    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }

    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });

    if(vars.hasOwnProperty("id")){
      console.log('yes')
      data.append('propertyID', vars['id']);
      data.append('propertyURL', window.location.href);
      data.append('subject', 'Enquiry [Property ID: '+vars['id']+']');
    }else{
     data.append('subject', 'Enquiry');
    }


   

    event.preventDefault();

   

    if (gdprChecked) {
      try {
        this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });

        await request.post('forms' , data);

        this.setState({ formState: { ...this.state.formState, loading: false, success: true } })


      } catch (err) {
        this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
      }
    } else {
      this.setState({ gdprWarning: true });
    }

  }

  handleInputChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;

  
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));

  }


  renderForm = (data) => {
    const { enquiry } = data.pages.forms;
    const { title, inputs } = enquiry;
    const { formState, gdprChecked, gdprWarning } = this.state;



   return (
      <form onSubmit={this.submitForm} type="post">
        <StyledTitle epsilon color="main" weight="medium" block>
          {title}
        </StyledTitle>
        <InputGroup>
          <Input 
          name="first_name"
          placeholder={inputs.firstName} 
          onChange={this.handleInputChange} 
          required />
          <Input placeholder={inputs.lastName} name="last_name" onChange={this.handleInputChange} required />
        </InputGroup>
        <InputGroup>
          <Input fluid placeholder="Email" name="email" onChange={this.handleInputChange} required />
        </InputGroup>

        <InputGroup>
          <Input placeholder={inputs.phone} name="phone" onChange={this.handleInputChange} />
          <Input placeholder={inputs.mobile} name="mobile" onChange={this.handleInputChange} required />
        </InputGroup>

        <InputGroup>
          <Input fluid placeholder={inputs.address} name="address" onChange={this.handleInputChange} required />
        </InputGroup>
        <InputGroup>
          <Input placeholder={inputs.city} name="city" onChange={this.handleInputChange} />
          <Input placeholder={inputs.postcode} name="postcode" onChange={this.handleInputChange} />
        </InputGroup>
        <InputGroup>
          <Textarea fluid placeholder={inputs.comments} name="comments" onChange={this.handleInputChange} />
        </InputGroup>
        <CallToActionContainer>
          <Checkbox
            color="main"
            name="gdpr"
            required
            label={inputs.gdpr}
            checked={gdprChecked}
            onChange={this.handleGdprChange}
            error={gdprWarning}
          />
          <Button disabled={formState.loading} alt block>
            {inputs.submit}
          </Button>
        </CallToActionContainer>
      </form>
    );
  }

  renderContent = (data) => {
    const { formState: { loading, sent } } = this.state;

    if (sent && !loading) {
      return this.renderFormState();
    } else {
      return this.renderForm(data);
    }
  }

  renderFormState = () => {
    const { errorMessage, formState: { error, success } } = this.state;

    if (error) {
      return (
        <Sans color="white" beta>
          {errorMessage || 'Error.'}
        </Sans>
      )
    }
    if (success) {
      return <FormSuccess />
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query EnquiryForm {
            pages {
              forms {
                enquiry {
                  title
                  inputs {
                    firstName
                    lastName
                    phone
                    mobile
                    city
                    address
                    postcode
                    comments
                    gdpr
                    submit
                  }
                }
              }
            }
          }
        `}
        render={this.renderContent}
      />
    );
  }
}

export default Enquiry;
