import styled, { css } from 'styled-components';
import sc from 'config/theme';
import bp from 'config/responsive';
import PT from 'prop-types';
import './hightide.css';
import './akkurat.css';


export const fonts = {
  sans: `'acumin-pro', sans-serif`,
  script: 'high_tideregular',
  serif: `akkurat, 'Times New Roman', Times, serif`,
};

const colorHelper = ({ color }) =>
  color
    ? css`
        color: ${sc.colors[color]};
      `
    : '';

export const weightRatio = {
  light: 200,
  normal: 300,
  medium: 500,
  bold: 600,
};

const weightHelper = ({ weight }) => {
  const resultingWeight = weightRatio[weight] || 300;

  return css`
    font-weight: ${resultingWeight};
  `;
};

const blockHelper = ({ block }) =>
  block &&
  css`
    display: block;
  `;

const alignHelper = ({ align }) =>
  align &&
  css`
    text-align: ${align};
  `;

const lineHeightHelper = ({ lineHeight }) =>
  lineHeight &&
  css`
    line-height: ${lineHeight};
  `;

const uppercaseHelper = ({ uppercase }) =>
  uppercase &&
  css`
    text-transform: uppercase;
  `;

const whiteSpaceHelper = ({ whiteSpace }) =>
  whiteSpace &&
  css`
    white-space: ${whiteSpace};
  `;

const helpers = css`
  ${blockHelper}
  ${colorHelper}
  ${weightHelper}
  ${alignHelper}
  ${lineHeightHelper}
  ${uppercaseHelper}
  ${whiteSpaceHelper}
`;

export const Script = styled.span`
  font-family: ${fonts.script};
  line-height: 1.5;
  ${helpers}

  ${({ alpha, beta, gamma, delta }) => {
    if (alpha) {
      // Landing title
      return css`
        font-size: 41px;

        ${bp.computer} {
          font-size: 55px;
        }
        ${bp.large} {
          font-size: 80px;
        }
        ${bp.mobileSm} {
          font-size: 38px;
        }
      `;
    } else if (beta) {
      // Section title
      return css`
        font-size: 32px;

        ${bp.tablet} {
          font-size: 38px;
        }
        ${bp.computer} {
          font-size: 40px;
        }
        ${bp.large} {
          font-size: 45px;
        }
      `;
    } else if (gamma) {
      // CTA
      return css`
        line-height: 1em;
        font-size: 32px;

        ${bp.tablet} {
          font-size: 38px;
        }

        ${bp.computer} {
          font-size: 65px;
        }
        ${bp.large} {
          font-size: 90px;
        }
      `;
    } else if (delta) {
      // Context title
      return css`
       font-size: 32px;

      ${bp.tablet} {
        font-size: 38px;
      }

       ${bp.computer} {
          font-size: 35px;
        }
        ${bp.large} {
          font-size: 40px;
        }


      `;
    }
  }}
`;

export const Sans = styled.span`
  font-family: ${fonts.sans};
  line-height: 1.5;
  ${helpers}

  ${({ alpha, beta, gamma, delta, epsilon, zeta, eta, theta }) => {
    if (alpha) {
      // Landing text
      return css`
        font-size: 16px;

        ${bp.computer} {
          font-size: 18px;
        }
        ${bp.large} {
          font-size: 22px;
        }
      `;
    } else if (beta) {
      // Subtitle
      return css`
        font-size: 16px;

        ${bp.tablet} {
          font-size: 20px;
        }
      `;
    } else if (gamma) {
      // Regular text
      return css`
        font-size: 18px;

        ${bp.computer} {
          font-size: 20px;
        }
        ${bp.large} {
          font-size: 28px;
        }
      `;
    } else if (delta) {
      // Footer text
      return css`
        font-size: 14px;
      `;
    } else if (epsilon) {
      // Form title
      return css`
        font-size: 26px;

        ${bp.computer} {
          font-size: 26px;
        }
        ${bp.large} {
          font-size: 26px;
        }
      `;
    } else if (zeta) {
      // Footnotes and cards
      return css`
        font-size: 18px;
      `;
    } else if (eta) {
      // CTA text
      return css`
        font-size: 16px;


        ${bp.computer} {
          font-size: 16px;
        }
        ${bp.large} {
          font-size: 24px;
        }
      `;
    } else if (theta) {
      // Smaller title
      return css`
        font-size: 16px;

        ${bp.computer} {
          font-size: 21px;
        }
        ${bp.large} {
          font-size: 32px;
        }
      `;
    }
  }}
`;

export const Serif = styled.span`
  font-family: ${fonts.serif};
  line-height: 1.5;
  ${helpers}

  ${({ alpha, beta, gamma, delta }) => {
    if (alpha) {
      // CTA text
      return css`
        font-size: 23px;

        ${bp.computer} {
          font-size: 28px;
        }
        ${bp.large} {
          font-size: 45px;
        }

      `;
    } else if (beta) {
      // Section title
      return css`
        font-size: 26px;

        ${bp.computer} {
          font-size: 30px;
        }
        ${bp.large} {
          font-size: 36px;
        }
      `;
    } else if (gamma) {
      // Section text
      return css`
        font-size: 20px;

        ${bp.computer} {
          font-size: 30px;
        }
        ${bp.large} {
          font-size: 40px;
        }
      `;
    } else if (delta) {
      // Smaller section text
      return css`
        font-size: 16px;

        ${bp.computer} {
          font-size: 25px;
        }
        ${bp.large} {
          font-size: 35px;
        }
      `;
    }
  }}
`;

const propTypes = {
  // Main props
  block: PT.bool,
  color: PT.string,
  weight: PT.string,
  align: PT.oneOf(['left', 'center', 'right']),
  // Variant props
  alpha: PT.bool,
  beta: PT.bool,
  gamma: PT.bool,
  delta: PT.bool,
  epsilon: PT.bool,
  zeta: PT.bool,
  eta: PT.bool,
  theta: PT.bool,
};

const defaultProps = {
  block: false,
  weight: 'normal',
};

Script.propTypes = propTypes;
Script.defaultProps = defaultProps;

Sans.propTypes = propTypes;
Sans.defaultProps = defaultProps;

Serif.propTypes = propTypes;
Serif.defaultProps = defaultProps;
