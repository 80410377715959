import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { navigate } from 'gatsby';
import {
  Button,
  InputGroup,
  Input,
  PillMenu,
  Dropdown,
  InputRange,
  Checkbox,
  Sans,
  Media,
  Script,
} from 'components/ui';

import bp from 'config/responsive';
import sc from 'config/theme';
import { FormContext } from 'context/propertySearch';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import MultiSelect from "@khanacademy/react-multi-select";

import { searchOptions } from '../../../data';

import resultsBanner from 'images/results-banner.png';
import iconEmail from 'images/icon-email.svg';
import iconShare from 'images/icon-share.svg';


const Container = styled.div`
  padding-top: 67px;
  padding-bottom: 36px;
  margin-left: -${sc.sizes.margin}px;
  margin-right: -${sc.sizes.margin}px;
  ${bp.mobile} {
    padding-top: 0px;
    /* padding-bottom: 125px; */
  }


  & .multi-select {
    padding: 0 20px;
    position: relative;

    .dropdown {
      position: absolute !important;
      left: 0 !important;
      width: 100% !important;
    }

    .dropdown-heading {
      height: 56px !important;
      border-color: rgba(151,151,151,0.22) !important;
      background: #fafafa;
      display: flex !important;


      justify-content: space-between;

      align-items: center;
      color: #163163 !important;
      border-radius: 6px !important;
      padding: 0px 10px 0 10px;

      user-select: none;

      flex: initial;

      .dropdown-heading-value {
        display: flex;
        align-items: center;
        padding: 0 40px  0 20px !important;
        color: #163163 !important;
        font-size: 16px;
        font-weight: 400;
        font-family: 'acumin-pro',sans-serif;

        & > span {
          color: #163163 !important;
          overflow: hidden;
        }
      }

      .dropdown-heading-dropdown-arrow {
        color: #163163 !important;


        & > span {
          color: #0b1d3f !important;
          border-color: #0b1d3f transparent transparent !important;
          border-width: 9px 9px 2.5px !important;
          transform: translate(4px, 2px);
          top: 0 !important;
        }
      }

    }
  }

`;

const CounterResults = styled.div`
  color: #979797;

  ${bp.mobile} {
  }

`;



const Row = styled.div`
  display: flex;

  ${bp.mobile} {
    flex-wrap: wrap;
  }
`;

const Column = styled.div`
  flex: 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 20px;
  width: calc(50% - 40px);

  ${bp.mobile} {
    width: calc(100% - 16px);
    margin: 0 ${sc.sizes.margin}px;
    flex: 1 100%;
  }
  ${({ mobile }) =>
    mobile &&
    css`
      display: none;
      ${bp.mobile} {
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
      }
    `}
`;

const StyledIcon = styled(Icon)`
  &&& {
    background: ${sc.colors.white};
    border-radius: 50%;
    width: 44px;
    height: 44px;
    font-size: 20px;
    position: relative;
    margin: 0;
    z-index: 1;
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;


const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 20px;
  margin-top: 25px;

  span {
    width: calc(50% - 8px);
    display: flex;
    justify-content: space-between;
    margin-left: 8px;
  }
  ${bp.large} {
    display: none;
  }
  ${bp.mobile} {
    span {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: 0px;
    }
    ${Checkbox} {
      &&& {
        margin-left: 0px;
      }
    }
  }
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #ddd',
    color: '#0B1D3F',
    padding: 20,


  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: 'inherit',
    background: 'white',
    borderRadius: 6,
    'padding-top': 5,
    'padding-bottom': 5,
    'padding-left': 10,
    'padding-right': 10,
    display: 'flex',
    alignItems: 'center',
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "'acumin-pro',sans-serif",
    "border": "1px solid rgba(151,151,151,0.22)",
    height: 54,

  }),
  placeholder: () => ({
    color: 'rgba(22,49,99,0.52) !important',
    "marginLeft": "2px",
    "marginRight": "2px",
    "position": "absolute",
    "top": "50%",
    "transform": "translateY(-50%)",
    "boxSizing": "border-box"

  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const DropdownIndicator = (props) => {
  return (
    null
  );
};
const IndicatorSeparator = (props) => {
  return (
    null
  );
};

const filterSearch = (area) => {


 const matches = searchOptions.filter(
    i =>
    i.label.toLowerCase().startsWith(area.toLowerCase())
  );

  const  arrLength = matches.length;
  if(arrLength > 3){
      matches.splice( 0, arrLength - 3);
  }

  return matches
};


const loadOptions = (area, callback) => {
  setTimeout(() => {
    callback(filterSearch(area));
  }, 1000);
};

const ContainerBanner = styled.div`
  background-color: #f5f5f5;
  padding-top: 44px;
  margin-bottom: 44px;


  margin: 0 -30px;

  padding: 45px 30px 35px 30px;

  ${bp.mobile} {
    padding-top: 42px;
  
  }
`;
const RowBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsContainer = styled.div`
  color: ${sc.colors.gray};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const IconLink = styled.a`
  display: inline-block;
  margin-left: 16px;
  width: 20px;
  height: 20px;
  background: transparent url(${({ icon }) => icon}) center no-repeat;
  background-size: auto;
  cursor: pointer;
  transition: opacity .25s;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;

export class SearchResultsFilters extends PureComponent {
  state = {
    form: {
      priceMin: 200,
      priceMax: 1000,

      rangeMin : 200,
      rangeMax : 1000,

      enquiryType: 'Rent',
      area: '',
      propertyType: null,
      bedrooms: null,
      more: ['1-option'],
      includeNewHomes: true,

      sortBy: 'price' ,
      sortOrder: 'DESC',

      stepSize : 50,
      page : 1,
    },
    error: false,
    inputValue : null,
    menuIsOpen : false,
    selected: [],
  };

  handleChange = ({ target: { name, value } }) =>
    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));

  handleSemanticChange = (_, { name, value }) =>
    this.setState(({ form }) => ({
      form: {
        ...form,
        [name]: value,
      },
    }));

  handleRangeChange = ({ min, max }) =>{

    this.setState(({ form }) => ({
      form: {
        ...form,
        priceMin: min,
        priceMax: max,
      },
    }));
  }

  handleSearch = () => {
    let str = '';
    const { form } = this.state;

    Object.keys(form).forEach((key, index, arr) => {
      if (index >= arr.length - 1) {
        str += `${key}=${form[key]}`;
      } else {
        str += `${key}=${form[key]}&`;
      }
    });

    navigate(`search-results?${str}`);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      this.setState({ form: this.props.form });
    }
  }

  handlePillChange = (offerType, { form }) => {


   // const { form } = this.state;

    if (offerType === "Rent" ){
      form.rangeMax = 1000;
      form.rangeMin = 200;
      form.priceMin = 200;
      form.priceMax = 1000;
      form.enquiryType = offerType;
      form.stepSize = 50;


    }else{

       form.rangeMin = 100000;
       form.rangeMax = 1000000;
       form.priceMin = 100000;
       form.priceMax = 1000000;
       form.enquiryType = offerType;
       form.stepSize = 10000;

    }


    this.setState({ form: {
          ...form,
          enquiryType: offerType,
    }});

  };
   closeMenu = () => {
    this.setState({ menuIsOpen: false });
   };

  openMenuIsOpen = () => {
    this.setState({ menuIsOpen: true });

  };
  handleInputChange = (newValue) => {

    if (newValue != ""){
        this.openMenuIsOpen();
    }else{
      this.closeMenu();
    }
  };
  handleInputChange1 = (newValue, { form }) => {

    if (newValue != null){

      if (newValue.hasOwnProperty('value') ){

        const inputValue = newValue.value;
        this.setState({ inputValue });
        this.closeMenu();

        this.setState({ form: {
          ...form,
          area: inputValue,
        }});

        return inputValue;

      }else{

        this.setState({ inputValue: '' });
        this.setState({ form: {
          ...form,
          area: '',
        }});


        this.closeMenu();
        return null;
      }
    }else{

      this.setState({ inputValue: '' });
      this.setState({ form: {
          ...form,
          area: '',
      }});

      this.closeMenu();
      return null;
    }
  };

  createlabel = (inputValue) => {
    return "Search \"".concat(inputValue, "\"");
  }

  handleInputChangeMore = (selected, form) => {

    this.setState({selected});
    form.more = selected;
    this.setState({form});

  };

  toggleSort = () => {

    console.log('sss');

      var neworder = (this.state.form.sortOrder == "DESC" ? "ASC" : "DESC");
      this.state.form.sortOrder =  neworder;

      this.setState(({ form }) => ({
        form: {
          ...form,
          sortBy: 'Price',
          sortOrder: neworder,
        },
      }));

      let str = '';
      const { form } = this.state;
        Object.keys(form).forEach((key, index, arr) => {
          if (index >= arr.length - 1) {
            str += `${key}=${form[key]}`;
          } else {
            str += `${key}=${form[key]}&`;
          }
        });

      navigate(`search-results?${str}`);

    };



  render() {
    const { handleExpandMap, content, handleSignup , results} = this.props;
    const { offerTypes, selects, updateBtn } = content;
    const { form } = this.state;
    const {selected} = this.state;
    const area = {value:this.state.form.area, label:this.state.form.area}

    const isSale = this.state.form.enquiryType == "Buy";



    // const { labels } = content.form;

    if (!Object.keys(this.state.form).length) return null;

        return (
      <FormContext.Consumer>
        {({
          inputValue,
          handleSearch,
          handleSemanticChange,
          handleRangeChange,

        }) => {
          return (
            <React.Fragment>
            <Container>
              <Column mobile>
                <Sans epsilon color="white" weight="bold">
                  Property Search
                </Sans>
              </Column>
              <Row>
                <Column>
                  <InputGroup padded>
                    <PillMenu inputName="enquiryType3" showCheck>

                      <PillMenu.Pill
                        onClick={() =>
                          this.handlePillChange(offerTypes.sales, {form})
                        }
                        checked={form['enquiryType'] === offerTypes.sales}
                      >

                        {offerTypes.sales}
                      </PillMenu.Pill>
                      <PillMenu.Pill
                        onClick={() =>
                          this.handlePillChange(offerTypes.lettings, {form})
                        }
                        checked={form['enquiryType'] === offerTypes.lettings}
                      >
                        {offerTypes.lettings}
                      </PillMenu.Pill>

                    </PillMenu>
                  </InputGroup>
                      <input
                        type="hidden"
                        name="sortBy"
                        value={form.sortBy}
                      />
                      <input
                        type="hidden"
                        name="sortOrder"
                        value={form.sortOrder}
                      />
                  <Media
                    breakpoint={bp.mobile}
                    renderIfTrue={() => (
                      <InputGroup padded>
                        <InputRange
                          padded
                          typeT = {form.enquiryType}
                          stepSize = {form.stepSize}

                          minValue = {form.rangeMin}
                          maxValue = {form.rangeMax}
                          value={{
                            min: this.state.form.priceMin,
                            max: this.state.form.priceMax,
                          }}
                          handleChange={this.handleRangeChange}
                        />
                      </InputGroup>
                    )}
                    renderIfFalse={() => (
                      <InputGroup padded>

                        <AsyncCreatableSelect

                          value={area}
                          components={{ DropdownIndicator, IndicatorSeparator }}
                          placeholder="Search"
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions
                          isClearable = {true}
                          formatCreateLabel = {this.createlabel}
                          menuIsOpen={this.state.menuIsOpen}
                          styles={customStyles}
                          onChange={(value) => {
                              this.handleInputChange1( value, {form});
                            }
                          }
                          onInputChange= {this.handleInputChange}

                      />


                      </InputGroup>
                    )}
                  />
                  <Media
                    breakpoint={bp.mobile}
                    renderIfTrue={() => (
                      <InputGroup padded>

                      <AsyncCreatableSelect


                          value={area}
                          components={{ DropdownIndicator, IndicatorSeparator }}
                          placeholder="Search"
                          cacheOptions
                          loadOptions={loadOptions}
                          defaultOptions
                          isClearable = {true}
                          formatCreateLabel = {this.createlabel}
                          menuIsOpen={this.state.menuIsOpen}
                          styles={customStyles}
                          onChange={(value) => {
                              this.handleInputChange1( value, {form})
                            }
                          }
                          onInputChange= {this.handleInputChange}

                      />


                      </InputGroup>
                    )}
                    renderIfFalse={() => (
                      <InputGroup padded>
                        <Dropdown
                          fluid
                          placeholder='Bedrooms'
                          onChange={this.handleSemanticChange}
                          name="bedrooms"
                          value={this.state.form.bedrooms}
                          options={selects.bedrooms.map(select => ({
                            ...select,
                            key: select.value,
                          }))}
                          placeholder="All bedrooms"
                        />
                        <Dropdown
                          fluid
                          placeholder='Type'
                          onChange={this.handleSemanticChange}
                          value={this.state.form.propertyType}
                          name="propertyType"
                          options={selects.propertyType.map(select => ({
                            ...select,
                            key: select.value,
                          }))}
                          placeholder="Property type"
                        />

                      </InputGroup>
                    )}
                  />
                </Column>
                <Column>
                  <Media
                    breakpoint={bp.tablet}
                    renderIfTrue={() => (
                      <InputGroup padded>
                        <InputRange
                          padded
                          typeT = {form.enquiryType}
                          minValue = {form.rangeMin}
                          maxValue = {form.rangeMax}
                          stepSize = {form.stepSize}

                          value={{
                            min: this.state.form.priceMin,
                            max: this.state.form.priceMax,
                          }}
                          handleChange={this.handleRangeChange}
                        />
                      </InputGroup>
                    )}
                    renderIfFalse={() => (
                      <InputGroup padded>
                        <Dropdown
                          fluid
                          onChange={this.handleSemanticChange}
                          value={this.state.form.bedrooms}
                          name="bedrooms"
                          options={selects.bedrooms.map(select => ({
                            ...select,
                            key: select.value,
                          }))}
                          placeholder="All bedrooms"
                        />
                        <Dropdown
                          fluid
                          onChange={this.handleSemanticChange}
                          name="propertyType"
                          value={this.state.form.propertyType}
                          options={selects.propertyType.map(select => ({
                            ...select,
                            key: select.value,
                          }))}
                          placeholder="Property type"
                        />

                      </InputGroup>
                    )}
                  />
                  <InputGroup padded>

                    
                    {isSale ? (

                    <MultiSelect
                      fluid
                      overrideStrings={{
                        selectSomeItems: "More Options",
                      }}
                      hasSelectAll= {false}
                      disableSearch ={true}
                      options={selects.moreOptions}
                      selected={this.state.form.more}
                      onSelectedChanged = {selected => {
                        this.handleInputChangeMore( selected, this.state.form )
                        }
                      }
                      ItemRenderer={RightItemRenderer}
                    />
                    ) : (

                    <MultiSelect
                      fluid
                      overrideStrings={{
                        selectSomeItems: "More Options",
                      }}
                      hasSelectAll= {false}
                      disableSearch ={true}
                      options={selects.moreOptionsLet}
                      selected={this.state.form.more}
                      onSelectedChanged = {selected => {
                        this.handleInputChangeMore( selected, this.state.form )
                        }
                      }
                      ItemRenderer={RightItemRenderer}
                    />

                    )}


                    <Button fluid alt onClick={this.handleSearch}>
                      {updateBtn}
                    </Button>

                  </InputGroup>
                </Column>
              </Row>

              <CheckboxContainer className="newHomes">
                <span>
                  <Checkbox
                    color="white"
                    verticalCenter
                    label="Include New Homes"
                  />
                  <StyledIcon
                    onClick={handleExpandMap}
                    name="map marker alternate"
                  />
                </span>
              </CheckboxContainer>
            </Container>

            <ContainerBanner>
              <RowBanner>
                <Script color="main" delta>
                  Results
                </Script>

                <IconsContainer>
                   <CounterResults>{results} Properties Found</CounterResults>
                  <IconLink title="Sign Up to Property Alerts" icon={iconEmail}  onClick={handleSignup}/>
                  <IconLink title="Sort by Price" icon={iconShare}  onClick={this.toggleSort}/>
                </IconsContainer>
              </RowBanner>


              {/* <Content>
                <BannerImg src={resultsBanner} alt="results banner" />
                <ContentWrapper>
                  <StyledHeader align="center" color="main" zeta>
                    {content.title}
                  </StyledHeader>
                  <Button onClick={handleSignup} main>
                    {content.btn}
                  </Button>
                </ContentWrapper>
              </Content> */}


            </ContainerBanner>
            </React.Fragment>


          );
        }}
      </FormContext.Consumer>
    );
  }
}

class RightItemRenderer extends PureComponent {
    render() {
        const {checked, option, onClick} = this.props;
        return <span>
            <span>
                {option.label}
            </span>
            <input
                type="checkbox"
                onChange={onClick}
                checked={checked}
                tabIndex="-1"
                style={{float: 'right'}}
            />
        </span>;
    }
}

export default SearchResultsFilters;
