import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import {
  Input,
  InputGroup,
  Button,
  Checkbox,
  Paragraph,
  Sans,
  FormSuccess,
} from 'components/ui';
import sc from 'config/theme';
import bp from 'config/responsive';
import resultsBanner from 'images/results-banner.png';
import request from 'utils/request';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${sc.colors.white};
  z-index: 1;
  padding: 30px 0;
  padding-bottom: 0;

  ${bp.mobile} {
    width: 100%;
    display: block;
    padding-top: 20px;
  }
  
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${bp.mobile} {
    padding: 0 20px;
  }
`;

const StyledHeader = styled(Sans)`
  &&& {
    margin-bottom: 20px;

    ${bp.mobile} {
      margin-bottom: 27px;
    }
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;

  &.nomargin {
    margin: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: -47px;

  ${bp.mobile} {
    display: none;
  }
`;

const HomeImg = styled.img``;

const InputsWrapper = styled.div`
  margin-top: 66px;
  margin-bottom: 30px;

  ${bp.mobile} {
    margin-top: 42px;
    margin-bottom: 28px;
  }
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;

export class SearchResultsSignup extends PureComponent {
  state = {
    formState: {
      loading: false,
      error: false,
      success: false,
      sent: false,
    },
    gdprChecked: false,
    gdprWarning: false,
  }

  submitForm = async (event) => {
    const { gdprChecked } = this.state;

    event.preventDefault();


    var data = new FormData();

    for ( var key in this.state.form ) {
        data.append(key, this.state.form[key]);
    }

    data.append('subject', 'Property Alerts');






    if (gdprChecked) {
      try {
        this.setState({ formState: { ...this.state.formState, loading: true, sent: true }, gdprWarning: false });
        await request.post('forms', data);
        this.setState({ formState: { ...this.state.formState, loading: false, success: true } })
      } catch (err) {
        this.setState({ formState: { ...this.state.formState, error: true, loading: false }});
      }
    } else {
      this.setState({ gdprWarning: true });
    }
  }

  handleGdprChange = () => {
    this.setState(({ gdprChecked }) => ({ gdprChecked: !gdprChecked }));
  }


  handleInputChange = (event) => {

      const name = event.target.name;
      const value = event.target.value;

  
      this.setState(({ form }) => ({
        form: {
          ...form,
          [name]: value,
        },
      }));

  }

  renderForm = ({title, subtitle, form}) => {
    const { formState, gdprChecked, gdprWarning } = this.state;

    return (
      <FormWrapper>
        <Form onSubmit={this.submitForm}>
          <StyledHeader epsilon color="main" weight="medium">
            {title}
          </StyledHeader>
          <StyledParagraph className="nomargin">{subtitle}</StyledParagraph>
          <InputsWrapper>
            <InputGroup>
              <Input fluid required placeholder={form.firstName} name="first_name" onChange={this.handleInputChange} />

              <Input fluid required placeholder={form.lastName} name="last_name" onChange={this.handleInputChange} />
            </InputGroup>
            
            <InputGroup>
              <Input fluid placeholder="Email" name="email" onChange={this.handleInputChange} required />
            </InputGroup>


            <InputGroup>
              <Input fluid placeholder={form.phone} name="phone" onChange={this.handleInputChange}/>
              <Input fluid required placeholder={form.mobile} name="mobile" onChange={this.handleInputChange} />
            </InputGroup>
          </InputsWrapper>

          <InputGroup>
            <Column>
              <Checkbox
                color="main"
                fluid
                verticalCenter
                required
                name="gdpr"
                label={form.gdpr}
                checked={gdprChecked}
                onChange={this.handleGdprChange}
                error={gdprWarning}
              />
            </Column>
            <Column>
              <Button
                disabled={formState.loading}
                checked={gdprChecked}
                fluid
                alt
              >
                {form.submit}
              </Button>
            </Column>
          </InputGroup>
        </Form>
        <ImgWrapper>
          <HomeImg src={resultsBanner} alt="results banner" />
        </ImgWrapper>
      </FormWrapper>
    );
  }

  renderContent = (props) => {
    const { formState: { loading, sent } } = this.state;

    if (sent && !loading) {
      return this.renderFormState();
    } else {
      return this.renderForm(props);
    }
  }

  renderFormState = () => {
    const { errorMessage, formState: { error, success } } = this.state;

    if (error) {
      return (
        <Sans color="white" beta>
          {errorMessage || 'Error.'}
        </Sans>
      )
    }
    if (success) {
      return <FormSuccess />
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SignupModal {
            pages {
              searchResults {
                signup {
                  title
                  subtitle
                  form {
                    firstName
                    lastName
                    phone
                    mobile
                    gdpr
                    submit
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const { pages: { searchResults } } = data;
          return this.renderContent(searchResults.signup);
        }}
      />
    );
  }
}

export default SearchResultsSignup;
