import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import PT from 'prop-types';
import {
  InnerContainer,
  Grid,
  Spacer,
  Column,
  PulsingButton,
  Media,
} from 'components/ui';
import sc from 'config/theme';
import { Serif, Sans } from 'Typography';
import bp from 'config/responsive';


import iconAward from 'images/icon-awards.svg';
import iconReviews from 'images/icon-reviews.svg';

const Container = styled.div`
  padding: 100px 0 60px;
  background: ${sc.colors.white};


  ${bp.computer} {
    padding: 190px 0 130px;
  }
  ${bp.large} {
    padding: 300px 0 270px;
  }

  .leftSection {
    padding-left: 25px !important;
    padding-right: 25px !important;

    ${bp.computer} {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
  .grid {
    ${bp.computer} {
      opacity: 0;
    }
  }

  .linkIn {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;

const Title = styled.div`
  margin-bottom: 15px;

  ${bp.computer} {
    margin-bottom: 83px;
  }
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const SansBadge = styled(Sans)`
  font-size: 16px;

  ${bp.computer} {
    font-size: 12px;
  }
  ${bp.large} {
    font-size: 14px;
  }
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;

  ${bp.computer} {
    margin-top: 0;
    display: inherit;
    flex-wrap: inherit;
    justify-content: inherit;
  }
`;

const BadgeDefault = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  border-radius: 50%;
  margin-bottom: 30px;

  img {
    margin-bottom: 10px;
  }

  ${bp.computer} {
    margin-bottom: 0;
    position: absolute;
  }
`;

const BadgeAwards = styled.div`
  ${BadgeDefault}
  width: 65vw;
  height: 65vw;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  border: 12px solid ${sc.colors.white};
  background-color: ${sc.colors.main};
  right: 0;
  top: 0;

  img {
    width: 40px;
  }

  ${bp.tablet} {
    width: 50vw;
    height: 50vw;
  }

  ${bp.computer} {
    width: 175px;
    height: 175px;
    right: 315px;
    top: 256px;
  }

  ${bp.large} {
    width: 229px;
    height: 229px;
    right: 515px;
    top: 326px;

    img {
      width: 70px;
    }
  }

`;

const BadgeReviews = styled.div`
  ${BadgeDefault}
  width: 65vw;
  height: 65vw;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  border: 12px solid ${sc.colors.white};
  background-color: ${sc.colors.main};
  right: 0;
  top: 0;

  img {
    width: 40px;
  }



  ${bp.tablet} {
    width: 50vw;
    height: 50vw;
  }

  ${bp.computer} {
    width: 175px;
    height: 175px;
    right: 0;
    top: -52px;
  }

  ${bp.large} {
    width: 229px;
    height: 229px;
    right: 0;
    top: 8px;

    img {
      width: 70px;
    }
  }
`;

const BadgeNewHomes = styled.div`
  ${BadgeDefault}
  width: 65vw;
  height: 65vw;
  border: 19px solid ${sc.colors.yellow};
  background-color: ${sc.colors.white};
  right: 0;
  top: 0;
  color: #212121 !important;


  ${bp.tablet} {
    width: 50vw;
    height: 50vw;
  }

  ${bp.computer} {
    width: 341px;
    height: 341px;
    right: 70px;
    top: 20px;
  }

  ${bp.large} {
    width: 451px;
    height: 451px;
    right: 160px;
    top: 28px;
  }

  p {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 1;

    ${bp.tablet} {
      font-size: 60px;
    }
    ${bp.computer} {
      font-size: 70px;
    }
    ${bp.large} {
      font-size: 110px;
    }
  }

`;

export class NewHomesHero extends PureComponent {
  static propTypes = {
    title: PT.string,
    text: PT.string,
    buttonText: PT.string,
    badges: PT.shape({
      newHomesLabel: PT.string,
      awardWinning: PT.string,
      fiveStarReviews: PT.string,
    }),
  }

  render() {
    const { title, text, buttonText, badges } = this.props;

    return (
      <Media
        breakpoint={bp.computer}
        renderIfTrue={() => (
        <Container>
          <InnerContainer>
            <Grid>
              <Spacer />
              <Column width={4} className="leftSection">
                <Title >
                  <Serif alpha color="main">{title}</Serif>
                </Title>
                <Content>
                  <Sans alpha color="gray">{text}</Sans>
                </Content>
                <PulsingButton href="/search-results?area=&bedrooms=all&enquiryType=Buy&more=1-option&page=1&priceMax=20000000&priceMin=100000&propertyType=property-type-0&rangeMax=20000000&rangeMin=100000&sortBy=date&sortOrder=DESC" fullWidth>{buttonText}</PulsingButton>
              </Column>
              <Spacer />
              <Column width={5} className="rightSection" >
                <BadgesContainer>
                  <BadgeAwards className="firstCircle">
                    <a className="linkIn" href="/about/"></a>
                    <img src={iconAward} alt={badges.awardWinning} />
                    <SansBadge color="white" block>
                      {badges.awardWinning}
                    </SansBadge>
                  </BadgeAwards>
                  <BadgeNewHomes className="secondCircle">
                    <a className="linkIn" href="/search-results?area=&bedrooms=all&enquiryType=Buy&more=1-option&page=1&priceMax=20000000&priceMin=100000&propertyType=property-type-0&rangeMax=20000000&rangeMin=100000&sortBy=date&sortOrder=DESC&stepSize=10000"></a>
                    <p>
                      <span id="counting">1000</span>
                      <sup>+</sup>
                    </p>
                    <Sans gamma>{badges.newHomesLabel}</Sans>
                  </BadgeNewHomes>
                  <BadgeReviews className="thirdCircle">
                    <a className="linkIn" href="https://www.google.com/search?q=ernest+brooks+google+reviews&rlz=1C5CHFA_enCZ786CZ786&oq=ernest+brooks+google+reviews&aqs=chrome..69i57j69i64.4894j0j4&sourceid=chrome&ie=UTF-8#lrd=0x487602b9bb6c1d19:0xb06e19d159f5769f,1" target="_blank"></a>
                    <img src={iconReviews} alt={badges.fiveStarReviews} />
                    <SansBadge color="white" block>
                      {badges.fiveStarReviews}
                    </SansBadge>
                  </BadgeReviews>
                </BadgesContainer>
              </Column>
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
      )}
      renderIfFalse={() => (
        <Container>
          <InnerContainer>
            <Grid>
              <Column width={12} >
                <Title >
                  <Serif alpha color="main">{title}</Serif>
                </Title>
                <Content>
                  <Sans alpha color="gray">{text}</Sans>
                </Content>
                <PulsingButton href="/search-results?area=&bedrooms=all&enquiryType=Buy&more=1-option&page=1&priceMax=20000000&priceMin=100000&propertyType=property-type-0&rangeMax=20000000&rangeMin=100000&sortBy=date&sortOrder=DESC" fullWidth>{buttonText}</PulsingButton>
              </Column>
              <Column width={12} >
                <BadgesContainer>
                  <BadgeReviews >
                    <a className="linkIn" href="https://www.google.com/search?q=ernest+brooks+google+reviews&rlz=1C5CHFA_enCZ786CZ786&oq=ernest+brooks+google+reviews&aqs=chrome..69i57j69i64.4894j0j4&sourceid=chrome&ie=UTF-8#lrd=0x487602b9bb6c1d19:0xb06e19d159f5769f,1" target="_blank"></a>
                    <img src={iconReviews} alt={badges.fiveStarReviews} />
                    <SansBadge color="white" block>
                      {badges.fiveStarReviews}
                    </SansBadge>
                  </BadgeReviews>
                  <BadgeNewHomes>
                    <a className="linkIn" href="/search-results?area=&bedrooms=all&enquiryType=Buy&more=1-option&page=1&priceMax=20000000&priceMin=100000&propertyType=property-type-0&rangeMax=20000000&rangeMin=100000&sortBy=date&sortOrder=DESC&stepSize=10000"></a>
                    <p>
                    <span id="counting">1000</span>
                    <sup>+</sup>
                    </p>
                    <Sans gamma>{badges.newHomesLabel}</Sans>
                  </BadgeNewHomes>
                  <BadgeAwards >
                    <a className="linkIn" href="/about/"></a>
                    <img src={iconAward} alt={badges.awardWinning} />
                    <SansBadge color="white" block>
                      {badges.awardWinning}
                    </SansBadge>
                  </BadgeAwards>

                </BadgesContainer>
              </Column>
            </Grid>
          </InnerContainer>
        </Container>
      )} />
    );
  }
}
