import React, { PureComponent } from 'react';
import PT from 'prop-types';
import styled, { css } from 'styled-components';
import {
  Grid,
  Spacer,
  Column,
  InnerContainer,
  List,
  EventCard,
  Button,
  Media
} from 'components/ui';
import { Script, Sans } from 'Typography';
import sc from 'config/theme';

import metroIcon from 'images/metro-icon.svg';
import tramIcon from 'images/tram-icon.svg';
import trainIcon from 'images/nr-icon.svg';
import dotIcon from 'images/dot-icon.svg';


import { ViewportEnterAnimation } from 'components/ui';
import { functionSectionLoad1} from 'motion/single-property.js';
import bp from 'config/responsive';
import GoogleMapReact from 'google-map-react';  

const key = 'AIzaSyCuS5Z05YuKuDXjDrLkjFC1a9HzxeIjJXw';






const Container = styled.div`
  background: ${sc.colors.white};
  padding: 30px 0 0;

  ${bp.smallNotebook} {
    padding: 102px 0 0px;
  }

  .sectionLoadInner {
    ${bp.computer} { 
      opacity: 0;
    }
  }
  .smallContent {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
`;

const StyledScript = styled(Script)`
  margin-bottom: 16px;
  line-height: 1.6;
`;


const EpcImg = styled.img`
width : 100%;
height: auto;

  ${({ width }) => {
    //return css`max-width: ${width}px;`;
    return css`max-width: 260px;`;
  }}

  ${({ height }) => {
    return css`max-height: ${height}px;`;
  }}
`;


const Title = ({ children }) => (
  <StyledScript delta color="main" block>
    {children}
  </StyledScript>
);

const TextContent = styled.div`
  p {
    margin-bottom: 1em;
    line-height: inherit;
  }
`;

const Section = styled.div`
  ${bp.computer} {
    margin-bottom: 70px;
  
  }
`;

const Station = styled.div`
  background-position: left center;
  background-repeat: no-repeat;
  padding: 8px 0 8px 35px;
  margin-bottom: 8px;

  ${({ type }) => {
    if (type === 'metro') {
      return css`background-image: url(${metroIcon});`;
    } else if (type === 'tram') {
      return css`
        background-image: url(${tramIcon});
        background-position: left 6px center;
      `;
    }else if (type === 'dot')  {
      return css`
        background-image: url(${dotIcon});
        background-position: left 0px center;
        background-size: 25px;

        `;
    }else{
      return css`
        background-image: url(${trainIcon});
        background-position: left 0px center;
        background-size: 25px;

}
         `;
    }
  }}
`;


const ValuationButton = styled(Button)`
  max-width: 100%;
  margin-top: 20px !important;
  margin-bottom: 35px !important;

  ${bp.tablet} {
    max-width: 90%;
    /* margin-top: unset !important;
    margin-bottom: unset !important; */
  }
  ${bp.computer} {
    /*margin-top: unset !important;*/
    margin-bottom: unset !important;
  }
`;


const EventCardsContainer = styled.div`
  display: block;
`;

const SummaryContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 3rem;
  background-color: transparent;
  display: table;
  table-layout: fixed;
  margin-top: 3rem;

  ${bp.computer} {
    margin-bottom: 10rem;
    margin-top: 5rem;
  }
`;



const SummaryRow = styled.div`
  display: flex;
  flex-direction: column;

  ${bp.computer} {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
  }
`;

const SummaryItems = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;

  ${bp.computer} {
    margin-bottom: 0;
    width: 33.333%;
  }
`;
const TitleItem = styled.div`
  color: ${sc.colors.main};
  font-weight: 600;
  font-size: 1.2rem;
`;

const DescriptionItem = styled.div`
    font-size: 18px;
    margin-top: .5rem;

    div {
      font-size: 18px !important;
    }
`;
const ImgWrapper = styled.a`
  background: ${sc.colors.white};
  overflow: hidden;
  width: 100%;

  img{
    width: 100%;
    height: auto;
    margin-top: 20px;
    cursor: pointer;
    max-width: 400px;
    margin-left: -5px;
  }
`;


export class DevelopmentPropertyContent extends PureComponent {
  static propTypes = {
    overview: PT.string.isRequired,
    features: PT.string.isRequired,
    localLife: PT.arrayOf(PT.object),
    nearestStations: PT.arrayOf(PT.object),
  }
  state = {
   places1: [],
   places2: [],
   center: {
      lat : this.props.localLifelat,
      lng : this.props.localLifelng,
   }
  }


  
handleButtonClick(){
    if (typeof window !== 'undefined') {
      window.openPropertyEnquiry && window.openPropertyEnquiry();
    }
}


apiIsLoaded = (map, maps) => {

  var service = new maps.places.PlacesService(map);



  let self = this;    


    service.nearbySearch( {location: this.state.center, radius: 1000, type: ['restaurant'] , extensions : "review_summary" },

    function(results, status, pagination) {
      
      if (status !== 'OK') return;

      if(results.length > 3){
          results.splice( 0, results.length - 3);
      }

       self.setState({ places2: results });
  });


   

};


  render() {
    let {
      epc,
      overviewTitle,
      features,
      featuresTitle,
      localLife,
      localLifelat,
      localLifelng,
      localLifeTitle,
      nearestStations,
      nearestStationsTitle,
      summary,
      
    } = this.props;

    let {overview} = this.props;
    overview = overview.replace(/(?:\\[rr]|[\r\r])/g, "\r\n");

    const noDescription = 'No description available.';

    const showSidebar = true;

  const center = {
      lat: 51.50975,
      lng: -0.017595
    }
  const  zoom = 12;


  const {places1, places2} = this.state;

    summary.ground_rent = summary.ground_rent.replace(/(?:\\[rr]|[\r\r])/g, "\r\n");

    return (

        <React.Fragment>


        <div style={{ display: 'none' }} >

        </div>

      <Media
        breakpoint={bp.smallNotebook}
        renderIfTrue={() => (

        <ViewportEnterAnimation offset={100} animation={functionSectionLoad1}>
        <Container className="sectionLoad">
          <InnerContainer className="sectionLoadInner">
            <Grid>
              <Spacer className="development_content"  />


              <Column width={showSidebar ? 5 : 10}>
   

                <Title>{overviewTitle}</Title>
                <TextContent>
                  <Sans
                    alpha
                    as="div"
                    color="gray"
                    weight="light"
                    lineHeight="1.9"
                  >
                  {overview
                      ? overview.split('\r\n').map(line =><p>{line}</p>)
                      : noDescription}
                  </Sans>
                </TextContent>


              </Column>



              {showSidebar && (
                <React.Fragment>
                  <Spacer />
                  <Column width={4}>


                     <Section>
                        <Title>Development viewing</Title>
                        <TextContent>When would you like to view this property?</TextContent>

                        <ImgWrapper onClick={this.handleButtonClick}>
                        <img src={'https://apidev.ernest-brooks.com/img/calendar.jpg'} />
                        </ImgWrapper>

                  
                       
                      </Section>

                      <Section>
                        <Title>{localLifeTitle}</Title>
                        <EventCardsContainer>

                            <EventCard type='school' center={this.state.center} />

                          {places2.map(eventData => (
                            <EventCard type='restaurant' {...eventData} />
                          ))}

                        </EventCardsContainer>
                      </Section>
                    {nearestStations && (
                      <Section>
                        <Title>{nearestStationsTitle}</Title>
                        {nearestStations.map(({ type, label }) => (
                          <Station type={type}>
                            <Sans delta color="gray">{label}</Sans>
                          </Station>
                        ))}
                      </Section>
                    )}




                  </Column>
                </React.Fragment>
              )}
              <Spacer />
            </Grid>
          </InnerContainer>
        </Container>
        </ViewportEnterAnimation>
      )}
      renderIfFalse={() => (
        <ViewportEnterAnimation offset={100} animation={functionSectionLoad1}>
        <Container className="sectionLoad">
          <InnerContainer className="sectionLoadInner">
            <Grid>
              <Column width={12} className="smallContent">
               
                <Title>{overviewTitle}</Title>


               <TextContent>
                  <Sans
                    alpha
                    as="div"
                    color="gray"
                    weight="light"
                    lineHeight="1.9"
                  >
                  {overview
                      ? overview.split('\r\n').map(line =><p>{line}</p>)
                      : noDescription}
                  </Sans>
                </TextContent>

              </Column>
              {showSidebar && (
                <React.Fragment>
                  <Column width={12} className="smallContent">




                      <Section>
                        <Title>Property viewing</Title>
                        <TextContent>When would you like to view this property?</TextContent>

                        <ImgWrapper onClick={this.handleButtonClick}>
                        <img src={'https://apidev.ernest-brooks.com/img/calendar.jpg'} />
                        </ImgWrapper>

                        <ValuationButton alt compact onClick={this.handleButtonClick} >
                          Arrange a viewing
                        </ValuationButton>
                       
                      </Section>

                  

                      <Section>
                        <Title>{localLifeTitle}</Title>
                        <EventCardsContainer>

                          <EventCard type='school'  center={this.state.center}/>

                          {places2.map(eventData => (
                            <EventCard type='restaurant' {...eventData} />

                          ))}

                        </EventCardsContainer>
                      </Section>

                    {nearestStations && (
                      <Section>
                        <Title>{nearestStationsTitle}</Title>
                        {nearestStations.map(({ type, label }) => (
                          <Station type={type}>
                            <Sans delta color="gray">{label}</Sans>
                          </Station>
                        ))}
                      </Section>
                    )}

                  </Column>
                </React.Fragment>
              )}
            </Grid>
          </InnerContainer>
        </Container>
        </ViewportEnterAnimation>
      )} />
      </React.Fragment>
    );
  }
}

export default DevelopmentPropertyContent;
