import React, { PureComponent } from 'react';
import Slick from 'react-slick';
import styled from 'styled-components';
import { SliderArrow } from 'components/ui';
import { SliderInnerCard } from 'components/blocks';
import bp from 'config/responsive';


const StyledSlick = styled(Slick)`
  width: calc(100% + 30px);
  margin-left: -15px;

  * {
    outline: none;
  }
  &.slider-outer {
    height: 100%;

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      opacity: 0.2;
      transition: ease 0.45s;

      &.slick-active {
        opacity: 1;
      }
    }
  }
`;

const Slide = styled.div`
  position: relative;
  padding: 0 5px;

  ${bp.computer} {
    padding: 0 15px;
  }
`;

const SlideInner = styled.div`
  padding-top: 70% !important;
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  & > * {
    -webkit-transform:translateZ(0);
  }
`;

export class LatestPropertiesSlider extends PureComponent {
  state = {
    currentIndex: 0,
  };

  outerSliderSettings = {
    className: 'slider-outer',
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 3,
    initialSlide: 0,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SliderArrow next />,
    prevArrow: <SliderArrow />,
    easing: 'cubic-bezier(0.77, 0, 0.175, 1)',

    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  afterChange = index => {
    this.setState({ currentIndex: index });
  };

  render() {
    const { currentIndex } = this.state;
    const { data } = this.props;

    return (
      <StyledSlick {...this.outerSliderSettings} afterChange={this.afterChange}>
        {data.map((item, index) => (
          <Slide key={item.src}>
            <SlideInner>
              <SliderInnerCard
                cover={item}
                active={index === currentIndex}
                index={index}
              />
            </SlideInner>
          </Slide>
        ))}
      </StyledSlick>
    );
  }
}

export default LatestPropertiesSlider;
