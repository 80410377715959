import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { Icon } from 'semantic-ui-react';
import sc from 'config/theme';
import { Sans } from 'Typography';
import bp from 'config/responsive';

const StyledPill = styled(Sans)`
  margin-right: 0;
  cursor: pointer;

  ${bp.tablet} {
    margin-right: 20px;
  }

  &:last-child {
    margin-right: 0;
  }

  label {
    display: block;
    line-height: 1em;
    height: 37px;
    border-radius: 5px;
    background: transparent;
    color: ${({ dark }) => (dark ? sc.colors.main : sc.colors.white)};
    text-transform: uppercase;
    user-select: none;
    padding: 11px;
    font-size: 13px;
    font-weight: 600;
    transition: background-color 0.25s ease, color 0.25s ease;
    cursor: pointer;
    white-space: nowrap;
    
    &>*{pointer-events:none;}
    

    ${bp.tablet} {
      padding: 11px 22px;
    }
    ${bp.large} {
      margin-right: 0;
    }

    i {
      transition: opacity 0.25s ease;
      opacity: 0;
      margin-left: 5px;
      transform: scale(1);
    }
  }



  input:hover + label {
    background: rgba(196, 216, 255, 0.25);
    

    i {
      opacity: 1;
      transform: none;
    }
  }

  input:checked + label {
    pointer-events: none;
    background: ${({ dark }) => (dark ? sc.colors.main : sc.colors.white)};
    color: ${({ dark }) => (dark ? sc.colors.white : sc.colors.main)};

    i {
      opacity: 1;
      transform: none;
    }
  }

  input {
    display: none;
  }
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};

  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
  position: relative;
  flex-direction: column;
  
  ${bp.tablet} {
    flex-direction: inherit;
  }

  &::-webkit-scrollbar { 
    display: none; 
  }

  ${bp.large} {
    overflow: inherit;
    overflow-x: inherit;
  }

`;

class PillMenu extends PureComponent {
  state = {
    active: this.props.active,
  };

  static propTypes = {
    inputName: PT.string.isRequired,
    dark: PT.bool,
    showCheck: PT.bool,
  };

  render() {
    const { inputName, dark, showCheck, children, justify } = this.props;

    return (
      <StyledMenu justify={justify}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            inputName,
            dark,
            showCheck,
          })
        )}
      </StyledMenu>
    );
  }
}

class Pill extends PureComponent {
  uniqueId = `radio-id-${Math.random()
    .toString(32)
    .substr(2)}`;

  render() {
    const {
      showCheck,
      dark,
      inputName,
      value,
      children,
      onChange,
      checked,
      ...otherProps
    } = this.props;

    return (
      <StyledPill as="div" showCheck={showCheck} dark={dark}>
        <input
          type="radio"
          name={inputName}
          value={value}
          id={this.uniqueId}
          onChange={onChange}
          checked={checked}
          {...otherProps}
        />
        <label htmlFor={this.uniqueId}>
          {children}
          {showCheck && <Icon name="check" />}
        </label>
      </StyledPill>
    );
  }
}

PillMenu.Pill = Pill;

export default PillMenu;
