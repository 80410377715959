import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Media } from 'components/ui';
import bp from 'config/responsive';
import { CloseButton } from 'components/ui';
import { Script, Sans } from 'Typography';
import request from 'utils/request';




import {
  InnerContainer,
  Grid,
  Column,
  Header,
  SocialMediaIcon,
} from 'components/ui';
import sc from 'config/theme';

const StyledFooterImg = styled(Img)`
  &:not(:last-child) {
    margin-right: 20px;
    margin-bottom: 0;

    ${bp.computer} {
      margin-right: 38px;
    }
  }
`;

const Container = styled.div`
  background: ${sc.colors.main};
  color: ${sc.colors.white};
  padding-top: 40px;
  padding-bottom: 40px;

  .floatRight {
    justify-content: flex-end;
  }

  &.centerFooter * {
    text-align: center;
    justify-content: center;

    ${bp.smallNotebook} {
      text-align: inherit;
      justify-content: inherit;
    }

  }

  &.centerFooter .grid {
      width: 100% !important;

      ${bp.smallNotebook} {
        width: calc(100% / 12 * 16) !important;
      }
  }


  ${bp.computer} {
    padding-top: 113px;
    padding-bottom: 48px;
  }

`;

const Content = styled.div`
  padding-left: 0px;
  padding-right: 0px;

  ${bp.computer} {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const StyledHeader = styled(Header)`
  && {
    margin-left: 0;
    margin-bottom: 28px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  flex: 2;
  padding-bottom: 30px;
  flex-wrap: wrap;

  ${bp.smallNotebook} {

  }
`;

const Locations = styled(Row)`
  font-size: 16px;

  ${bp.computer} {
    font-size: 18px;
  }
  ${bp.large} {
    font-size: 24px;
  }

  img {
    width: 180px;

  }


  
`;

const Phone = styled(Row)`
  font-size: 16px;

  ${bp.computer} {
    font-size: 18px;
  }
  ${bp.large} {
    font-size: 24px;
  }
`;



const Location = styled.a`
  margin: 15px;
  color: rgba(${sc.colors.white_rgb}, 0.38);
  text-decoration: none;
  transition: color 0.25s ease-in;
  width: 100%;
  font-size: 24px;


  &:hover {
    color: ${sc.colors.white};
  }

  ${({ active }) =>
    active &&
    css`
      color: ${sc.colors.white};
    `}

  ${bp.computer} {
    width: auto;
    margin: 0 15px 0;
    font-size: 18px;
  }
  ${bp.smallNotebook} {
    margin: 0 30px 0 0;
  }
`;

const Meta = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-top: 14px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4em;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;

  &.locations {
    line-height: 2em;
  }

  & .extraLink {
    margin: 0 15px 15px;

    ${bp.computer} {
      margin: 0 15px 0 0;
    }
  }


  ${bp.computer} {
    white-space: nowrap;

    &.locations {
      line-height: 2em;
    }
  }
  ${bp.large} {
    line-height: 1em;
    overflow: inherit;
    white-space: normal;
    width: auto;
  }
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 0;
    border-left: 1px solid ${sc.colors.white};
    margin-left: 8px;
    padding-left: 8px;
    display: flex;
    align-items: center;
    font-size: 12px;

    ${bp.computer} {
      font-size: 16px;
    }

    &:first-of-type {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
`;

const Divider = styled.div`
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid white;
  opacity: 0.25;
`;

const InformalLink = styled.a`
  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    font-weight: inherit;
    color: inherit;

  }

  &:hover {
    /* text-decoration: underline; */
  }

  &.phone {
    font-size: 24px;

    ${bp.computer} {
      font-size: 16px;
    }
  }
`;


const DownloadLink = styled.a`
  font-size: 13px;
  text-transform: uppercase;
  margin-top: 15px;
  color: #fff;
  transition: opacity .3 ease;
  display: flex;
  align-items: center;

  ${bp.computer} {
    font-size: 13px;
  }
  ${bp.large} {
    font-size: 13px;
  }

  &:hover {
    color: #fff; 
    opacity: 0.8;
  }

  & .icon {
    width: 20px;
    height: 20px;
    display: inline-flex;
    margin-right: 10px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #fff;
      }
    }
  }
`;

const GdprContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(11, 29, 63, 0.90);
    padding: 10px 15px;
    z-index: 999;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      flex-direction: column;

      ${bp.computer} {
        flex-direction: row;
      }

      & * {
        padding: 0;
        margin:  0 0 15px 0;

        &:last-child {
          margin-bottom: 0;
        }

        ${bp.computer} {
          margin: 0 15px;
        }
      }
      & button {
        padding: 5px 10px;
        border-radius: 5px;
        border-color: transparent;
        background: #335eb3;
        color: #fff;
        transition: background-color ease 0.25s;
        cursor: pointer;

        &:hover {
          background-color: #294b8f;
        }
      }
    }
`;

const Gdpr = styled.div`
  margin: 0 auto;
`;

const Rowcontent = styled.div`
  display:flex;

`;
const SplitContent = styled.div`
  position:relative;
  width:50%;
  overflow:hidden;

    ${bp.mobile} {
      display:none;
    }

`;
const SplitContentpad = styled.div`
  width:50%;
  padding:40px;
  ${bp.mobile} {
    width:100%;
    padding:40px 20px;
  }
`;

const FullContent = styled.div`
  width:100%;
  padding:40px;
  ${bp.mobile} {
    width:100%;
    padding:40px 20px;
  }
`;



const ModalWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0,0,0,0.5);

  top: 0;
  left: 0;
  z-index: 999;



`;
const Modal = styled.div`
    width: 1000px;
    max-width: 80vw;
    height: auto;
    background: #0B1D3F;
    /*box-shadow: 0 0 30px rgba(0,0,0,0.2);*/
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: #fff;
    flex-direction: column;
    overflow:hidden

    span {
      float: right;
      cursor:pointer;

    }
    p{
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
      text-align:left!important;

    }
    img {
      width: 100%;
      height: auto;
      position: relative;
      object-fit: cover;

    }
  
`;
const ModalRelative = styled.div`
    width: 1000px;
    max-width: 80vw;
    height: auto;
    background: #0B1D3F;
    /*box-shadow: 0 0 30px rgba(0,0,0,0.2);*/
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    color: #fff;
    flex-direction: column;

    span {
      float: right;
      cursor:pointer;

    }
    p{
      margin-top: 15px;
      font-size: 18px;
      font-weight: 400;
      text-align:left!important;

    }
    img {
      width: 100%;
      height: auto;
    }
  
`;
const CloseButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;

    & button {
      &:before {
        top: -8px !important;
      }
    }
`;

const Headline = styled.div`
  margin-bottom: 0px;
  min-height: 55px;
  text-align: left !important;
  cursor:normal;

  ${bp.mobile} {
    margin-top: 20px;
    margin-bottom: 0px;
  }


  span{
    float:none!important;
    font-size: 45px;
    cursor: default;
  }
`;


const preventDefault = event => event.preventDefault && event.preventDefault();

class Footer extends PureComponent {
  state = {
    location: 'canaryWharf',

    cookie: {
      dropCookie: true,                      // false disables the Cookie, allowing you to style the banner
      cookieDuration: 14,                    // Number of days before the cookie expires, and the banner reappears
      cookieName: 'complianceCookie',        // Name of our cookie
      cookieValue: 'on',                     // Value of cookie
    },

    cookieModal: {
      dropCookie2: true,                     // false disables the Cookie, allowing you to style the banner
      cookieDuration2: 14,                   // Number of days before the cookie expires, and the banner reappears
      cookieName2: 'modalCookie',            // Name of our cookie
      cookieValue2: 'on',                    // Value of cookie
    },

    displayGdpr: false,
    displayPopUp: false,
    displayPopUpEnable: false,

    popUp_img: "",
    popUp_link: "",
    popUp_title: "",
    popUp_text: "",

  };

  switchLocation = ({ target }) => {
    const { location } = target.dataset;
    this.setState({ location });
  };




  createCookie = (name, value, days, dropCookie) => {

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";

    if (dropCookie) {
      document.cookie = name + "=" + value + expires + "; path=/";

    }
  }


  checkCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  getCookieValue(a) {
    var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
  }

  dimissGDPR = () => {
    this.setState({ displayGdpr: false });

  }



  renderGDPR = () => {
    const { displayGdpr } = this.state;


    return (

      <React.Fragment>
        {displayGdpr &&
          <GdprContainer>
            <Gdpr>
              <p>By using this website, you agree to our use of cookies.</p>
              <button onClick={() => this.dimissGDPR()}>
                OK
              </button>
            </Gdpr>
          </GdprContainer>
        }
      </React.Fragment>

    );
  }

  dimissModal = () => {
    this.setState({ displayPopUp: false });

  }

  renderPopUp = () => {



    const { displayPopUp } = this.state;
    var { displayPopUpEnable } = this.state;
    const { popUp_img } = this.state;
    const { popUp_title } = this.state;
    var { popUp_link } = this.state;
    const { popUp_text } = this.state;
    var popuptype = "split";
    var popuplink = true;

    if (popUp_title == "" && popUp_text == "") {
      popuptype = "image";
    }

    if (popUp_link == "") {
      popuplink = false;
    } else {
      popUp_link = "https://" + popUp_link;
    }



    if (displayPopUpEnable === "0" || displayPopUpEnable == false) {
      displayPopUpEnable = false;
    } else {
      displayPopUpEnable = true;
    }


    var showmodal = (displayPopUp && displayPopUpEnable);


    if (popuptype == "image") {


      if (popuptype == "image") {

        return (
          <React.Fragment>
            {(showmodal) &&
              <ModalWrapper>
                <ModalRelative>
                  <CloseButtonWrapper>
                    <CloseButton onClick={() => this.dimissModal()} />
                  </CloseButtonWrapper>
                  <Rowcontent>
                    <FullContent>
                      <a href={popUp_link} target="_blank">
                        <img src={popUp_img} />
                      </a>
                    </FullContent>
                  </Rowcontent>

                </ModalRelative>
              </ModalWrapper>
            }
          </React.Fragment>
        );

      } else {

        return (
          <React.Fragment>
            {(showmodal) &&
              <ModalWrapper>
                <ModalRelative>
                  <CloseButtonWrapper>
                    <CloseButton onClick={() => this.dimissModal()} />
                  </CloseButtonWrapper>
                  <Rowcontent>
                    <FullContent>
                      <img src={popUp_img} />
                    </FullContent>
                  </Rowcontent>

                </ModalRelative>
              </ModalWrapper>
            }
          </React.Fragment>
        );

      }

    } else {
      if (popuptype == "image") {

        return (
          <React.Fragment>
            {(showmodal) &&
              <ModalWrapper>
                <Modal>
                  <CloseButtonWrapper>
                    <CloseButton onClick={() => this.dimissModal()} />
                  </CloseButtonWrapper>
                  <Rowcontent>
                    <SplitContent>
                      <img src={popUp_img} />
                    </SplitContent>
                    <SplitContentpad>
                      <Headline>
                        <Script alpha color="white" className="aboutContactSectionTitle">
                          {popUp_title}
                        </Script>
                      </Headline>
                      <div>
                        <p>{popUp_text}</p>
                      </div>
                    </SplitContentpad>
                  </Rowcontent>

                </Modal>
              </ModalWrapper>
            }
          </React.Fragment>
        );
      } else {

        return (
          <React.Fragment>
            {(showmodal) &&
              <ModalWrapper>
                <Modal>
                  <CloseButtonWrapper>
                    <CloseButton onClick={() => this.dimissModal()} />
                  </CloseButtonWrapper>
                  <Rowcontent>
                    <SplitContent>
                      <a href={popUp_link} target="_blank">
                        <img src={popUp_img} />
                      </a>
                    </SplitContent>
                    <SplitContentpad>
                      <Headline>
                        <Script alpha color="white" className="aboutContactSectionTitle">
                          {popUp_title}
                        </Script>
                      </Headline>
                      <div>
                        <p>{popUp_text}</p>
                      </div>
                    </SplitContentpad>
                  </Rowcontent>

                </Modal>
              </ModalWrapper>
            }
          </React.Fragment>
        );


      }
    }

  }


  getData = async () => {
    try {
      this.setState({ loading: true, error: false });
      const { data } = await request('displaymodal');

      this.setState({ displayPopUpEnable: data.enable, popUp_img: data.src, popUp_title: data.title, popUp_link: data.link, popUp_text: data.description });

    } catch (err) {
      console.error(err.message);
      this.setState({ loading: false, error: true });
    }
  };

  async componentDidMount() {
    const { cookieName, cookieValue, cookieDuration, dropCookie } = this.state.cookie;
    const { cookieName2, cookieValue2, cookieDuration2, dropCookie2 } = this.state.cookieModal;

    if (this.getCookieValue(cookieName) != cookieValue) {

      this.setState({ displayGdpr: true });

      this.createCookie(cookieName, cookieValue, cookieDuration, dropCookie); // Create the cookie
    }
    if (this.getCookieValue(cookieName2) != cookieValue2) {

      this.setState({ displayPopUp: true });

      this.createCookie(cookieName2, cookieValue2, cookieDuration2, dropCookie2); // Create the cookie
    }

    this.getData();
  }


  render() {
    const { location } = this.state;

    return (
      <StaticQuery
        query={graphql`
          query FooterImages {
            trustSpotImg: file(relativePath: { eq: "google-reviews.png" }) {
              childImageSharp {
                fixed(width: 114, height: 50) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            propertyImg: file(
              relativePath: { eq: "property-mark.png" }
            ) {
              childImageSharp {
                fixed(width: 137, height: 50) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            pages {
              footer {
                socialMedia {
                  title
                  links {
                    name
                    link
                    title
                  }
                }
                locations {
                  title
                  addresses {
                    canaryWharf {
                      title
                      address
                    }
                    bank {
                      title
                      address
                    }
                    singapore {
                      title
                      address
                    }
                    kualaLumpur {
                      title
                      address
                    }
                  }
                }
                getInTouch {
                  title
                  number
                }
                siteDetails {
                  name
                  link
                }
                fileEnable
                fileLocation
                fileURL

              }
            }
          }
        `}
        render={data => {
          const {
            pages: { footer },
          } = data;

          const { socialMedia, getInTouch, locations, siteDetails, fileLocation, fileURL, fileEnable } = footer;

          locations.title = "Accredited by"

          return (

            <Media
              breakpoint={bp.smallNotebook}
              renderIfTrue={() => (
                <Container>
                  <InnerContainer>
                    <Content>
                      <Grid>
                        <Column column width={4}>
                          <StyledHeader as="h5">{socialMedia.title}</StyledHeader>
                        </Column>
                        <Column column width={6}>
                          <StyledHeader as="h5">{locations.title} </StyledHeader>
                        </Column>
                        <Column column width={2}>
                          <StyledHeader as="h5">{getInTouch.title}</StyledHeader>
                        </Column>
                      </Grid>
                      <Grid>
                        <Column column width={4}>
                          <Row>
                            {socialMedia.links.map(link => (
                              <SocialMediaIcon
                                name={link.name}
                                href={link.link}
                                title={link.title}
                              />
                            ))}
                          </Row>
                        </Column>
                        <Column column width={6}>

                          <Locations>
                            <Location>
                              <img src="https://apidev.ernest-brooks.com/img/PRS_Logo.png" alt=""></img>
                            </Location>
                  


                          </Locations>
                        </Column>
                        <Column column width={2}>
                          <Phone>
                            <InformalLink href={`tel:${getInTouch.number}`}>
                              {getInTouch.number}
                            </InformalLink>



                            {fileEnable == 'on'  &&
                              <React.Fragment>

                                {fileLocation == 'file_file' ? (
                                  <DownloadLink href="https://apidev.ernest-brooks.com/ErnestBrooksBrochure.pdf" target="_blank" download>
                                    <span className="icon">
                                      <svg enable-background="new 0 0 511.448 511.448" height="512" viewBox="0 0 511.448 511.448" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m360.607 85.951c-81.131-80.468-217.589-42.747-248.244 64.895-58.526-10.296-112.363 34.756-112.363 94.216 0 52.752 42.917 95.67 95.67 95.67h139v65l-9.066-6.8c-9.277-6.958-22.441-5.078-29.4 4.2s-5.079 22.441 4.2 29.4c47.081 35.311 44.823 34.375 50.685 35.684 5.895 1.312 11.924.035 16.705-3.355 1.11-.786 11.879-8.88 43.144-32.328 9.278-6.959 11.159-20.122 4.2-29.4-6.958-9.277-20.119-11.158-29.399-4.2l-9.067 6.801v-65h128.328c31.445 0 61.106-13.802 81.365-37.875.504-.599.988-1.248 1.357-1.801 64.385-95.33-10.35-225.51-127.115-215.107zm-264.937 212.781c-29.594 0-53.67-24.076-53.67-53.67 0-39.24 41.127-65.405 76.693-48.467 15.773 7.473 16.22 16.673 29.774 16.673 18.541 0 28.116-22.583 14.849-35.849-3.733-3.733-7.71-7.076-11.865-10.062 16.475-71.34 97.963-104.374 159.496-67.234-45.199 22.784-76.277 69.632-76.277 123.609v75zm357.858-22.1c-12.241 14.058-29.865 22.1-48.528 22.1h-128.33v-75c0-52.431 42.523-96.33 96.33-96.33 77.006 0 122.204 85.966 80.528 149.23z" /></svg>
                                    </span>Download Brochure
                                  </DownloadLink>
                                ) : (
                                  <DownloadLink href={fileURL} target="_blank" download>
                                    <span className="icon">
                                      <svg enable-background="new 0 0 511.448 511.448" height="512" viewBox="0 0 511.448 511.448" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m360.607 85.951c-81.131-80.468-217.589-42.747-248.244 64.895-58.526-10.296-112.363 34.756-112.363 94.216 0 52.752 42.917 95.67 95.67 95.67h139v65l-9.066-6.8c-9.277-6.958-22.441-5.078-29.4 4.2s-5.079 22.441 4.2 29.4c47.081 35.311 44.823 34.375 50.685 35.684 5.895 1.312 11.924.035 16.705-3.355 1.11-.786 11.879-8.88 43.144-32.328 9.278-6.959 11.159-20.122 4.2-29.4-6.958-9.277-20.119-11.158-29.399-4.2l-9.067 6.801v-65h128.328c31.445 0 61.106-13.802 81.365-37.875.504-.599.988-1.248 1.357-1.801 64.385-95.33-10.35-225.51-127.115-215.107zm-264.937 212.781c-29.594 0-53.67-24.076-53.67-53.67 0-39.24 41.127-65.405 76.693-48.467 15.773 7.473 16.22 16.673 29.774 16.673 18.541 0 28.116-22.583 14.849-35.849-3.733-3.733-7.71-7.076-11.865-10.062 16.475-71.34 97.963-104.374 159.496-67.234-45.199 22.784-76.277 69.632-76.277 123.609v75zm357.858-22.1c-12.241 14.058-29.865 22.1-48.528 22.1h-128.33v-75c0-52.431 42.523-96.33 96.33-96.33 77.006 0 122.204 85.966 80.528 149.23z" /></svg>
                                    </span>Download Brochure
                                  </DownloadLink>
                                )}
                              </React.Fragment>
                            }

                          </Phone>




                        </Column>
                      </Grid>
                    </Content>
                  </InnerContainer>
                  <Divider />
                  <InnerContainer>
                    <Content>
                      <Grid>
                        <Column column width={4} compact>
                          <Meta>
                            <List>
                              {siteDetails.map(detail =>
                                detail.link ? (
                                  <li key={detail.name}>
                                    <InformalLink href={detail.link}>
                                      {detail.name}
                                    </InformalLink>
                                  </li>
                                ) : (
                                  <li key={detail.name}>{detail.name}</li>
                                )
                              )}
                              <li><InformalLink href="https://www.artistsweb.co.uk/" target="_blank">Site Credits</InformalLink></li>
                            </List>
                          </Meta>
                        </Column>
                        <Column column width={4}>
                          <Meta>

                          </Meta>
                        </Column>
                        <Column column width={4} >
                          <Meta className="floatRight">
                            <a className="extraLink" target="_blank" href="https://www.google.com/search?q=ernest+brooks+google+reviews&rlz=1C5CHFA_enCZ786CZ786&oq=ernest+brooks+google+reviews&aqs=chrome..69i57j69i64.4894j0j4&sourceid=chrome&ie=UTF-8#lrd=0x487602b9bb6c1d19:0xb06e19d159f5769f,1">
                              <StyledFooterImg
                                fixed={data.trustSpotImg.childImageSharp.fixed}
                                alt="google reviews"
                              />
                            </a>
              
                          </Meta>
                        </Column>
                      </Grid>
                    </Content>
                  </InnerContainer>
                  <React.Fragment>
                    {this.renderGDPR()}
                  </React.Fragment>
                  <React.Fragment>
                    {this.renderPopUp()}
                  </React.Fragment>

                </Container>
              )}
              renderIfFalse={() => (
                <Container className="centerFooter">
                  <InnerContainer>
                    <Content>
                      <Grid>
                        <Column column width={12}>
                          <StyledHeader as="h5">{socialMedia.title}</StyledHeader>
                          <Row>
                            {socialMedia.links.map(link => (
                              <SocialMediaIcon
                                name={link.name}
                                href={link.link}
                                title={link.title}
                              />
                            ))}
                          </Row>
                        </Column>
                        <Column column width={12}>
                          <StyledHeader as="h5">{locations.title}</StyledHeader>

                          <Locations>
                            <Location>
                              <img src="https://apidev.ernest-brooks.com/img/PRS_Logo.png" alt=""></img>
                            </Location>
                

                          </Locations>


                        </Column>
                        <Column column width={12}>
                          <StyledHeader as="h5">{getInTouch.title}</StyledHeader>
                          <Phone>
                            <InformalLink className="phone" href={`tel:${getInTouch.number}`}>
                              {getInTouch.number}
                            </InformalLink>

                            {fileEnable == 'on' &&
                              <React.Fragment>

                                <DownloadLink href="https://apidev.ernest-brooks.com/ErnestBrooksBrochure.pdf" target="_blank" download>
                                  <span className="icon">
                                    <svg enable-background="new 0 0 511.448 511.448" height="512" viewBox="0 0 511.448 511.448" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m360.607 85.951c-81.131-80.468-217.589-42.747-248.244 64.895-58.526-10.296-112.363 34.756-112.363 94.216 0 52.752 42.917 95.67 95.67 95.67h139v65l-9.066-6.8c-9.277-6.958-22.441-5.078-29.4 4.2s-5.079 22.441 4.2 29.4c47.081 35.311 44.823 34.375 50.685 35.684 5.895 1.312 11.924.035 16.705-3.355 1.11-.786 11.879-8.88 43.144-32.328 9.278-6.959 11.159-20.122 4.2-29.4-6.958-9.277-20.119-11.158-29.399-4.2l-9.067 6.801v-65h128.328c31.445 0 61.106-13.802 81.365-37.875.504-.599.988-1.248 1.357-1.801 64.385-95.33-10.35-225.51-127.115-215.107zm-264.937 212.781c-29.594 0-53.67-24.076-53.67-53.67 0-39.24 41.127-65.405 76.693-48.467 15.773 7.473 16.22 16.673 29.774 16.673 18.541 0 28.116-22.583 14.849-35.849-3.733-3.733-7.71-7.076-11.865-10.062 16.475-71.34 97.963-104.374 159.496-67.234-45.199 22.784-76.277 69.632-76.277 123.609v75zm357.858-22.1c-12.241 14.058-29.865 22.1-48.528 22.1h-128.33v-75c0-52.431 42.523-96.33 96.33-96.33 77.006 0 122.204 85.966 80.528 149.23z" /></svg>
                                  </span>Download Brochure
                                </DownloadLink>
                              </React.Fragment>
                            }




                          </Phone>
                        </Column>
                      </Grid>

                    </Content>
                  </InnerContainer>
                  <Divider />
                  <InnerContainer>
                    <Content>
                      <Grid>
                        <Column column width={12} compact>
                          <Meta>
                            <List>
                              {siteDetails.map(detail =>
                                detail.link ? (
                                  <li key={detail.name}>
                                    <InformalLink href={detail.link}>
                                      {detail.name}
                                    </InformalLink>
                                  </li>
                                ) : (
                                  <li key={detail.name}>{detail.name}</li>
                                )
                              )}
                              <li><InformalLink href="https://www.artistsweb.co.uk/" target="_blank">Site Credits</InformalLink></li>

                            </List>
                          </Meta>
                        </Column>

                        <Column column width={12} >
                          <Meta className="locations">
                            {locations.addresses[this.state.location].address}
                          </Meta>
                        </Column>
                        <Column column width={12} className="footerImages" >
                          <Meta>
                            <a className="extraLink" target="_blank" href="https://www.google.com/search?q=ernest+brooks+google+reviews&rlz=1C5CHFA_enCZ786CZ786&oq=ernest+brooks+google+reviews&aqs=chrome..69i57j69i64.4894j0j4&sourceid=chrome&ie=UTF-8#lrd=0x487602b9bb6c1d19:0xb06e19d159f5769f,1">
                              <StyledFooterImg
                                fixed={data.trustSpotImg.childImageSharp.fixed}
                              />
                            </a>
                    
                          </Meta>
                        </Column>
                      </Grid>
                    </Content>
                  </InnerContainer>
                  <React.Fragment>
                    {this.renderGDPR()}
                  </React.Fragment>
                  <React.Fragment>
                    {this.renderPopUp()}
                  </React.Fragment>
                </Container>
              )} />



          );
        }}
      />
    );
  }
}

export default Footer;
