import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { Serif } from 'Typography';
import sc from 'config/theme';
import hex2rgb from 'utils/hex2rgb';
import { Grid, Spacer, Column, InnerContainer, Card } from 'components/ui';
import bp from 'config/responsive';

import { ViewportEnterAnimation } from 'components/ui';
import { functionTimelineRow } from 'motion/rent.js';


const Container = styled.div`
  background: ${sc.colors.whiteDim};
  padding: 40px 0 0;


  ${bp.computer} {
    margin-bottom: 0;
    padding: 112px 0 0;
  }

  .step-card {
    
    &_point {
      ${bp.computer} {
        transform: scale(0);
        transition: all .6s ease;
        transition-delay: .3s;
      }
    }
    &_box {
      transform: translateY(90px);

      ${bp.computer} {
        opacity: 0;
        transform: translateY(30px);
        transition: all .6s ease;
        transition-delay: .6s;
      }
    }

   

    .step-card_box {
        
      width: 100%;
      max-width: 100% !important;

      ${bp.computer} {
        max-width: 539px !important;
      }
       
    }

    &.isVisible {
      .step-card_point {
        transform: scale(1);
      }
      .step-card_box {

        opacity: 1;
        transform: translateY(100px);

        ${bp.large} {
          transform: translateY(0px);
        }
      }
    }
  }
  
`;

const Title = styled.div`
  max-width: 542px;
  margin: 0 auto 70px;

  .timeline {
    opacity: 0;
  }

  ${bp.computer} {
    margin: 0 auto 170px;
  }

`;

const StyledColumn = styled(Column)`
  &&& {
    padding-bottom: 0 !important;
  }
`;

const LinePoint = styled.div`
  display: block;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  background: rgba(${hex2rgb('#a2764c').join(',')}, 0.11);
  border: 2px solid rgba(${hex2rgb('#e5e5e5').join(',')}, 0.11);
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -33px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  &::before {
    width: 43px;
    height: 43px;
    border: 2px solid rgba(${hex2rgb('#e5e5e5').join(',')}, 0.82);
    background-color: rgba(207, 207, 207, 0.29);
  }

  &::after {
    width: 16px;
    height: 16px;
    border: 3px solid ${sc.colors.main};
    background-color: ${sc.colors.main};
  }
`;

const StepRow = styled.div`
  transition: 0.4s;
  display: flex;
  justify-content: flex-start;
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 160px;

  &:nth-child(2n) {
    justify-content: flex-end;
  }

  &:last-child {
    padding-bottom: 250px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    border-left: 1px dashed #979797;
    opacity: 0.55;
  }

  &:first-child::before {
    top: 33px;
  }

  ${bp.bigNotebook} {
    padding-bottom: 100px;

    &:last-child {
      padding-bottom: 60px;
    }
  }

`;

const StepsContainer = styled.div`
&.timelineContainer {
  transform: translateY(-15px);

  ${bp.computer} {

  }

}
`;

export class RentStepByStep extends PureComponent {
  static propTypes = {
    title: PT.string,
    steps: PT.arrayOf(PT.shape({
      title: PT.string,
      content: PT.string,
    })),
  }

  render() {
    const { title, steps } = this.props;

    return (
      <Container>
        <InnerContainer className="timeline">
          <Grid className="timelineInner">
            <Spacer />
            <StyledColumn width={10}>
              <Title className="timelineTitle">
                <Serif alpha color="main" block align="center">{title}</Serif>
              </Title>
              <StepsContainer className="timelineContainer">
                {steps.map((step, index) => (
                  <ViewportEnterAnimation offset={100} animation={() => functionTimelineRow(index)} >
                    <StepRow className="step-card">
                      <LinePoint className="step-card_point"/>
                      <Card
                        className="step-card_box"
                        title={step.title}
                        content={step.content}
                      />
                    </StepRow>
                  </ViewportEnterAnimation>
                ))}
              </StepsContainer>
            </StyledColumn>
            <Spacer />
          </Grid>
        </InnerContainer>
      </Container>
    );
  }
}