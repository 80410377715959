import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PT from 'prop-types';
import { Sans } from 'Typography';
import sc from 'config/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 217px;
  height: 217px;
  border: 9px solid ${sc.colors.yellow};
  border-radius: 50%;
  margin: 0 auto;
`;

const Svg = styled.svg`
  margin-bottom: 25px;
`;

export class FormSuccess extends PureComponent {
  static propTypes = {
    white: PT.bool,
  }

  render() {
    const { white } = this.props;

    return (
      <Wrapper>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="55"
          height="42"
          viewBox="0 0 55 42"
        >
          <path
            fill={white ? 'white' : "#293856"}
            d="M55 4.416L17.286 42 0 24.774l4.431-4.416 12.855 12.778L50.569 0z"
          />
        </Svg>
        <Sans beta color={white ? 'white' : 'main'}>
          Success
        </Sans>
      </Wrapper>
    );
  }
}